import { Component, OnInit,ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import {InvhistorialserialService  } from "../invservicios/invhistorialserial.service";
import {Invhistorialserial  } from "../invinterfaces/invhistorialserial";
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { InvbusquedaService } from '../invservicios/invbusqueda.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
@Component({
  selector: 'app-invhistorialserial',
  templateUrl: './invhistorialserial.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class InvhistorialserialComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  txtartcodigo:string;
  txtnomarticulo:string;
  txtserie:string;
  dethistorialserial:Invhistorialserial[];
  rowStyle;
  defaultColDefhisserial;
  public frameworkComponents;
  largo: string;
  rowSelection = 'multiple';
  busquedacampos: string[];
  consulta:string;
  tabla: string;
  where: string;
  opcion:string;
  types: SelectItem[];
  arregloCons: any[];
  spin:boolean;
  displayDialogBusquedaFast:boolean;
  columnDefshistorial=[
    { // 0
      headerName: 'Documento', field: 'TRNKARDEXOTR_NUMERO', cellEditor: 'cellPrueba',
       editable: false,
    },
    { // 1
      headerName: 'Origen', field: 'TRNKARDEXOTR_ORIGEN', cellEditor: 'cellPrueba',
       editable: false,
    },
    { // 2
      headerName: 'Tipo', field: 'TRNKARDEXOTR_TIPO', cellEditor: 'cellPrueba',
       editable: false,
    },
    { // 3
      headerName: 'Bodega', field: 'BOD_CODIGO', cellEditor: 'cellPrueba',
       editable: false,
    },
    { // 4
      headerName: 'Fecha', field: 'TRNKARDEXOTR_FECHATRN', cellEditor: 'cellPrueba',
       editable: false,
    }
  ]
  constructor(public invhistorialserialService:InvhistorialserialService,
    private busquedaSer: InvbusquedaService,private datePipe: DatePipe,
    private messageService: MessageService, private init: NuevoComponentService,
    ) {
    this.agTable()
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefhisserial= {
      editable: true,
      width: 150,
      objeto: 'invhistserial'
    };
   
  }
  ngOnInit(): void {
    this.txtartcodigo='';
    this.txtnomarticulo='';
    this.txtserie='';
    this.dethistorialserial=[]
    this.largo = '-5800';
    this.spin=false;
    this.displayDialogBusquedaFast=false;
  }

manejarSenales(evento){
  if(evento === 'Cancelar'){
    this.txtartcodigo='';
    this.txtnomarticulo='';
    this.txtserie='';
    this.dethistorialserial=[]
  }
  if(evento === 'Salir'){
    this.init.tabs.splice(this.init.tabindex, 1);
  }
  }
async busquedaarticulo(parametro){
  this.opcion = 'ARTICULO';
  this.types = [
    { label: 'Código', value: 'ART_CODIGO' },
    { label: 'Nombre', value: 'ART_NOMBRE' },
    { label: 'Grupo', value: 'GRUP_CODIGO' },
    { label: 'Nombre Corto', value: 'ART_NOMBREC' },
    { label: 'Código alterno', value: 'ART_CODIGOALT1' },
  ];
  this.busquedacampos = [parametro,'', '', '', ''];
  this.tabla = 'INV_MAEARTICULO';
  this.where = 'ART_SERIALFLAG=\'S\''
  //if (parametro !== '' && parametro !== undefined && parametro !== null) {
    const eR = await this.invhistorialserialService.erpBusquedaArticulo(this.tabla, this.types[0].value, parametro);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
        return;
      }else{
        this.busquedaSer.busquedainvArticulo(parametro).subscribe((datos: any[]) => {
          this.spin=true;
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
          this.spin = false;
        });
      }
    }else{
      this.busquedaSer.busquedainvArticulo(parametro).subscribe((datos: any[]) => {
        this.spin=true;
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
        this.spin = false;
      });
    }
  //}

  }
  async busquedaserie(parametro){
    this.opcion = 'SERIAL';
    this.types = [
    { label: 'Serie', value: 'TRNKARDEXOTR_NUMSERIE' },
    { label: 'DIM', value: 'TRNKARDEXOTR_DIM' },
    { label: 'Teléfono', value: 'TRNKARDEXOTR_TELEFONO' },

  ];
  this.busquedacampos = [parametro,'', ''];
  this.consulta = 'a.* , INV_FNC_OBTENER_NOMARTICULO(art_codigo,\'01\') as ART_NOMBRE';
  this.tabla = 'INV_TRNKARDEXOTR a';

  if(this.txtartcodigo === '' || this.txtartcodigo === null || this.txtartcodigo === undefined){
    this.where = ''
  }else{
    this.where = 'ART_CODIGO =\''+this.txtartcodigo+'\'';
  }
  
  //if (parametro !== '' && parametro !== undefined && parametro !== null) {
    const eR = await this.invhistorialserialService.erpBusquedaSerial('INV_TRNKARDEXOTR a', this.types[0].value, parametro,this.txtartcodigo);
    if (eR !== null) {
      if (eR[0] !== undefined) {
        this.manejarSeleccion(eR[0]);
        return;
      }else{
        this.busquedaSer.busquedainvSerial(parametro,this.txtartcodigo).subscribe((datos: any[]) => {
          this.spin=true;
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
          this.spin = false;
        });
      }
    }else{
      this.busquedaSer.busquedainvSerial(parametro,this.txtartcodigo).subscribe((datos: any[]) => {
        this.spin=true;
        this.arregloCons = datos;
        this.displayDialogBusquedaFast = true;
        this.spin = false;
      });
    }
  //}
  }
async revisarhistoria(){
  if(this.txtserie == '' || this.txtserie === null || this.txtserie === undefined){
    this.messageService.add({
      key: 'invhisserie',
      severity: 'error',
      summary: 'Consultar seriales',
      detail: 'Ingrese el código del artículo y del serial.'
    });
    return;
  }
    const rs=await this.invhistorialserialService.consultarhisserial(this.txtartcodigo,this.txtserie);
    if(rs !== null && rs !== undefined){
      this.dethistorialserial=rs;
      if(this.dethistorialserial.length >0){
        for(const data of this.dethistorialserial){
          data.TRNKARDEXOTR_FECHATRN=this.datePipe.transform(data.TRNKARDEXOTR_FECHATRN,'dd/MM/yyyy');
          if(this.txtartcodigo === '' || this.txtartcodigo === null || this.txtartcodigo === undefined){
            this.txtartcodigo = data.ART_CODIGO;
            this.txtnomarticulo=data.ART_NOMBRE;
          }
        }
      }
    }
  }
  manejarSeleccion(opcion){
    if( this.opcion === 'ARTICULO'){
      this.txtartcodigo=opcion.ART_CODIGO;
      this.txtnomarticulo=opcion.ART_NOMBRE;
      this.txtserie=''
    }
    if(this.opcion === 'SERIAL'){
      this.txtserie=opcion.TRNKARDEXOTR_NUMSERIE;
      
    }
    this.displayDialogBusquedaFast=false;
  }
}
