import { Component, OnInit, ViewChild } from '@angular/core';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { PresuplancuentasService } from "../conservicios/presuplancuentas.service";
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { Presudetplanc } from "../coninterfaces/presudetplanc";
import { Presuencplanc } from '../coninterfaces/presuencplanc';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
@Component({
  selector: 'app-presuplancuentas',
  templateUrl: './presuplancuentas.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class PresuplancuentasComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  indicador: any;
  //ag-grid
  rowSelection = 'multiple';
  public frameworkComponents;
  defaultColDefPresucon;
  rowStyle;
  detpresuplanselect: Presudetplanc;
  boolnuevo = false;
  boolconsolida= false;
  spin: boolean;
  opcion: string;
  types: SelectItem[];
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  busquedacampos: string[];
  tabla: string;
  where: string;
  columnDefspresuCon = [
    {
      headerName: 'Cuenta', field: 'CON_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {

        return false;
      },
    },
    {
      headerName: 'Descripcion', field: 'CON_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return false;
      },
    },
    {
      headerName: 'Enero', field: 'CON_DETPRES01', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Febrero', field: 'CON_DETPRES02', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Marzo', field: 'CON_DETPRES03', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Abril', field: 'CON_DETPRES04', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Mayo', field: 'CON_DETPRES05', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Junio', field: 'CON_DETPRES06', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Julio', field: 'CON_DETPRES07', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Agosto', field: 'CON_DETPRES08', cellEditor: 'cellPrueba', width: 85, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Septiembre', field: 'CON_DETPRES09', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Octubre', field: 'CON_DETPRES10', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Noviembre', field: 'CON_DETPRES11', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Diciembre', field: 'CON_DETPRES12', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
    {
      headerName: 'Acumulado', field: 'CON_DETPRESACM', cellEditor: 'cellPrueba', width: 110, editable: (params) => {
        return true;
      }, cellStyle: { textAlign: 'right' },
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: false,
          fecha: false,
          tamanocelda: 32,
          tienecalculos: true,
          int: true
        }
      }
    },
  ];
  constructor(
    private init: NuevoComponentService,
    public presuplancuentasService: PresuplancuentasService, private messageService: MessageService,
    private confirmationService: ConfirmationService,private auditoriagral: AuditoriagralService,
    private busquedaSer:InvbusquedaService

  ) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefPresucon = {
      editable: true,
      width: 130,
      objeto: 'presucon'
    };

  }
  ngOnInit() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = {}
    this.detpresuplanselect = {}
    this.presuplancuentasService.detpresupuestoarray=[]
  }
  manejarSenales(evento) {
    if (evento === 'Guardar') {
      this.spin=true
      this.guardar()
    }
    if (evento === 'Nuevo') {
      this.nuevo()
    }
    if (evento === 'Cancelar') {
      this.cencelar()
    }
    if (evento === 'Borrar') {
      this.confirmaeliminar()
    }
    if (evento === 'Consolidar'){
      this.consolida();
    }
    if (evento === 'Buscar'){
      this.busqueda();
    }
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  busqueda() {

    this.opcion = 'BUSQUEDA';
    this.types = [
      { label: 'Año', value: 'CON_ENCPRESANIO' },
      { label: 'Descripcion', value: 'CON_ENCDESCRIPCION' },

    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_ENCPRESCON';

    this.busquedaSer.busquedaPresucon().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  manejarSeleccion(opcion) {
    this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO=opcion.CON_ENCPRESANIO;
    this.busquedaanio(opcion.CON_ENCPRESANIO)
    this.displayDialogBusquedaFast=false;
  }
  async consolida(){
    await this.presuplancuentasService.consolidarcuentas(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO).catch(e=>{
      this.messageService.add({
        key: 'preplanc',
        severity: 'error',
        summary: 'Consolidar Encabezado Presupuesto',
        detail: e.error
      });
   
      return
    });
    this.auditoriagral.registrarAuditoria('CON_ENCPRESCON',this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO, 'CO',
    '', '01', '', '', '', '').subscribe(() => {});
    this.messageService.add({
      key: 'preplanc',
      severity: 'success',
      summary: 'Insertar Presupuesto',
      detail: 'Se consolidó con éxito el presupuesto'
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    await this.busquedaanio(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO)
  }
  confirmaeliminar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el presupuesto',
      header: 'Eliminar Caja',
      icon: 'pi pi-exclamation-triangle',
      key: 'PrePlanc',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.eliminar();
      },
      reject: () => {
      }
    });
  }
  async eliminar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.boolnuevo = false;
    this.boolconsolida=false;

    if (this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CONTROL === 0) {
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = {};
      this.presuplancuentasService.detpresupuestoarray = [];
    }else{
      await this.presuplancuentasService.eliminarregistro(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO).catch(e=>{
        this.messageService.add({
          key: 'preplanc',
          severity: 'error',
          summary: 'Eliminar Encabezado Presupuesto',
          detail: e.error
        });
     
        return
      });
      this.messageService.add({
        key: 'preplanc',
        severity: 'success',
        summary: 'Insertar Presupuesto',
        detail: 'Se eliminó con éxito el presupuesto'
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESCON',this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO, 'E',
      '', '01', '', '', '', '').subscribe(() => {});
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = {};
      this.presuplancuentasService.detpresupuestoarray = [];
    }
  }
  cencelar() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    if (this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CONTROL === 0||
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray.length === 0) {
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = {};
      //this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CONTROL=0
      this.presuplancuentasService.detpresupuestoarray = [];
      this.aggrid.habilitarGrid();
    } else {
      this.busquedaanio(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO)
    }
  }
  async guardar() {
    let enc: Presuencplanc = this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador];
    if (enc.CON_ENCPRESANIO === null || enc.CON_ENCPRESANIO === undefined || enc.CON_ENCPRESANIO === '') {
      this.messageService.add({
        key: 'preplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Año no puede estar vacío'
      });
      this.spin=false;
      return;
    } else if (enc.CON_ENCDESCRIPCION === null || enc.CON_ENCDESCRIPCION === undefined || enc.CON_ENCDESCRIPCION === '') {
      this.messageService.add({
        key: 'preplanc',
        severity: 'error',
        summary: 'INFORMACIÓN',
        detail: 'El campo Descripción no puede estar vacío'
      });
      this.spin=false;
      return;
    } else {
      for (const rs of this.presuplancuentasService.detpresupuestoarray) {


        if (isNullOrUndefined(rs.CON_DETPRES01) || isNullOrUndefined(rs.CON_DETPRES02) || isNullOrUndefined(rs.CON_DETPRES03) || isNullOrUndefined(rs.CON_DETPRES04)
          || isNullOrUndefined(rs.CON_DETPRES05) || isNullOrUndefined(rs.CON_DETPRES06) || isNullOrUndefined(rs.CON_DETPRES07) || isNullOrUndefined(rs.CON_DETPRES08)
          || isNullOrUndefined(rs.CON_DETPRES09) || isNullOrUndefined(rs.CON_DETPRES10) || isNullOrUndefined(rs.CON_DETPRES11) || isNullOrUndefined(rs.CON_DETPRES12)
          || isNullOrUndefined(rs.CON_DETPRESACM)
        ) {
          this.messageService.add({
            key: 'preplanc',
            severity: 'error',
            summary: 'INFORMACIÓN',
            detail: 'Revise los valores de la cuenta ' + rs.CON_CODIGO
          });
          this.spin=false;
          return;
        }
      }
      await this.finguardar();
    }



  }
  async finguardar() {
    let enc: Presuencplanc = this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador];
    if (enc.CONTROL === 0) {
      await this.presuplancuentasService.insertarplancenc(enc).catch(e => {
        this.messageService.add({
          key: 'preplanc',
          severity: 'error',
          summary: 'Insertar Encabezado Presupuesto',
          detail: e.error
        });
        this.spin = false;
        return
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESCON',enc.CON_ENCPRESANIO, 'I',
      '', '01', '', '', '', '').subscribe(() => {});

      for (const rs of this.presuplancuentasService.detpresupuestoarray) {
        //rs.CON_DETPRES01=rs.CON_DETPRES01;
        await this.presuplancuentasService.insertarplancdet(rs).catch(e => {
          this.messageService.add({
            key: 'preplanc',
            severity: 'error',
            summary: 'Insertar Detalle Presupuesto de la cuenta ' + rs.CON_CODIGO,
            detail: e.error
          });
          this.spin = false;
          return
        });
        this.auditoriagral.registrarAuditoria('CON_DETPRESCON',rs.CON_ENCPRESANIO+'/'+rs.CON_CODIGO+'/'+rs.CON_DETPRESACM, 'I',
        '', '01', '', '', '', '').subscribe(() => {});
      }
    } else {
      await this.presuplancuentasService.actualizarplancenc(enc).catch(e => {
        this.messageService.add({
          key: 'preplanc',
          severity: 'error',
          summary: 'Insertar Encabezado Presupuesto',
          detail: e.error
        });
        this.spin = false;
        return
      });
      this.auditoriagral.registrarAuditoria('CON_ENCPRESCON',enc.CON_ENCPRESANIO, 'A',
      '', '01', '', '', '', '').subscribe(() => {});
      for (const rs of this.presuplancuentasService.detpresupuestoarray) {
        await this.presuplancuentasService.actualizarplancdet(rs).catch(e => {
          this.messageService.add({
            key: 'preplanc',
            severity: 'error',
            summary: 'Insertar Detalle Presupuesto de la cuenta ' + rs.CON_CODIGO,
            detail: e.error
          });
          this.spin = false;
          return
        });
        this.auditoriagral.registrarAuditoria('CON_DETPRESCON',rs.CON_ENCPRESANIO+'/'+rs.CON_CODIGO+'/'+rs.CON_DETPRESACM, 'A',
        '', '01', '', '', '', '').subscribe(() => {});
      }
    }
    this.messageService.add({
      key: 'preplanc',
      severity: 'success',
      summary: 'Insertar Presupuesto',
      detail: 'Se guardó con éxito el presupuesto'
    });
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.spin=false;
    await this.busquedaanio(enc.CON_ENCPRESANIO)
  }
  nuevo() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    this.boolnuevo = false;
    this.boolconsolida = false;
    this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = {};
    this.presuplancuentasService.detpresupuestoarray = [];
    this.aggrid.habilitarGrid();
    const dato: Presuencplanc = {
      CON_ENCPRESANIO: '',
      CON_ENCDESCRIPCION: '',
      COM_CODIGO: '01',
      CON_ENCPRESCONSOLIDA: 0,
      CONTROL: 0,
    }
    this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = dato
    document.getElementById('CON_ENCPRESANIO').focus();
  }
  async busquedaanio(parametro) {
    if(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO === null ||
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO === undefined||
      this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO ===''){
        this.messageService.add({
          key: 'preplanc',
          severity: 'error',
          summary: 'INFORMACIÓN',
          detail: 'El campo Año no puede estar vacío'
        });
        this.spin=false;
        return;
      }
    const rs1 = await this.presuplancuentasService.consultaplancenc(parametro);
    const rs = await this.presuplancuentasService.consultaplancdet(parametro);
    if (rs1 !== null && rs1 !== undefined && rs1.length > 0) {
      for (const pre of rs1) {
        pre.CON_ENCPRESANIO = this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESANIO;
        pre.CON_ENCDESCRIPCION = pre.CON_ENCDESCRIPCION === null || pre.CON_ENCDESCRIPCION === undefined ? '' : pre.CON_ENCDESCRIPCION;
       
        this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador] = pre;
        if (this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CONTROL === 1
          || this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESCONSOLIDA === 1) {
          this.boolnuevo = true
        } else {
          this.boolnuevo = false
        }
      }
    } else {
      this.boolnuevo = false;
    }
    if (rs !== null && rs !== undefined && rs.length > 0) {
      this.presuplancuentasService.detpresupuestoarray = rs
      for (const item of this.presuplancuentasService.detpresupuestoarray) {
        item.CON_DETPRES01 = Number(item.CON_DETPRES01).toFixed(2)
        item.CON_DETPRES02 = Number(item.CON_DETPRES02).toFixed(2)
        item.CON_DETPRES03 = Number(item.CON_DETPRES03).toFixed(2)
        item.CON_DETPRES04 = Number(item.CON_DETPRES04).toFixed(2)
        item.CON_DETPRES05 = Number(item.CON_DETPRES05).toFixed(2)
        item.CON_DETPRES06 = Number(item.CON_DETPRES06).toFixed(2)
        item.CON_DETPRES07 = Number(item.CON_DETPRES07).toFixed(2)
        item.CON_DETPRES08 = Number(item.CON_DETPRES08).toFixed(2)
        item.CON_DETPRES09 = Number(item.CON_DETPRES09).toFixed(2)
        item.CON_DETPRES10 = Number(item.CON_DETPRES10).toFixed(2)
        item.CON_DETPRES11 = Number(item.CON_DETPRES11).toFixed(2)
        item.CON_DETPRES12 = Number(item.CON_DETPRES12).toFixed(2)
        item.CON_DETPRESACM = Number(item.CON_DETPRESACM).toFixed(2)
      }
    }
    this.aggrid.refreshaggrid(this.presuplancuentasService.detpresupuestoarray, this.defaultColDefPresucon.objeto);
    // if(this.presuplancuentasService.encabezadoPresuSeleccionadoarray[this.indicador].CON_ENCPRESCONSOLIDA === 1){
    //   this.aggrid.bloquearGrid()
    //   this.boolconsolida=true;
    // }
  }
  selectedPresuCon(params) {
    if (params.object === undefined || params.object === null) {
      return;
    }
    this.detpresuplanselect = params.object;
    this.validaedicion();
  }
  validaedicion() {
    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresucon.objeto].getFocusedCell() === null) {
      return;
    }
    console.log(this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresucon.objeto].getFocusedCell().column)

    if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresucon.objeto].getFocusedCell().column === null) {
      return;
    }
    const colid = this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresucon.objeto].getFocusedCell().column.colId
    // if (this.detpresuplanselect.NUEVO === true) {
    this.columnDefspresuCon[0].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[1].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[2].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[3].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[4].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[5].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[6].cellEditor = 'cellPrueba';
    this.columnDefspresuCon[7].cellEditor = 'cellPrueba';

    this.columnDefspresuCon[2].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[3].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[4].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[5].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[6].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[7].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[8].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[9].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[10].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[11].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[12].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.columnDefspresuCon[13].editable = (params) => {
      const boolEdit = this.isedit(params.data);
      return boolEdit;
    };
    this.aggrid.refreshColumnDefs();
    this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefPresucon.objeto].setFocusedCell(
      this.presuplancuentasService.detpresupuestoarray.indexOf(this.detpresuplanselect), colid);
    //}
  }
  isedit(data) {
    let boolean = true;
    if (data.CON_CODIGO.substr(-1, 1) === '.') {
      boolean = false
    }
    return boolean;
  }
  cambio(evento) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
  }
}
