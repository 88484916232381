import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioEsquema} from '../../usuarioEsquema';
import {Usuario} from '../../usuario';
import {Conmaecuenta} from '../coninterfaces/conmaecuenta';
import {Observable} from 'rxjs';
import {Conmaepresupuesto} from '../coninterfaces/conmaepresupuesto';
import {ConmaeMayorGeneral} from '../coninterfaces/conmaemayorgeneral';
import {log} from 'util';
import {Conmaeparametro} from '../coninterfaces/conmaeparametro';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';

@Injectable({
  providedIn: 'root'
})
export class ConmaeconService {
  
  
  get commaecuenta(): Conmaecuenta[] {
    return this._commaecuenta;
  }
  
  set commaecuenta(value: Conmaecuenta[]) {
    this._commaecuenta = value;
  }
  
  get conmaePresupuesto(): Conmaepresupuesto[] {
    return this._conmaepresupuesto;
  }
  
  set conmaePresupuesto(value: Conmaepresupuesto[]) {
    this._conmaepresupuesto = value;
  }
  
  
  get conmaeMayorGeneral(): ConmaeMayorGeneral[] {
    return this._conmaemayorgeneral;
  }
  
  set conmaeMayorGeneral(value: ConmaeMayorGeneral[]) {
    this._conmaemayorgeneral = value;
  }
  
  get conmaeparametro(): Conmaeparametro[] {
    return this._conmaeparametro;
  }
  
  set conmaeparametro(value: Conmaeparametro[]) {
    this._conmaeparametro = value;
  }
  
  public way: string;
  private _auxsalto: boolean;
  private _commaecuenta: Conmaecuenta[];
  private _conmaepresupuesto: Conmaepresupuesto[];
  private _conmaemayorgeneral: ConmaeMayorGeneral[];
  private _conmaeparametro: Conmaeparametro[];
  
  constructor(private http: HttpClient,
              public usuarioEsquema: UsuarioEsquema,
              public usuario: Usuario,
              private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  
  getCuentas(): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/cuentas/pMEyBblyq1LuEJv', {
        elementos: {},
      }, this.confIniciales.httpOptions());
  }
  
  existeCuenta(cuenta): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/cuentas/RsTZT5ljyYTKru0', {
        elementos: {con_codigo: cuenta},
      }, this.confIniciales.httpOptions());
  }
  
  VerificarCuentaUso(cuenta): Observable<any[]> {
    return this.http.post<any[]>(this.way + '/cont/cuentas/rjH31th2H3v2kNe', {
        elementos: {con_codigo: cuenta},
      }, this.confIniciales.httpOptions());
  }
  
  
  insertarCuenta(cuenta: Conmaecuenta): Observable<any[]> {
    console.log(cuenta);
    return this.http.post<any>(this.way + '/cont/cuentas/V0ggj8OihePyvFr', {
      elementos: {
        p_CON_codigo_1: cuenta.CON_CODIGO,
        p_CON_nombre_2: cuenta.CON_NOMBRE,
        p_CON_centro_3: cuenta.CON_CENTRO,
        p_CON_centrosub_4: '',
        p_COM_codigo_5: '01', // near campos
        p_con_nivel: cuenta.CON_NIVEL,
        p_con_codsri: cuenta.CON_CODSRI,
        p_con_tipgas:  cuenta.CON_TIPGAS,
        p_con_supercia:cuenta.CON_SUPERCIA,
      
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarCuenta(cuenta: Conmaecuenta, codigo): Observable<any[]> {
    console.log(cuenta);
    return this.http.post<any>(this.way + '/cont/cuentas/Ij9U3JitAWa35DB', {
      elementos: {
        p_CON_codigop: codigo,
        p_CON_codigo_1: cuenta.CON_CODIGO,
        p_CON_nombre_2: cuenta.CON_NOMBRE,
        p_CON_centro_3: cuenta.CON_CENTRO,
        p_CON_centrosub_4: cuenta.CON_CENTROSUB,
        p_COM_codigo_5: '01',
        p_con_nivel: cuenta.CON_NIVEL, // revisar
        p_con_codsri: cuenta.CON_CODSRI,
        p_con_tipgas: cuenta.CON_TIPGAS,
        p_con_supercia:cuenta.CON_SUPERCIA,
      }
    }, this.confIniciales.httpOptions());
  }
  
  eliminarCuenta(cuenta: Conmaecuenta, codigo): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/cuentas/lm8CTFwpksGilP5', {
      elementos: {
        p_CON_codigop: codigo,
        p_COM_codigop: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  obtenerMayorGeneral(cuenta: Conmaecuenta): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/cuentas/bshTrfpfZrRfxOY', {
      elementos: {
        p_CON_codigop: cuenta.CON_CODIGO,
        p_COM_codigop: '01'
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  ConsultarCuenta(): Observable<any[]> {
    
    return this.http.post<any>(this.way + '/cont/cuentas/S4IHoE61wzZQpHN', {
      elementos: {
        p_opcion: '',
        p_filtro: '01',
        p_condicion: ''
      }
    }, this.confIniciales.httpOptions());
  }
  
  
  obtenerPresupuesto(cuenta: Conmaecuenta): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/cuentas/lBy1aEvo33Pb3tz', {
      elementos: {
        p_CON_codigop: cuenta.CON_CODIGO,
        p_COM_codigop: '01'
      }
    }, this.confIniciales.httpOptions());
    
  }
  
  
  obtenerTipoCuenta(tipoCuenta): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/cuentas/fZRCYzqJeB8W86R', {
        elementos: {
          PAR_CODIGO: tipoCuenta
        },
      }, this.confIniciales.httpOptions());
  }
  
  
  verificarCuentaMaestra(cuenta: Conmaecuenta): Observable<any[]> {
    
    return this.http.post<any>(this.way + '/cont/cuentas/jsbw8u9md2UghQJ', {
      elementos: {
        p_CON_codigop: cuenta.CON_CODIGO,
        p_COM_codigop: '01'
      }
    }, this.confIniciales.httpOptions());
  }
  
  insertarPresupuesto(presupuesto: Conmaepresupuesto): Observable<any[]> {
    
    return this.http.post<any>(this.way + '/cont/cuentas/SKcQMnZa0KQNbLc', {
      elementos: {
        p_CON_codigo_1: presupuesto.CON_CODIGO,
        p_PRE_dma_2: presupuesto.PRE_DMA,
        p_PRE_valor_3: Number(presupuesto.Valor),
        p_COM_codigo_4: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  actualizarPresupuesto(presupuesto: Conmaepresupuesto): Observable<any[]> {
    return this.http.post<any>(this.way + '/cont/cuentas/CIMRUoQQBdpENdc', {
      elementos: {
        p_CON_codigo_1: presupuesto.CON_CODIGO,
        p_PRE_dma_2: presupuesto.PRE_DMA,
        p_PRE_valor_3: Number(presupuesto.Valor),
        p_COM_codigo_4: '01',
      }
    }, this.confIniciales.httpOptions());
  }
  
  existePresupuesto(presupuesto): Observable<any[]> {
    
    return this.http.post<any[]>(this.way + '/cont/cuentas/Oq8kBV0YFtayN21', {
        elementos: {
          con_codigo: presupuesto.CON_CODIGO
        },
      }, this.confIniciales.httpOptions());
  }
  
  calculosPresupuesto(arregloCuentaPresupuesto) {
    
    let con = 0;
    let totalprevalor;
    let totaldebe;
    let totalhaber;
    let totalsaldo;
    
    totalprevalor = 0;
    totaldebe = 0;
    totalhaber = 0;
    totalsaldo = 0;
    for (let i = 0; i < 19; i++) {
      const cuenta = {
        Valor: 0,
        Debe: 0,
        Haber: 0,
        Saldo: 0
      };
      if (con === 3) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 0; i < 3; i++) {
          totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
          totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
          totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[3].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[3].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[3].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[3].Saldo = totalsaldo.toFixed(2);
        con++;
        // trimestre 2
      }
      if (con === 7) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 4; i < 7; i++) {
          totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
          totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
          totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[7].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[7].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[7].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[7].Saldo = totalsaldo.toFixed(2);
        con++;
        
      }
      // SEMETRE 1
      if (con === 8) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 0; i < 8; i++) {
          if (i === 3 || i === 7) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[8].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[8].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[8].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[8].Saldo = totalsaldo.toFixed(2);
        con++;
        
      }
      //
      if (con === 12) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 9; i < 12; i++) {
          
          totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
          totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
          totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[12].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[12].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[12].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[12].Saldo = totalsaldo.toFixed(2);
        con++;
      }
      if (con === 16) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 13; i < 16; i++) {
          totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
          totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
          totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
        }
        
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[16].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[16].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[16].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[16].Saldo = totalsaldo.toFixed(2);
        con++;
      }
      
      if (con === 17) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 0; i < 17; i++) {
          if (i === 12 || i === 16) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[17].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[17].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[17].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[17].Saldo = totalsaldo.toFixed(2);
        con++;
      }
      if (con === 18) {
        totalprevalor = 0;
        totaldebe = 0;
        totalhaber = 0;
        totalsaldo = 0;
        for (let i = 0; i < 18; i++) {
          if (i === 8 || i === 17) {
            totalprevalor = totalprevalor + Number(arregloCuentaPresupuesto[i].Valor);
            totaldebe = totaldebe + Number(arregloCuentaPresupuesto[i].Debe);
            totalhaber = totalhaber + Number(arregloCuentaPresupuesto[i].Haber);
          }
        }
        totalhaber = Number(totalhaber) * -1;
        totalsaldo = Number(totalhaber) - Number(totaldebe);
        
        this.conmaePresupuesto[18].Valor = totalprevalor.toFixed(2);
        this.conmaePresupuesto[18].Debe = totaldebe.toFixed(2);
        this.conmaePresupuesto[18].Haber = totalhaber.toFixed(2);
        this.conmaePresupuesto[18].Saldo = totalsaldo.toFixed(2);
        con++;
      }
      con++;
    }
  }
  
}
