import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { bantrninversiones } from "../baninterfaces/bantrninversiones";
import { BantrninversionesService } from "../banservicios/bantrninversiones.service";
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';
import { isNullOrUndefined } from 'util';
import { bantrninv } from "../baninterfaces/bantrninv";
@Component({
  selector: 'app-bantrninversiones',
  templateUrl: './bantrninversiones.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BantrninversionesComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  botonPrimero: boolean;
  botonAnterior: boolean;
  botonSiguiente: boolean;
  botonUltimo: boolean;
  btnBuscar: boolean;
  btnGridBorrar: boolean;

  lblTrnBanNumeroRet: string = '';
  bantrninversion: bantrninversiones = {};
  lblNombreBanco: string = '';
  tarjdis: boolean = false;
  boolreversavenci: boolean = false;
  boolprocesa: boolean = false;
  selectTab = 0;
  largo: string;
  indicador: any;
  dettrnretencion: SaciTrnretencion = {};//CDPJ
  detselectbantrninversion: bantrninv;
  public frameworkComponents;
  rowStyle;
  rowSelection = 'multiple';
  defaultbantrninv
  finplazoSeleccionado: string;
  disabledTabDatos: boolean = false;
  disabledTabInteres: boolean = false;
  disabledTabImpu: boolean = false;
  disabledTabDiavenci: boolean = false;
  disabledTabPrecan: boolean = false;
  permisoInver: SegMaePermiso;
  opcion: string;
  types: SelectItem[];
  tabla: string;
  consulta = '';
  busquedacampos: string[];
  arregloBus: any[];
  displayDialogBusquedaFast: boolean;
  trbanvenci: ''
  lblNomCtaCert: string = '';
  lblNomCtaInteres: string = '';
  lblNomCtaOtrosInteres: string = '';
  lblNomCtaFPAper: string = '';
  lblNomCtaFPCierre: string = '';
  lblCntaIsd: string = '';
  lblNomCtaBanco: string = '';
  lblNombreEntFin: string = '';
  lblNombreIsd: string = '';
  cmbasiento: any[];
  selectasiento: any = {};
  asiento: any;
  displayAsiContable: boolean;
  auxiliar1: number;

  cmbasientoin: any[];
  selectasientoin: any = {};
  where = ''
  lblfechainteres
  lblNombreret
  lblporcentajeret
  displayDialogDocumentos = false;
  sumaInteres: number;



  constructor(
    public usuario: Usuario, private permisosService: PermisosService,
    private message: MessageService, private errorService: ErroresBaseDatosService,
    private datePipe: DatePipe, private busquedaSer: InvbusquedaService,
    private auditoriagral: AuditoriagralService, private confirmationService: ConfirmationService,
    private utilitariosService: UtilitariosService,
    private confIniciales: ConfInicialesService,
    public bantrninversionesService: BantrninversionesService,
    private init: NuevoComponentService,
  ) {
    this.agTable();

  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };

    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };

    this.defaultbantrninv = {
      objeto: 'bantrninver',
      resizable: true
    };

  }
  columnDefsInvtrninver = [
    {
      headerName: 'Fecha', field: 'TRNINTINV_FECHA', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Días Trnascurridos', field: 'TRNINTINV_DIAS', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Interés Mensual', field: 'TRNINTINV_INTERES', cellEditor: 'cellPrueba', width: 100, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Asiento', field: 'ASI_NRO', cellEditor: 'cellPrueba', width: 150, editable: true,
      cellEditorParams:
      {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 1,
          obligatorio: false,
          fecha: false,
          tamanocelda: 50,
          tienecalculos: true,
        }
      }
    },
  ]
  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.btnBuscar = false;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnGridBorrar = false;
    this.bantrninversion = {};
    this.bantrninversionesService.detbantrninversion[this.indicador]  = []
    this.bantrninversionesService.dettrnretencion = {}
    this.cmbasiento = [{ codigo: '', name: '' }];
    this.cmbasientoin = [{ codigo: '', name: '' }];
    this.largo = '400';
    this.lblfechainteres = new Date()
    this.selectTab = 0;
    this.tarjdis = false;

    //await this.getConfiguracion();
    await this.irnuevo();
    await this.getPermisos();
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasTrninversiones--;
  }
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoInver = data[0];
        } else {
          this.permisoInver = null;
        }
      } else {
        this.permisoInver = null;
      }
    } catch (e) {
      this.permisoInver = null;
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(valor) {
    if (valor === 'Nuevo') {
      if (this.selectTab !== 0) {
        this.selectTab = 0;
        this.cambiarbotones(true, true, false, true);
        this.irnuevo();
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('ENTFIN_CODIGO').focus();
      } else {
        this.cambiarbotones(true, true, false, true);
        this.irnuevo();
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        document.getElementById('ENTFIN_CODIGO').focus();
      }
      //this.nuevo();
    }
    if (valor === 'Cancelar') {
      this.cancelar();
    }
    if (valor === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (valor === 'Guardar') {
      if (this.selectTab === 2) {
        this.empiezaguardarret();
      } else if (this.selectTab === 4) {
        this.empiezaguardarprecan();
      } else {
        this.empiezaguardar();
      }
    }
    if (valor === 'Buscar') {
      this.buscar()
    }
    if (valor === 'Borrar') {
      if (this.selectTab === 0) {
        this.eliminarinv();
      }
      if (this.selectTab === 2) {
        this.eliminarret();
      }
    }
    if (valor === 'AsientoVer') {
      if (this.selectTab === 0) {
        this.abrirasientoDatos()
      }
      if (this.selectTab === 1) {
        this.abrirasientoInteres()
      }
      if (this.selectTab === 3) {
        this.abrirasientoDiaVenci()
      }
      if (this.selectTab === 4) {
        this.abrirasientoPreCan()
      }
    }
  }

  abrirasientoDatos() {
    this.asiento = this.selectasiento.codigo;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }
  abrirasientoInteres() {
    this.asiento = this.selectasientoin.codigo;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }
  abrirasientoDiaVenci() {
    this.asiento = this.bantrninversion.ASI_FINPLAZO;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }
  abrirasientoPreCan() {
    this.asiento = this.bantrninversion.ASI_PRECANCELA;
    if (this.asiento === null || this.asiento === undefined || this.asiento === '') {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Información',
        detail: 'No ha generado el Asiento Contable'
      });
    } else {
      this.auxiliar1++;
      this.displayAsiContable = true;
    }
  }
  recibirSenalesConEncasil(valor) {
    if (valor === true) {
      this.displayAsiContable = false;
    }
  }
  eliminarinv() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'inver',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        try {
          await this.bantrninversionesService.eliminarreg(this.bantrninversion.BANINV_CERTIFICADO);
          this.message.add({
            key: 'inversion',
            severity: 'success',
            summary: 'Informacion',
            detail: 'Se eliminó con éxito el registro'
          });
          this.auditoriagral.registrarAuditoria('BAN_TRNINVERSION',
            this.bantrninversion.BANINV_CERTIFICADO + '/' + this.bantrninversion.BANINV_CERTIFICADOAPER, 'E',
            '', '01', '', '', '', '').subscribe();
          this.cmbasiento = []
          this.cancelar()
        } catch (error) {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al eliminar el registro'
          });
          return;
        }
      },
      reject: () => {
        return;
      }
    });
  }
  eliminarret() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar la retención?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'inver',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        try {
          await this.bantrninversionesService.eliminarret(this.bantrninversionesService.dettrnretencion);
          this.message.add({
            key: 'inversion',
            severity: 'success',
            summary: 'Informacion',
            detail: 'Se eliminó la retención con éxito el registro'
          });
          this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION',
            this.bantrninversion.BANINV_CERTIFICADO + '/' + this.bantrninversion.BANINV_CERTIFICADOAPER + '/' + this.bantrninversionesService.dettrnretencion.TRNRETENCION_NRO, 'E',
            '', '01', '', '', '', '').subscribe();
          this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO)
        } catch (error) {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al eliminar el registro'
          });
          return;
        }
      },
      reject: () => {
        return;
      }
    });
  }
  buscar() {
    this.opcion = 'BUSCAR';
    this.types = [
      { label: 'CERTIFICADO', value: 'BANINV_CERTIFICADO' },
      { label: 'APERTURA', value: 'BANINV_CERTIFICADOAPER' },
      { label: 'ESTADO', value: 'BANINV_ESTADO' },
      { label: 'FECHAEMISION', value: 'BANINV_FECHAEMISION' },
      { label: 'FECHAVENCIMIENTO', value: 'BANINV_FECHAVENCIMIENTO' },
      { label: 'VALORNOMINAL', value: 'BANINV_VALORNOMINAL' },
      { label: 'PLAZO', value: 'BANINV_VALORNOMINAL' },
      { label: 'INTERESANUAL', value: 'BANINV_INTERESANUAL' },
    ];
    this.busquedacampos = ['', '', '', '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'BAN_TRNINVERSION';
    this.where = '';
    this.busquedaSer.busquedaTabla('*', 'BAN_TRNINVERSION', '').subscribe((datos: any[]) => {
      this.arregloBus = datos;
      this.arregloBus.map(rs => {
        if (rs.BANINV_FECHAEMISION !== null) {
          rs.BANINV_FECHAEMISION = this.datePipe.transform(rs.BANINV_FECHAEMISION, 'dd/MM/yyyy')
        }
        if (rs.BANINV_FECHAVENCIMIENTO !== null) {
          rs.BANINV_FECHAVENCIMIENTO = this.datePipe.transform(rs.BANINV_FECHAVENCIMIENTO, 'dd/MM/yyyy')
        }
      })
      this.displayDialogBusquedaFast = true;
    })

  }
  async empiezaguardarret() {
    try {
      if (this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION.toString().includes('-')) {
        this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION = this.datePipe.transform(this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION, 'dd/MM/yyyy')
      } else {
        this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION = this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION
      }
      await this.bantrninversionesService.guardartretencion(this.bantrninversionesService.dettrnretencion, this.bantrninversion.BANINV_CERTIFICADO);
      await this.actualizadatosadic()
      await this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO)
      this.auditoriagral.registrarAuditoria('SACI_TRNRETENCION',
        this.bantrninversion.BANINV_CERTIFICADO + '/' + this.bantrninversion.BANINV_CERTIFICADOAPER + '/' + this.bantrninversionesService.dettrnretencion.TRNRETENCION_NRO, 'I',
        '', '01', '', '', '', '').subscribe();

      this.message.add({
        key: 'inversion',
        severity: 'success',
        summary: 'Informacion',
        detail: 'Se guardó correctamente la retención'
      });
      this.btnBuscar = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al guardar la retención '
      });
      return;
    }
  }
  async actualizadatosadic() {
    try {
      await this.bantrninversionesService.actdatosadic(this.bantrninversion.BANINV_ISD, this.bantrninversion.ASI_CTAISD, this.bantrninversion.BANINV_CERTIFICADO);
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al actualizar los datos adicionales '
      });
      return;
    }
  }
  async empiezaguardarprecan() {
    try {
      if (this.bantrninversion.BANINV_FECHAPRECANCELA.toString().includes('-')) {
        this.bantrninversion.BANINV_FECHAPRECANCELA = this.datePipe.transform(this.bantrninversion.BANINV_FECHAPRECANCELA, 'dd/MM/yyyy')
      } else {
        this.bantrninversion.BANINV_FECHAPRECANCELA = this.bantrninversion.BANINV_FECHAPRECANCELA
      }
      await this.bantrninversionesService.actdatoprecan(this.bantrninversion);
      await this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO)
      this.message.add({
        key: 'inversion',
        severity: 'success',
        summary: 'Informacion',
        detail: 'Registro guardado con éxito '
      });
      this.btnBuscar = false;
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;

    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al actualizar los datos '
      });
      return;
    }
  }
  async empiezaguardar() {
    const op = this.finplazoSeleccionado === 'fnpRenova' ? 'R' : this.finplazoSeleccionado === 'fnpCanL' ? 'CL' : this.finplazoSeleccionado === 'fnpCanE' ? 'CE' : '';
    this.bantrninversion.BANINV_TIPOFINPLAZO = op;
    let error = 0;
    if (this.validaVacios(this.bantrninversion.ENTFIN_CODIGO)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Nombre de la Entidad bancaria incorrecta'
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_CERTIFICADO)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Número del certificado es incorrecto '
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_CERTIFICADOAPER)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Número del certificado de apertura es incorrecto '
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_ORIGENFONDOS)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Origen de los fondos es incorrecto '
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_DESTINOFONDOS)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Destino de los fondos es incorrecto '
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_CTACERTIFICADO)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Cuenta contable del certificado es  incorrecto '
      });
      error++;
      return;
    }
    if (this.validaVacios(this.bantrninversion.BANINV_CTAINTERES)) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Cuenta contable de los intereses es  incorrecto'
      });
      error++;
      return;
    }
    if (error === 0) {
      await this.finguardar();
    }

  }
  validaVacios(cadena) {
    let bool = false;
    if (isNullOrUndefined(cadena) || cadena === '') {
      bool = true;
    }
    return bool;
  }
  async finguardar() {
    try {
      if (this.bantrninversion.BANINV_FECHAEMISION.toString().includes('-')) {
        this.bantrninversion.BANINV_FECHAEMISION = this.datePipe.transform(this.bantrninversion.BANINV_FECHAEMISION, 'dd/MM/yyyy');
      } else {
        this.bantrninversion.BANINV_FECHAEMISION = this.bantrninversion.BANINV_FECHAEMISION;
      }

      if (this.bantrninversion.BANINV_FECHAVENCIMIENTO.toString().includes('-')) {
        this.bantrninversion.BANINV_FECHAVENCIMIENTO = this.datePipe.transform(this.bantrninversion.BANINV_FECHAVENCIMIENTO, 'dd/MM/yyyy');
      } else {
        this.bantrninversion.BANINV_FECHAVENCIMIENTO = this.bantrninversion.BANINV_FECHAVENCIMIENTO;
      }
      const dato = await this.bantrninversionesService.insertarbaninv(this.bantrninversion);

      if (dato !== null && dato !== undefined && dato.length > 0) {
        for (const iterator of dato) {
          console.log(iterator)
          await this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO)
          await this.generarasiento()
          await this.insertaDepositosYRetiros('RE')
          if (iterator[':B1'] === 0) {
            this.auditoriagral.registrarAuditoria('BAN_TRNINVERSION',
              this.bantrninversion.BANINV_CERTIFICADO + '/' + this.bantrninversion.BANINV_CERTIFICADOAPER, 'I',
              '', '01', '', '', '', '').subscribe();
            this.message.add({
              key: 'inversion',
              severity: 'success',
              summary: 'Informacion',
              detail: 'Se guardó correctamente el registro'
            });
            this.btnBuscar = false;
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
          } else {
            this.auditoriagral.registrarAuditoria('BAN_TRNINVERSION',
              this.bantrninversion.BANINV_CERTIFICADO + '/' + this.bantrninversion.BANINV_CERTIFICADOAPER, 'A',
              '', '01', '', '', '', '').subscribe();
            this.message.add({
              key: 'inversion',
              severity: 'success',
              summary: 'Informacion',
              detail: 'Se actualizó correctamente el registro'
            });
            this.btnBuscar = false;
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
          }
        }
      }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al guadar la inversión'
      });
      return;
    }
  }
  async encontrarregistro(valor) {
    this.bantrninversion = {};
    this.bantrninversionesService.detbantrninversion[this.indicador]  = []
    this.bantrninversionesService.dettrnretencion = {}
    this.cmbasiento = [];
    this.cmbasientoin = [];
    const dato = await this.bantrninversionesService.getbainvdato(valor)

    if (dato !== null && dato !== undefined && dato.length > 0) {
      for (let iterator of dato) {
        iterator.BANINV_FECHAEMISION = new Date(iterator.BANINV_FECHAEMISION);
        iterator.BANINV_FECHAVENCIMIENTO = new Date(iterator.BANINV_FECHAVENCIMIENTO);
        iterator.BANINV_FECHAPRECANCELA = new Date(iterator.BANINV_FECHAPRECANCELA);
        iterator.BANINV_VALORNOMINAL = Number(iterator.BANINV_VALORNOMINAL).toFixed(2)
        iterator.BANINV_INTERESANUAL = Number(iterator.BANINV_INTERESANUAL).toFixed(2)
        iterator.BANINV_RENTABILIDADVENC = Number(iterator.BANINV_RENTABILIDADVENC).toFixed(2)
        iterator.BANINV_INTERESDIARIO = Number(iterator.BANINV_INTERESDIARIO).toFixed(2)
        iterator.BANINV_ISD = iterator.BANINV_ISD === null || iterator.BANINV_ISD === undefined ? '0.00' : Number(iterator.BANINV_ISD).toFixed(2)
        iterator.BANINV_DIFERENCIA = iterator.BANINV_DIFERENCIA === null || iterator.BANINV_DIFERENCIA === undefined ? '0.00' : Number(iterator.BANINV_DIFERENCIA).toFixed(2)

        this.bantrninversion = iterator
        this.lblTrnBanNumeroRet = this.bantrninversion.TRNBAN_NUMERO_APER
        this.bantrninversion.TRNBAN_NUMERO_V = this.trbanvenci === null || this.trbanvenci === undefined ? this.bantrninversion.TRNBAN_NUMERO_V : this.trbanvenci
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_FPAPER).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaFPAper = eR[0].CON_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarbanentifin(this.bantrninversion.ENTFIN_CODIGO ).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNombreBanco=eR[0].ENCFIN_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_FPCIERRE).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaFPCierre = eR[0].CON_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_CTACERTIFICADO).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaCert = eR[0].CON_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_CTAINTERES).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaInteres = eR[0].CON_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_CTAOTROSINT).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaOtrosInteres = eR[0].CON_NOMBRE;
            }
          }

        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.BANINV_CTAALTBANCO).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaBanco = eR[0].CON_NOMBRE;
            }
          }
        })
        this.bantrninversionesService.encontrarConNombre(this.bantrninversion.ASI_CTAISD).subscribe(eR => {
          if (eR !== null) {
            if (eR[0] !== undefined) {
              this.lblNomCtaBanco = eR[0].CON_NOMBRE;
            }
          }
        })
      }
      this.disabledTabInteres = false;
      this.disabledTabImpu = false;
      this.disabledTabDiavenci = false;
      this.disabledTabPrecan = false;
      document.getElementById('divPrincipal').style.pointerEvents = 'all'
      document.getElementById('divPrincipal2').style.pointerEvents = 'all'
      document.getElementById('divPrincipal3').style.pointerEvents = 'all'
      document.getElementById('divPrincipal3_1').style.pointerEvents = 'all'
      document.getElementById('divPrincipal3_2').style.pointerEvents = 'all'
      document.getElementById('divPrincipal4').style.pointerEvents = 'all'
      document.getElementById('divPrincipal5').style.pointerEvents = 'all'
    }
    await this.desplegarAsientos()
    await this.mostrarInteres();
    await this.desplegarAsientosInteres();
    await this.leeRetencion();

  }
  async leeRetencion() {
    try {
      this.bantrninversionesService.dettrnretencion = {}
      const data = await this.bantrninversionesService.getretenciones(this.bantrninversion.BANINV_CERTIFICADO);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const iterator of data) {
          iterator.TRNRETENCION_FECHAEMISION = iterator.TRNRETENCION_FECHAEMISION === null || iterator.TRNRETENCION_FECHAEMISION === undefined || iterator.TRNRETENCION_FECHAEMISION === '' ? new Date() : new Date(iterator.TRNRETENCION_FECHAEMISION)
          iterator.TRNRETENCION_BASE = iterator.TRNRETENCION_BASE === null || iterator.TRNRETENCION_BASE === undefined || iterator.TRNRETENCION_BASE === '' ? Number(this.sumaInteres).toFixed(2) : Number(iterator.TRNRETENCION_BASE).toFixed(2);
          iterator.TRNRETENCION_TOTALRETENIDO = iterator.TRNRETENCION_TOTALRETENIDO === null || iterator.TRNRETENCION_TOTALRETENIDO === undefined ? '0.00' : Number(iterator.TRNRETENCION_TOTALRETENIDO).toFixed(2);
          this.bantrninversionesService.dettrnretencion = iterator
          this.busquedaSer.encontrarRegistro2(iterator.RETENCION_CODIGO).subscribe(eR => {
            if (eR !== null) {
              if (eR[0] !== undefined) {
                this.lblNombreret = eR[0].RETENCION_NOMBRE;
                this.lblporcentajeret = eR[0].RETENCION_PORCENTAJE;
                this.bantrninversionesService.dettrnretencion.CON_CODIGO = eR[0].CON_CODIGO
              }
            }
          })
        }
      } else {
        this.bantrninversionesService.dettrnretencion.TRNRETENCION_BASE = Number(this.sumaInteres).toFixed(2)
        this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION = new Date()
        this.lblporcentajeret = '',
          this.lblNombreret = ''
      }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al consultar las retenciones'
      });
      return;
    }
  }
  async insertaDepositosYRetiros(valor) {
    try {
      let fecha;
      let valornom = 0;
      let bancod = '';
      let banbeni = '';
      let strnumcomprobante = '';
      let strBanTrnNumero = '';
      let strConcepto = '';
      let strAsiento = '';
      let concodigo = '';
      if (this.bantrninversion.BANINV_CERTIFICADO === this.bantrninversion.BANINV_CERTIFICADOAPER) {
        strnumcomprobante = this.bantrninversion.BANINV_CERTIFICADOAPER
      } else {
        strnumcomprobante = this.bantrninversion.BANINV_CERTIFICADOAPER + '/' + this.bantrninversion.BANINV_CERTIFICADO
      }
      if (valor === 'RE') {
        /// trae datos de de los bancos
        const data = await this.bantrninversionesService.consulbancodigoRE(this.bantrninversion.BANINV_FPAPER)
        if (data !== null && data !== null && data.length > 0) {
          for (const item of data) {
            bancod = item.BAN_CODIGO;
            banbeni = item.BAN_BANCO;
          }
        }


        if (this.bantrninversion.BANINV_FECHAEMISION.toString().includes('-')) {
          fecha = this.datePipe.transform(this.bantrninversion.BANINV_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          fecha = this.bantrninversion.BANINV_FECHAEMISION;
        }
        valornom = Number(Number(this.bantrninversion.BANINV_VALORNOMINAL) * -1)
        strBanTrnNumero = this.lblTrnBanNumeroRet;
        strConcepto = 'Retiro por apertura de inversión ' + strnumcomprobante;
        strAsiento = this.selectasiento.name
        concodigo = this.bantrninversion.BANINV_FPAPER;
      }
      if (valor === 'DP') {
        /// trae datos de de los bancos
        const data = await this.bantrninversionesService.consulbancodigoDP(this.bantrninversion.BANINV_CTAALTBANCO)
        if (data !== null && data !== null && data.length > 0) {
          for (const item of data) {
            bancod = item.BAN_CODIGO;
            banbeni = item.BAN_BANCO;
          }
        }
        if (this.bantrninversion.BANINV_FECHAPRECANCELA.toString().includes('-')) {
          fecha = this.datePipe.transform(this.bantrninversion.BANINV_FECHAPRECANCELA, 'dd/MM/yyyy');
        } else {
          fecha = this.bantrninversion.BANINV_FECHAPRECANCELA;
        }
        valornom = Number(this.bantrninversion.BANINV_VALORNOMINAL)

        strBanTrnNumero = this.bantrninversion.TRNBAN_NUMERO_PRE === null || this.bantrninversion.TRNBAN_NUMERO_PRE === undefined ? '' : this.bantrninversion.TRNBAN_NUMERO_PRE;
        strConcepto = 'Depósito por precancelación ' + strnumcomprobante;
        strAsiento = this.bantrninversion.ASI_PRECANCELA
        concodigo = this.bantrninversion.BANINV_CTAALTBANCO;
      }
      const rs = await this.bantrninversionesService.insertadepre(
        bancod, valor, strBanTrnNumero, fecha, valornom, strConcepto, banbeni, strAsiento, 'BINV', this.bantrninversion.BANINV_CERTIFICADOAPER, concodigo
      );
      if (rs !== null && rs !== undefined && rs.length > 0) {
        for (const data of rs) {
          if(valor === 'RE'){
            this.lblTrnBanNumeroRet=data.TRNBAN_NUMERO
          }
          console.log(data);
        }
      }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al insertar depoditos y retiros'
      });
      return;
    }
  }
  async irnuevo() {
    this.disabledTabInteres = true;
    this.disabledTabImpu = true;
    this.disabledTabDiavenci = true;
    this.disabledTabPrecan = true;
    this.lblporcentajeret = '';
    this.lblNombreret = '';
    this.lblfechainteres = new Date()
    this.lblNomCtaCert = '';
    this.lblNomCtaInteres = '';
    this.lblNomCtaOtrosInteres = '';
    this.lblNomCtaFPAper = '';
    this.lblNomCtaFPCierre = '';
    this.lblCntaIsd = '';
    this.lblNomCtaBanco = '';
    this.lblNombreEntFin = '';
    this.lblNombreIsd = '';
    this.boolprocesa = false;
    this.lblNombreBanco='';
    this.lblTrnBanNumeroRet='';
    this.bantrninversion = {
      BANINV_CERTIFICADO: '', BANINV_CERTIFICADOAPER: '', COM_CODIGO: '', BAN_CODIGO: '', BANINV_FECHAEMISION: new Date(),
      BANINV_FECHAVENCIMIENTO: new Date(), BANINV_VALORNOMINAL: Number('0').toFixed(2), BANINV_DIASPLAZO: '', BANINV_INTERESANUAL: Number('0').toFixed(2),
      BANINV_RENTABILIDADVENC: Number('0').toFixed(2), BANINV_INTERESDIARIO: Number('0').toFixed(2), BANINV_ORIGENFONDOS: '', BANINV_DESTINOFONDOS: '', BANINV_CTACERTIFICADO: '', BANINV_CTAINTERES: '',
      BANINV_CTAOTROSINT: '', BANINV_ESTADO: '', ASI_NRO_APER: '', BANINV_CTAALTBANCO: '', BANINV_FPAPER: '', BANINV_FPCIERRE: '',
      BANINV_ISD: '', BANINV_TIPOFINPLAZO: '', BANINV_FECHAPRECANCELA: '', BANINV_TASACASTIGO: '', BANINV_INTPRECANCELA: '',
      ASI_PRECANCELA: '', ASI_FINPLAZO: '', ASI_CTAISD: '', ENTFIN_CODIGO: '', BANINV_ARCHIVO: '', BANINV_DIFERENCIA: '', TRNBAN_NUMERO: '', TRNBAN_NUMERO_APER: '',
      TRNBAN_NUMERO_PRE: '', TRNBAN_NUMERO_V: ''
    };
    this.cmbasiento = [];
    this.cmbasientoin = [];
    this.bantrninversionesService.dettrnretencion.RETENCION_CODIGO = '';
    this.bantrninversionesService.dettrnretencion.RETENCION_NOMBRE = '';
    this.bantrninversionesService.dettrnretencion.CON_CODIGO
    this.bantrninversionesService.dettrnretencion.TRNRETENCION_FECHAEMISION = new Date()
    this.bantrninversionesService.dettrnretencion.TRNRETENCION_NRO = '';
    this.bantrninversionesService.dettrnretencion.TRNRETENCION_PORCENTAJE = '';
    this.bantrninversionesService.dettrnretencion.TRNRETENCION_BASE = Number('0').toFixed(2);
    this.bantrninversionesService.dettrnretencion.TRNRETENCION_TOTALRETENIDO = Number('0').toFixed(2);
  }

  cambiarFoco(evento) {
    if (evento.target.id === 'ENTFIN_CODIGO') {

      this.buscarBanco(evento.target.value);
    }
    if (evento.target.id === 'BANINV_CERTIFICADO') {

      document.getElementById('BANINV_CERTIFICADOAPER').focus();
      this.bantrninversion.BANINV_CERTIFICADOAPER = this.bantrninversion.BANINV_CERTIFICADO;
    }
    if (evento.target.id === 'BANINV_CERTIFICADOAPER') {

      document.getElementById('BANINV_FECHAEMISION').focus();
    }
    if (evento.target.id === 'BANINV_FECHAEMISION') {

      document.getElementById('BANINV_FPAPER').focus();
    }
    if (evento.target.id === 'BANINV_FPAPER') {

      this.buscarConcodigo(evento.target.value, 'CONCODIGO1');
    }
    if (evento.target.id === 'BANINV_DIASPLAZO') {
      document.getElementById('BANINV_FPCIERRE').focus();
      this.calcularfecha();
      this.calculaRentabilidadVencimiento()
    }
    if (evento.target.id === 'BANINV_FPCIERRE') {
      this.buscarConcodigo(evento.target.value, 'CONCODIGO2');
    }
    if (evento.target.id === 'BANINV_FECHAVENCIMIENTO') {

      document.getElementById('BANINV_CTACERTIFICADO').focus();
    }
    if (evento.target.id === 'BANINV_CTACERTIFICADO') {

      this.buscarConcodigo(evento.target.value, 'CONCODIGO3');
    }
    if (evento.target.id === 'BANINV_VALORNOMINAL') {

      document.getElementById('BANINV_CTAINTERES').focus();
      this.calculaRentabilidadVencimiento()
    }
    if (evento.target.id === 'BANINV_CTAINTERES') {
      this.buscarConcodigo(evento.target.value, 'CONCODIGO4');
    }
    if (evento.target.id === 'BANINV_INTERESANUAL') {

      document.getElementById('BANINV_CTAOTROSINT').focus();
      this.calculaRentabilidadVencimiento()
    }
    if (evento.target.id === 'BANINV_CTAOTROSINT') {
      this.buscarConcodigo(evento.target.value, 'CONCODIGO5');
    }
    if (evento.target.id === 'BANINV_RENTABILIDADVENC') {
      document.getElementById('BANINV_CTAALTBANCO').focus();
    }
    if (evento.target.id === 'BANINV_CTAALTBANCO') {
      this.buscarConcodigo(evento.target.value, 'CONCODIGO6');
    }
    if (evento.target.id === 'BANINV_INTERESDIARIO') {

      document.getElementById('BANINV_ORIGENFONDOS').focus();
    }
    if (evento.target.id === 'BANINV_ORIGENFONDOS') {

      document.getElementById('BANINV_DESTINOFONDOS').focus();
    }
    //------****** RETENCIONES
    if (evento.target.id === 'TRNRETENCION_BASE') {

      this.bantrninversionesService.calcularet();
    }
    if (evento.target.id === 'ASI_CTAISD') {

      this.buscarConcodigo(evento.target.value, 'CONCODIGO7');
    }
    if (evento.target.id === 'RETENCION_CODIGO') {
      this.buscarRetencion(evento.target.value)
      document.getElementById('TRNRETENCION_NRO').focus();
    }
    //------ PRECANCELACION
    if (evento.target.id === 'BANINV_TASACASTIGO') {
      this.calculaInteresPrecancelacion()
      document.getElementById('BANINV_INTPRECANCELA').focus();
    }

  }
  calculaInteresPrecancelacion() {
    let dblTasaCastigo = 0;
    let dblInteresPrecancela = 0;
    let dblTotalInteresCalculado = 0;
    dblTotalInteresCalculado = this.bantrninversionesService.calculaTotalInteres();
    dblTasaCastigo = Number(this.bantrninversion.BANINV_TASACASTIGO);
    dblInteresPrecancela = dblTotalInteresCalculado*dblTasaCastigo/100
    console.log(dblInteresPrecancela)
    this.bantrninversion.BANINV_INTPRECANCELA = Number(dblInteresPrecancela).toFixed(2);

  }
  calculaRentabilidadVencimiento() {
    let dblInteresAnual = 0;
    let dblPlazoDias = 0;
    let dblValorNominal = 0;
    let dblRentaVencimiento = 0;
    let dblinteresDiario = 0;

    dblInteresAnual = Number(this.bantrninversion.BANINV_INTERESANUAL)
    dblInteresAnual = dblInteresAnual / 100;

    dblPlazoDias = Number(this.bantrninversion.BANINV_DIASPLAZO);
    dblValorNominal = Number(this.bantrninversion.BANINV_VALORNOMINAL);

    dblRentaVencimiento = dblValorNominal * ((dblInteresAnual / 360) * dblPlazoDias);
    this.bantrninversion.BANINV_RENTABILIDADVENC = Number(dblRentaVencimiento).toFixed(2)
    console.log(dblPlazoDias)
    if (dblPlazoDias !== 0) {
      dblinteresDiario = Number(dblRentaVencimiento / dblPlazoDias)
    } else {
      dblinteresDiario = 0
    }
    this.bantrninversion.BANINV_INTERESDIARIO = Number(dblinteresDiario).toFixed(2)



  }
  calcularfecha() {

    const dateTimeFecIni: Date = this.bantrninversion.BANINV_FECHAEMISION;


    this.bantrninversion.BANINV_FECHAVENCIMIENTO = this.obtenerFechaVence(dateTimeFecIni.getDate(),
      dateTimeFecIni.getMonth(), dateTimeFecIni.getFullYear());
  }
  obtenerFechaVence(dia, mes, anio): Date {
    let fecha1: Date;
    fecha1 = new Date(anio, mes, dia);

    fecha1.setDate(dia + (this.bantrninversion.BANINV_DIASPLAZO === '' ? 0 :
      Number(this.bantrninversion.BANINV_DIASPLAZO)));

    return fecha1;
  }
  async generarasiento() {
    try {
      await this.bantrninversionesService.erGenerarAsiento(this.bantrninversion);
      await this.desplegarAsientos()
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al generar el asiento'
      });
      return;
    }
  }
  async desplegarAsientos() {
    try {
      const data = await this.bantrninversionesService.consultarAsientos(this.bantrninversion.BANINV_CERTIFICADO,
        this.bantrninversion.BANINV_CERTIFICADOAPER);
      this.cmbasiento = [];
      for (const rs of data) {
        if (rs.ASI_NRO_APER !== null) {
          this.cmbasiento.push({ codigo: rs.ASI_NRO_APER === null ? '' : rs.ASI_NRO_APER, name: rs.ASI_NRO_APER === null ? '' : rs.ASI_NRO_APER });
          //this.cmbasiento.push({codigo: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper,name: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper
          // });
        }
      }

      if (this.cmbasiento.length > 0) {
        this.selectasiento = { codigo: this.cmbasiento[0].codigo, name: this.cmbasiento[0].name };
      }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al consultar los asientos'
      });
      return;
    }

  }
  cancelar() {
    console.log(this.cmbasiento)
    if (this.cmbasiento.length === 0) {
      this.irnuevo();
      this.cambiarbotones(false, false, true, false);
      document.getElementById('divPrincipal').style.pointerEvents = 'none';
    } else {
      this.cambiarbotones(false, false, true, false);
      this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO)
    }
  }
  cambiarbotones(val1, val2, val3, val4) {
    this.btnBuscar = val1;
    this.btnNuevo = val2;
    this.btnGuardar = val3;
    this.btnBorrar = val4;
  }
  buscarBanco(parametro) {
    this.opcion = 'BANCOS';
    this.types = [
      { label: 'CÓDIGO', value: 'ENTFIN_CODIGO' },
      { label: 'NOMBRE', value: 'ENCFIN_NOMBRE' }
    ];
    this.busquedacampos = [parametro, '', ''];
    this.tabla = 'BAN_MAEENTFIN';
    this.where = '';
    this.consulta = ''
    this.bantrninversionesService.encontrarbanentifin(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0])
        } else {
          this.busquedaSer.bsuqedaBanmaeentifin().subscribe((res: any[]) => {
            this.arregloBus = res;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.bsuqedaBanmaeentifin().subscribe((res: any[]) => {
          this.arregloBus = res;
          this.displayDialogBusquedaFast = true;
        });
      }
    })

  }

  buscarConcodigo(parametro, numcuentas) {
    this.opcion = numcuentas;
    this.types = [
      { label: 'Codigo', value: 'CON_CODIGO' },
      { label: 'Nombre', value: 'CON_NOMBRE' },
      { label: 'Cnt.Costos', value: 'CON_CENTRO' }
    ];

    this.busquedacampos = ['', '', ''];
    this.tabla = 'CON_MAECON';
    this.bantrninversionesService.encontrarConNombre(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          if (parametro.substr(-1, 1) === '.') {
            this.message.add({
              key: 'inversion',
              severity: 'error',
              summary: 'Cuenta Contable',
              detail: 'No se puede elegir esa cuenta contable'
            });
            switch (this.opcion) {
              case 'CONCODIGO1': {
                this.bantrninversion.BANINV_FPAPER = '';
                this.lblNomCtaFPAper = '';
                break;
              }
              case 'CONCODIGO2': {
                this.bantrninversion.BANINV_FPCIERRE = '';
                this.lblNomCtaFPCierre = '';
                break;
              }
              case 'CONCODIGO3': {
                this.bantrninversion.BANINV_CTACERTIFICADO = '';
                this.lblNomCtaCert = '';
                break;
              }
              case 'CONCODIGO4': {
                this.bantrninversion.BANINV_CTAINTERES = '';
                this.lblNomCtaInteres = '';
                break;
              }
              case 'CONCODIGO5': {
                this.bantrninversion.BANINV_CTAOTROSINT = '';
                this.lblNomCtaOtrosInteres = '';
                break;
              }
              case 'CONCODIGO6': {
                this.bantrninversion.BANINV_CTAALTBANCO = '';
                this.lblNomCtaBanco = '';
                break;
              }
              case 'CONCODIGO7': {
                this.bantrninversion.ASI_CTAISD = '';
                this.lblNombreIsd = '';
                break;
              }

              default: {
                break;
              }
            }
            return
          }
          switch (this.opcion) {
            case 'CONCODIGO1': {
              this.bantrninversion.BANINV_FPAPER = parametro;
              this.lblNomCtaFPAper = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO2': {
              this.bantrninversion.BANINV_FPCIERRE = parametro;
              this.lblNomCtaFPCierre = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO3': {
              this.bantrninversion.BANINV_CTACERTIFICADO = parametro;
              this.lblNomCtaCert = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO4': {
              this.bantrninversion.BANINV_CTAINTERES = parametro;
              this.lblNomCtaInteres = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO5': {
              this.bantrninversion.BANINV_CTAOTROSINT = parametro;
              this.lblNomCtaOtrosInteres = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO6': {
              this.bantrninversion.BANINV_CTAALTBANCO = parametro;
              this.lblNomCtaBanco = eR[0].CON_NOMBRE;
              break;
            }
            case 'CONCODIGO7': {
              this.bantrninversion.ASI_CTAISD = parametro;
              this.lblNombreIsd = eR[0].CON_NOMBRE;
              break;
            }

            default: {
              break;
            }
          }
          this.cambiarbotones(true, true, false, true);
        } else {
          this.busquedaSer.busquedaConMaeCon().subscribe((datos: any[]) => {
            this.arregloBus = datos;
            // this.displayDialogBusqueda = true;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busquedaSer.busquedaConMaeCon().subscribe((datos: any[]) => {
          this.arregloBus = datos;
          // this.displayDialogBusqueda = true;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  onTab1Change(event) {
    this.activateTab(event.index);
  }

  activateTab(tabNumber) {
    this.selectTab = tabNumber;

  }
  async manejarSeleccion(opcion) {
    this.botonesmodificar()
    switch (this.opcion) {
      case 'CONCODIGO1': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_FPAPER = '';
          this.lblNomCtaFPAper = '';
          return;
        }
        this.bantrninversion.BANINV_FPAPER = opcion.CON_CODIGO;
        this.lblNomCtaFPAper = opcion.CON_NOMBRE;
        document.getElementById('BANINV_DIASPLAZO').focus();
        break;
      }
      case 'CONCODIGO2': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_FPCIERRE = '';
          this.lblNomCtaFPCierre = '';
          return;
        }
        this.bantrninversion.BANINV_FPCIERRE = opcion.CON_CODIGO;
        this.lblNomCtaFPCierre = opcion.CON_NOMBRE;
        document.getElementById('BANINV_FECHAVENCIMIENTO').focus();
        break;
      }
      case 'CONCODIGO3': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTACERTIFICADO = '';
          this.lblNomCtaCert = '';
          return;
        }
        this.bantrninversion.BANINV_CTACERTIFICADO = opcion.CON_CODIGO;
        this.lblNomCtaCert = opcion.CON_NOMBRE;
        document.getElementById('BANINV_VALORNOMINAL').focus();
        break;
      }
      case 'CONCODIGO4': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAINTERES = '';
          this.lblNomCtaInteres = '';
          return;
        }
        this.bantrninversion.BANINV_CTAINTERES = opcion.CON_CODIGO;
        this.lblNomCtaInteres = opcion.CON_NOMBRE;
        document.getElementById('BANINV_INTERESANUAL').focus();
        break;
      }
      case 'CONCODIGO5': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAOTROSINT = '';
          this.lblNomCtaOtrosInteres = '';
          return;
        }
        this.bantrninversion.BANINV_CTAOTROSINT = opcion.CON_CODIGO;
        this.lblNomCtaOtrosInteres = opcion.CON_NOMBRE;
        document.getElementById('BANINV_RENTABILIDADVENC').focus();
        break;
      }
      case 'CONCODIGO6': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.BANINV_CTAALTBANCO = '';
          this.lblNomCtaBanco = '';
          return;
        }
        this.bantrninversion.BANINV_CTAALTBANCO = opcion.CON_CODIGO;
        this.lblNomCtaBanco = opcion.CON_NOMBRE;
        document.getElementById('BANINV_INTERESDIARIO').focus();
        break;
      }
      case 'CONCODIGO7': {
        if (opcion.CON_CODIGO.substr(-1, 1) === '.') {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Cuenta Contable',
            detail: 'No se puede elegir esa cuenta contable'
          });
          this.bantrninversion.ASI_CTAISD = '';
          this.lblNombreIsd = '';
          return;
        }
        this.bantrninversion.ASI_CTAISD = opcion.CON_CODIGO;
        this.lblNombreIsd = opcion.CON_NOMBRE;
        document.getElementById('BANINV_INTERESDIARIO').focus();
        break;
      }
      case 'BANCOS': {
        this.bantrninversion.ENTFIN_CODIGO = opcion.ENTFIN_CODIGO;
        this.lblNombreBanco = opcion.ENCFIN_NOMBRE;
        document.getElementById('BANINV_CERTIFICADO').focus();
        break;
      }
      case 'RETENCION': {
        this.bantrninversionesService.dettrnretencion.RETENCION_CODIGO = opcion.RETENCION_CODIGO;
        this.bantrninversionesService.dettrnretencion.TRNRETENCION_PORCENTAJE = opcion.RETENCION_PORCENTAJE
        this.bantrninversionesService.dettrnretencion.RETENCION_NOMBRE = opcion.RETENCION_NOMBRE
        this.bantrninversionesService.dettrnretencion.CON_CODIGO = opcion.CON_CODIGO
        this.lblNombreret = opcion.RETENCION_NOMBRE;
        this.lblporcentajeret = opcion.RETENCION_PORCENTAJE;
        this.bantrninversionesService.calcularet()
        document.getElementById('TRNRETENCION_NRO').focus();
        break;
      }
      case 'BUSCAR': {
        await this.encontrarregistro(opcion.BANINV_CERTIFICADO)
        document.getElementById('divPrincipal').style.pointerEvents = 'all';
        break;
      }

      default: {
        break;
      }
    }
    this.displayDialogBusquedaFast = false;
  }
  solonumero(ref) {
    const input = ref.target;
    let valor = input.value;
    const regex = /^-?\d*(\.\d*)?$/
    if (regex.test(valor)) {
      input.value = valor;
    } else {
      input.value = valor.replace(/[^-?\d.]/g, '');
      console.log(input.value.indexOf('-'))

      if (input.value.indexOf('-') > 0) {
        input.value = input.value.replace('-', '')
      } else {
        input.value = input.value.replace(/(?!^)-/g, '')
      }

      const parts = input.value.split('.')
      if (parts.length > 2) {
        input.value = parts[0] + '.' + parts.slice(1).join('')
      }

    }

    console.log(input.value)
  }
  async generarinteres() {
    this.confirmationService.confirm({
      message: 'Está seguro de generar los intereses?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'inver',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        try {
          if (this.lblfechainteres.toString().includes('-')) {
            this.lblfechainteres = this.datePipe.transform(this.lblfechainteres, 'dd/MM/yyyy')
          } else {
            this.lblfechainteres = this.lblfechainteres
          }
          await this.bantrninversionesService.generaineteres(this.bantrninversion, this.lblfechainteres)
          await this.generarasientoInteres();
          await this.mostrarInteres();
        } catch (error) {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al generar el interés'
          });
          return;
        }
      },
      reject: () => {
        return;
      }
    });
  }
  async generarasientoInteres() {
    try {
      await this.bantrninversionesService.generarasientointeres(this.bantrninversion.BANINV_CERTIFICADO);
      await this.desplegarAsientosInteres();

    } catch (error) {

    }
  }
  async desplegarAsientosInteres() {
    try {
      const data = await this.bantrninversionesService.consultarAsientosInteres(this.bantrninversion.BANINV_CERTIFICADO);
      this.cmbasientoin = [];
      for (const rs of data) {
        console.log(rs.ASI_NRO)
        if (rs.ASI_NRO !== null) {
          this.cmbasientoin.push({ codigo: rs.ASI_NRO === null ? '' : rs.ASI_NRO, name: rs.ASI_NRO === null ? '' : rs.ASI_NRO });
          //this.cmbasiento.push({codigo: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper,name: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper
          // });
        }
      }

      if (this.cmbasientoin.length > 0) {
        this.selectasientoin = { codigo: this.cmbasientoin[0].codigo, name: this.cmbasientoin[0].name };
      }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al consultar los asientos'
      });
      return;
    }
  }
  async mostrarInteres() {
    try {
      this.sumaInteres = 0
      const data = await this.bantrninversionesService.mostrarinteres(this.bantrninversion.BANINV_CERTIFICADO);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const iterator of data) {
          iterator.TRNINTINV_INTERES = Number(iterator.TRNINTINV_INTERES).toFixed(2)
          iterator.TRNINTINV_FECHA = this.datePipe.transform(iterator.TRNINTINV_FECHA, 'dd/MM/yyyy')
          this.sumaInteres = Number(this.sumaInteres) + Number(iterator.TRNINTINV_INTERES)
        }
        this.bantrninversionesService.detbantrninversion[this.indicador]  = data
        this.btnGridBorrar = false
      } else {
        this.btnGridBorrar = true;
      }
      this.bantrninversionesService.dettrnretencion.TRNRETENCION_BASE = Number(this.sumaInteres).toFixed(2)
      this.aggrid.refreshaggrid(this.bantrninversionesService.detbantrninversion[this.indicador] , this.defaultbantrninv.objeto)
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al consultar los los intereses'
      });
      return;
    }
  }
  selecciontrninver(params) {
    if (params === 'eliminar') {
      //if (this.detselectbantrninversion === undefined || this.detselectbantrninversion === null) {
      // this.message.add({
      //   key: 'inversion',
      //   severity: 'error',
      //   summary: 'Información',
      //   detail: 'Seleccione un registro'
      // });
      // return;
      //}
      this.eliminardet()
      return;
    }
    if (params.object !== undefined) {
      this.detselectbantrninversion = params.object;

    }



  }
  async eliminardet() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Eliminar Interés',
      icon: 'pi pi-exclamation-triangle',
      key: 'inver',
      accept: async () => {
        try {
          await this.bantrninversionesService.eliminardetinv(this.detselectbantrninversion);
          await this.mostrarInteres()
          this.message.add({
            key: 'encreemb',
            severity: 'success',
            summary: 'Información',
            detail: 'Éxito al eliminar el detalle'
          });
        } catch (error) {
          this.message.add({
            key: 'encreemb',
            severity: 'error',
            summary: 'Información',
            detail: 'Ocurrio un error al eliminar el detalle'
          });
          return;
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  cambiofinplazo() { }
  async procesar() {
    try {
      const op = this.finplazoSeleccionado === 'fnpRenova' ? 'R' : this.finplazoSeleccionado === 'fnpCanL' ? 'CL' : this.finplazoSeleccionado === 'fnpCanE' ? 'CE' : '';
      await this.bantrninversionesService.procesar(this.bantrninversion, op)
      await this.desplegarAsientoCierre()
      await this.insertaDepositosYRetiros_n('DEP', 'V')
      await this.actualizarTipoFinPlazo(op)
      await this.actualizarEstado('C');
      await this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO);
      this.boolprocesa = true;
    } catch (error) {
      this.message.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al procesar'
      });
      return;
    }
  }
  async desplegarAsientoCierre() {
    try {
      const data = await this.bantrninversionesService.consultarAsientosCierre(this.bantrninversion.BANINV_CERTIFICADO
      );

      for (const rs of data) {
        if (rs.ASI_FINPLAZO !== null) {
          this.bantrninversion.ASI_FINPLAZO = rs.ASI_FINPLAZO === null || rs.ASI_FINPLAZO === undefined ? '' : rs.ASI_FINPLAZO
          this.bantrninversion.ASI_PRECANCELA = rs.ASI_PRECANCELA === null || rs.ASI_PRECANCELA === undefined ? '' : rs.ASI_PRECANCELA
          //this.cmbasiento.push({ codigo: rs.ASI_FINPLAZO === null ? '' : rs.ASI_FINPLAZO, name: rs.ASI_FINPLAZO === null ? '' : rs.ASI_FINPLAZO });
          //this.cmbasiento.push({codigo: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper,name: rs.asi_nro_aper === null ? '' : rs.asi_nro_aper
          // });
        }
        if (rs.ASI_PRECANCELA !== null) {
          this.bantrninversion.ASI_PRECANCELA = rs.ASI_PRECANCELA === null || rs.ASI_PRECANCELA === undefined ? '' : rs.ASI_PRECANCELA
        }
      }

      // if (this.cmbasiento.length > 0) {
      //   this.selectasiento = { codigo: this.cmbasiento[0].codigo, name: this.cmbasiento[0].name };
      // }
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al consultar los asientos'
      });
      return;
    }
  }
  async actualizarTipoFinPlazo(tipo) {
    try {
      this.bantrninversionesService.actualizatipofinplazo(tipo)
    } catch (error) {
      this.message.add({
        key: 'inversion',
        severity: 'error',
        summary: 'Error',
        detail: 'Error al actualizar el tipo'
      });
      return;
    }
  }
  async actualizarEstado(estado) {
    try {
      await this.bantrninversionesService.actualizarestado(estado, this.bantrninversion.BANINV_CERTIFICADO);
      this.cambiarbotones(false, false, true, false);
    } catch (error) {

    }
  }
  async insertaDepositosYRetiros_n(tipo, tipoasi) {
    try {
      let fecha;
      let valornom = 0;
      let bancod = '';
      let banbeni = '';
      let strnumcomprobante = '';
      let strBanTrnNumero = '';
      let strConcepto = '';
      let strAsiento = '';
      let concodigo = '';
      let tipodoc = '';
      if (this.bantrninversion.BANINV_CERTIFICADO === this.bantrninversion.BANINV_CERTIFICADOAPER) {
        strnumcomprobante = this.bantrninversion.BANINV_CERTIFICADOAPER
      } else {
        strnumcomprobante = this.bantrninversion.BANINV_CERTIFICADOAPER + '/' + this.bantrninversion.BANINV_CERTIFICADO
      }
      if (tipo === 'RE') {
        /// trae datos de de los bancos
        const data = await this.bantrninversionesService.consulbancodigoRE(this.bantrninversion.BANINV_FPAPER)
        if (data !== null && data !== null && data.length > 0) {
          for (const item of data) {
            bancod = item.BAN_CODIGO;
            banbeni = this.lblNombreBanco;
          }
        }


        if (this.bantrninversion.BANINV_FECHAEMISION.toString().includes('-')) {
          fecha = this.datePipe.transform(this.bantrninversion.BANINV_FECHAEMISION, 'dd/MM/yyyy');
        } else {
          fecha = this.bantrninversion.BANINV_FECHAEMISION;
        }
        valornom = Number(Number(this.bantrninversion.BANINV_VALORNOMINAL) * -1)
        strBanTrnNumero = this.lblTrnBanNumeroRet;
        strConcepto = 'Retiro por apertura de inversión ' + strnumcomprobante;
        strAsiento = this.selectasiento.name
        concodigo = this.bantrninversion.BANINV_FPAPER;
        tipodoc = 'RE'
      }
      if (tipo === 'DEP') {
        /// trae datos de de los bancos
        const data = await this.bantrninversionesService.consulbancodigoDP(this.bantrninversion.BANINV_CTAALTBANCO)
        if (data !== null && data !== null && data.length > 0) {
          for (const item of data) {
            bancod = item.BAN_CODIGO;
            banbeni = item.BAN_BANCO;
          }
        }
        if (this.bantrninversion.BANINV_FECHAVENCIMIENTO.toString().includes('-')) {
          fecha = this.datePipe.transform(this.bantrninversion.BANINV_FECHAVENCIMIENTO, 'dd/MM/yyyy');
        } else {
          fecha = this.bantrninversion.BANINV_FECHAVENCIMIENTO;
        }
        valornom = Number(this.bantrninversion.BANINV_VALORNOMINAL)

        strBanTrnNumero = this.bantrninversion.TRNBAN_NUMERO_V == null || this.bantrninversion.TRNBAN_NUMERO_V == undefined ? '' : this.bantrninversion.TRNBAN_NUMERO_V;
        strConcepto = 'Depósito por vencimiento de la inversión ' + strnumcomprobante;
        strAsiento = this.bantrninversion.ASI_FINPLAZO
        concodigo = this.bantrninversion.BANINV_CTAALTBANCO;
        tipodoc = 'DP'
      }
      const rs = await this.bantrninversionesService.insertadepre(
        bancod, tipodoc, strBanTrnNumero, fecha, valornom, strConcepto, banbeni, strAsiento, 'BINV', this.bantrninversion.BANINV_CERTIFICADOAPER, concodigo
      );
      if (rs !== null && rs !== undefined && rs.length > 0) {
        for (const data of rs) {
          this.trbanvenci = data.TRNBAN_NUMERO
          this.bantrninversion.TRNBAN_NUMERO_V = data.TRNBAN_NUMERO
        }
      }

    } catch (error) {

    }
  }
  async reversar() {
    this.confirmationService.confirm({
      message: 'Está seguro de reversar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'inver',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: async () => {
        try {
          await this.bantrninversionesService.reversar(this.bantrninversion.BANINV_CERTIFICADO)
          this.message.add({
            key: 'inversion',
            severity: 'success',
            summary: 'Informacion',
            detail: 'Éxito al reversar'
          });
          this.boolprocesa = false;
          this.bantrninversion.ASI_FINPLAZO = '';
          this.bantrninversion.TRNBAN_NUMERO_V = ''
        } catch (error) {
          this.message.add({
            key: 'inversion',
            severity: 'error',
            summary: 'Error',
            detail: 'Error al reversar'
          });
          return;
        }
      },
      reject: () => {
        return;
      }
    });
  }
  async reversarprecancela() {
    await this.bantrninversionesService.reversarprecan(this.bantrninversion.BANINV_CERTIFICADO)
    this.message.add({
      key: 'inversion',
      severity: 'success',
      summary: 'Informacion',
      detail: 'Éxito al reversar'
    });
    this.boolprocesa = false;
    this.bantrninversion.BANINV_TASACASTIGO = Number('0').toFixed(2)
    this.bantrninversion.BANINV_INTPRECANCELA = Number('0').toFixed(2)
    this.bantrninversion.TRNBAN_NUMERO_PRE = ''
    this.bantrninversion.ASI_PRECANCELA = '';
  }
  async procesarprecancela() {
    try {
      if (this.bantrninversion.BANINV_FECHAPRECANCELA === null || this.bantrninversion.BANINV_FECHAPRECANCELA === undefined || this.bantrninversion.BANINV_FECHAPRECANCELA === '') {
        this.message.add({
          key: 'inversion',
          severity: 'error',
          summary: 'Error',
          detail: 'El campo fecha no puede estar vacío'
        });
        return;
      }
      if (this.btnGuardar === false) {
        this.message.add({
          key: 'inversion',
          severity: 'error',
          summary: 'Error',
          detail: 'Guarde primero el registro'
        });
        return;
      }
      await this.procesanprecanfin()
    } catch (error) {
      this.message.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al procesar'
      });
      return;
    }

  }
  async procesanprecanfin() {
    try {
      await this.bantrninversionesService.procesarprecan(this.bantrninversion)
      await this.desplegarAsientoCierre()
      await this.insertaDepositosYRetiros('DP')
      await this.actualizarTipoFinPlazo('PR')
      await this.actualizarEstado('C');
      await this.encontrarregistro(this.bantrninversion.BANINV_CERTIFICADO);
      this.boolprocesa = true;
    } catch (error) {

    }
  }
  buscarRetencion(parametro) {
    this.opcion = 'RETENCION';
    this.types = [
      { label: 'Código', value: 'RETENCION_CODIGO' },
      { label: 'Nombre', value: 'RETENCION_NOMBRE' },
      { label: 'Porcentaje', value: 'RETENCION_PORCENTAJE' },
      { label: 'CTACOMPRAS', value: 'CON_CODIGO' },
      { label: 'CTAVTAS', value: 'CON_CODIGO_VTA' },
      { label: 'Tipo retención', value: 'RETENCION_TIPRET' },

    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '*';
    this.tabla = 'saci_maeretencion';
    this.where = '';
    if (parametro === null || parametro === undefined) {
      parametro = ''
    }
    this.busquedaSer.encontrarRegistro2(parametro).subscribe(eR => {
      if (eR !== null) {
        this.manejarSeleccion(eR[0]);
      } else {
        this.busquedaSer.busquedaTabla('*', 'SACI_MAERETENCION', this.types[0].value + ' LIKE \'%' + parametro + '%\'').subscribe((datos: any[]) => {
          this.arregloBus = datos;
          this.displayDialogBusquedaFast = true;
        })
      }
    });
  }
  async actualizarTipoFinPlazopre() {
  }
  async almacenardoc() {
    console.log(this.cmbasiento.length)
    if (this.cmbasiento.length !== 0) {
      const usu = await this.confIniciales.obtenerPermisoUsuario(this.bantrninversionesService.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentos = true;
      } else {
        this.message.add({
          key: 'inversion',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }
    }
  }
  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
  botonesmodificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
  }
}
