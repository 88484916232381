<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="encreemb"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="encreembconf" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>
<div class="p-col-12">
    <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar"
        [botonBorrar]="btnBorrar" [botonRegresar]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [barraBotonesAsiCon]="true" [barraBotones5]="true" [botonBuscarPermanente]="true" [botonEstadoPermanente]="true"
        
        ></app-invmaebarra>
</div>

<div class="contorno" id="contorno">
    <div class="divgrups">
        <div class="p-grid">
            <!-- linea 1 -->
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Lote:</span>
                            </div>
                            <div class="p-col-6">
                                <input class="frm" id="ENCREE_LOTE" type="text" pInputText
                                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                                    [disabled]="true"
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].ENCREE_LOTE"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-4">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Caja:</span>
                            </div>
                            <div class="p-col-4">
                                <label for="ENCREE_CAJA"></label>
                                <input class="frm" id="ENCREE_CAJA" type="text" pInputText
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].ENCREE_CAJA"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                    autocomplete="off">
                            </div>
                            <div class="p-col-1">
                                <button type="button"
                                    style="background-image: url(../../../assets/images/iconos/buscar.png);"
                                    (click)="busquedacaja(comencreembolsoService.encabezadoreembolso[indicador].ENCREE_CAJA)"></button>
                            </div>
                            <div class="p-col-5">
                                <label for="CAJ_NOMBRE"></label>
                                <input class="frm" id="CAJ_NOMBRE" type="text" pInputText disabled
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].CAJ_NOMBRE"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Usuario:</span>
                            </div>
                            <div class="p-col-6">
                                <input class="frm" id="USU_IDENTIFICACION" type="text" pInputText
                                    style="background-color: #EFFFF7; text-align: center; color: rgb(0,0,255); font-weight: bold"
                                    [disabled]="true"
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].USU_IDENTIFICACION"
                                    autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Estado:</span>
                            </div>
                            <div class="p-col-6">
                                <p-dropdown [options]="cmbencestado" [style]="{'minWidth': '100%', 'width':'100%'}"
                                    optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipoencest">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- linea 2 -->
            <div class="p-col-12" style="margin-top: -25px">
                <div class="p-grid">
                    <div class="p-col-2">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Fecha:</span>
                            </div>
                            <div class="p-col-6">
                                <p-calendar id="ENCREE_FECHAEMISION" [inputStyle]="{width: '100%', height: '20px'}"
                                    [readonlyInput]="false" dateFormat="dd/mm/yy"
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].ENCREE_FECHAEMISION"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
                                </p-calendar>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-4">
                        <div class="p-grid">
                            <div class="p-col-2">
                                <span>Caja Inicial:</span>
                            </div>
                            <div class="p-col-4">
                                <label for="ENCREE_SALDO_CAJA"></label>
                                <input class="frm" id="ENCREE_SALDO_CAJA" type="text" pInputText style="width: 100%; text-align: right"
                                    [(ngModel)]="comencreembolsoService.encabezadoreembolso[indicador].ENCREE_SALDO_CAJA"
                                    (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                    autocomplete="off">
                            </div>
                            <div class="p-col-2">
                                <span>Cargar archivos:</span>
                            </div>
                            <div class="p-col-4">
                                <button type="button"
                                style="background-image: url(../../../assets/images/iconos/folder3.png);"
                                (click)="almacenardoc()"></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="divg" style="height: 650px">
    <p-tabView>
        <p-tabPanel header="Documentos">
            <div class="divgrups" id="contorno2">
                <div class="p-grid">
                    <div class="p-col-3">
                        <span>Estado:</span>
                    </div>
                    <div class="p-col-3">
                        <p-dropdown [options]="cmbestado" [style]="{'minWidth': '100%', 'width':'100%'}"
                            optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipocest">
                        </p-dropdown>
                    </div>
                    <!-- linea 2 -->
                    <div class="p-col-12" style="margin-top: -2px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Tipo de Identificación del Proveedor:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_TIPOIDEPRO"></label>
                                        <input class="frm" id="DETREE_TIPOIDEPRO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_TIPOIDEPRO"
                                            [maxLength]="10"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedatipoId(detallereembolso.DETREE_TIPOIDEPRO)"></button>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="lblTipoIDEPRO"></label>
                                        <input class="frm" id="lblTipoIDEPRO" type="text" pInputText disabled
                                            [(ngModel)]="lblTipoIDEPRO" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> No. de Identificación del Proveedor:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_IDEPRO"></label>
                                        <input class="frm" id="DETREE_IDEPRO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_IDEPRO"
                                            [maxLength]="20"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <!--<span *ngIf="valida">Mal</span>
                                        <span *ngIf="!valida">Bien</span>-->
                                        <span>{{strbienmal}}</span>
                                    </div>
                                    <div class="p-col-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 3 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Tipo de Proveedor:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_PROTIPO"></label>
                                        <input class="frm" id="DETREE_PROTIPO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_PROTIPO"
                                            [maxLength]="10"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedatipoprov(detallereembolso.DETREE_PROTIPO)"></button>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="lblTipoPRO"></label>
                                        <input class="frm" id="lblTipoPRO" type="text" pInputText disabled
                                            [(ngModel)]="lblTipoPRO" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> Codigo País Pago:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_PAISPAGO"></label>
                                        <input class="frm" id="DETREE_PAISPAGO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_PAISPAGO"
                                            [maxLength]="10"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedatipaispago(detallereembolso.DETREE_PAISPAGO)"></button>
                                    </div>
                                    <div class="p-col-5">

                                        <label for="lblPaispago"></label>
                                        <input class="frm" id="lblPaispago" type="text" pInputText disabled
                                            [(ngModel)]="lblPaispago" autocomplete="off">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 4 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Id.Credito Tributario:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_IDCRETRI"></label>
                                        <input class="frm" id="DETREE_IDCRETRI" type="text" pInputText [disabled]="boolestespecial"
                                            [(ngModel)]="detallereembolso.DETREE_IDCRETRI"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolestespecial"
                                            (click)="busquedaIdCreditrib(detallereembolso.DETREE_IDCRETRI)"></button>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="lblTipoIdCre"></label>
                                        <input class="frm" id="lblTipoIdCre" type="text" pInputText disabled
                                            [(ngModel)]="lblTipoIdCre" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> Código tipo de comprobante:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_TIPCOMP"></label>
                                        <input class="frm" id="DETREE_TIPCOMP" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_TIPCOMP"
                                            [maxLength]="10"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedaticomprobante(detallereembolso.DETREE_TIPCOMP)"></button>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="lblTipocom"></label>
                                        <input class="frm" id="lblTipocom" type="text" pInputText disabled
                                            [(ngModel)]="lblTipocom" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 5 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> No. comprobante de venta establecimiento:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_SERIEEST"></label>
                                        <input class="frm" id="DETREE_SERIEEST" type="text" pInputText [disabled]="boolestado99"
                                            [(ngModel)]="detallereembolso.DETREE_SERIEEST"
                                            [maxLength]="3"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> No. comprobante de venta pto.emisión:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_SERIEPTOEMI"></label>
                                        <input class="frm" id="DETREE_SERIEPTOEMI" type="text" pInputText [disabled]="boolestado99"
                                            [(ngModel)]="detallereembolso.DETREE_SERIEPTOEMI"
                                            oninput="this.value= this.value.replace(/[^0-9]/g, '');"
                                            [maxLength]="3"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 6 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> No. secuencial del comprobante de venta:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_SECCOMPVTA"></label>
                                        <input class="frm" id="DETREE_SECCOMPVTA" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_SECCOMPVTA"
                                            [maxLength]="9"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> F. emisión del comprobante de venta:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <p-calendar id="DETREE_FECHAEMISION"
                                            [inputStyle]="{width: '100%', height: '20px'}" [readonlyInput]="false" [disabled]="boolest"
                                            dateFormat="dd/mm/yy" [(ngModel)]="detallereembolso.DETREE_FECHAEMISION"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()">
                                        </p-calendar>
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 7 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span>No. autorización del comprobante de venta:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-5">
                                        <label for="DETREE_AUTORIZACION"></label>
                                        <input class="frm" id="DETREE_AUTORIZACION" type="text" pInputText [disabled]="boolestado99"
                                            [(ngModel)]="detallereembolso.DETREE_AUTORIZACION"
                                            oninput="this.value= this.value.replace(/[^0-9]/g, '');"
                                            [maxLength]="49"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> </span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">

                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 8 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Base Imponible tarifa 0% IVA:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_BASECERO"></label>
                                        <input class="frm" id="DETREE_BASECERO" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_BASECERO"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> Base Imp. ExeReemb:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4" >
                                        <label for="DETREE_BASEIMPEXEREEMB"></label>
                                        <input class="frm" id="DETREE_BASEIMPEXEREEMB" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_BASEIMPEXEREEMB"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 9 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span>Base Imponible tarifa IVA diferente de 0%:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_BASEIVA"></label>
                                        <input class="frm" id="DETREE_BASEIVA" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_BASEIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3" style="text-align: left">
                                <span> Base Imponible no objeto de IVA:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_BASENOIVA"></label>
                                        <input class="frm" id="DETREE_BASENOIVA" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_BASENOIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 10 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span>Porcentaje de IVA:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4" >
                                        <label for="DETREE_PORCENTAJEIVA"></label>
                                        <input class="frm" id="DETREE_PORCENTAJEIVA" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_PORCENTAJEIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span></span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 11 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span>Monto ICE:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_MONTOICE"></label>
                                        <input class="frm" id="DETREE_MONTOICE" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_MONTOICE"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span> Monto IVA:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4" >
                                        <label for="DETREE_MONTOIVA"></label>
                                        <input class="frm" id="DETREE_MONTOIVA" type="text" pInputText style="width: 100%; text-align: right" [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_MONTOIVA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                        <div class="p-col-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 12 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Empleado:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_EMPLEADO"></label>
                                        <input class="frm" id="DETREE_EMPLEADO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_EMPLEADO"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedaEmpleado(detallereembolso.DETREE_EMPLEADO)"></button>
                                    </div>
                                    <div class="p-col-5">
                                        <label for="lblEmpleado"></label>
                                        <input class="frm" id="lblEmpleado" type="text" pInputText disabled
                                            [(ngModel)]="lblEmpleado" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-3">
                                <span></span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                    </div>
                                    <div class="p-col-1">
                                    </div>
                                    <div class="p-col-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 13 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Tipo de Gasto:</span>
                            </div>
                            <div class="p-col-3">
                                <p-dropdown [options]="cmbtipogasto" [style]="{'minWidth': '100%', 'width':'100%'}" [disabled]="boolest"
                                    optionLabel="name" [panelStyle]="{'width':'110%'}" [(ngModel)]="selecttipogasto">
                                </p-dropdown>
                            </div>
                            <div class="p-col-3">
                                <span> Servicio:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="SERCOM_CODIGO"></label>
                                        <input class="frm" id="SERCOM_CODIGO" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.SERCOM_CODIGO"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedasercomcodigo(detallereembolso.SERCOM_CODIGO)"></button>
                                    </div>
                                    <div class="p-col-5">

                                        <label for="lblsercomnom"></label>
                                        <input class="frm" id="lblsercomnom" type="text" pInputText disabled
                                            [(ngModel)]="lblsercomnom" autocomplete="off">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 14 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span> Descripción:</span>
                            </div>
                            <div class="p-col-3">

                                <label for="DETREE_DESCRIPCION"></label>
                                <input class="frm" id="DETREE_DESCRIPCION" type="text" pInputText [disabled]="boolest"
                                    [(ngModel)]="detallereembolso.DETREE_DESCRIPCION" (keydown.enter)="setFocus($event)"
                                    (keydown)="botonesmodificar()" autocomplete="off">
                            </div>

                            <div class="p-col-3">
                                <span>Area/Departamento:</span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">
                                        <label for="DETREE_AREA"></label>
                                        <input class="frm" id="DETREE_AREA" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_AREA"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                                    </div>
                                    <div class="p-col-1">
                                        <button type="button"
                                            style="background-image: url(../../../assets/images/iconos/buscar.png);" [disabled]="boolest"
                                            (click)="busquedaareadep(detallereembolso.DETREE_AREA)"></button>
                                    </div>
                                    <div class="p-col-5">

                                        <label for="lblareadep"></label>
                                        <input class="frm" id="lblareadep" type="text" pInputText disabled
                                            [(ngModel)]="lblareadep" autocomplete="off">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- linea 15 -->
                    <div class="p-col-12" style="margin-top: -10px">
                        <div class="p-grid">
                            <div class="p-col-3">
                                <span>Descripción centro de costos:</span>
                            </div>
                            <div class="p-col-3">
                               
                                        <label for="DETREE_CENTROC"></label>
                                        <input class="frm" id="DETREE_CENTROC" type="text" pInputText [disabled]="boolest"
                                            [(ngModel)]="detallereembolso.DETREE_CENTROC"
                                            (keydown.enter)="setFocus($event)" (keydown)="botonesmodificar()"
                                            autocomplete="off">
                               
                            </div>
                            <div class="p-col-3">
                                <span></span>
                            </div>
                            <div class="p-col-3">
                                <div class="p-grid">
                                    <div class="p-col-4">

                                    </div>
                                    <div class="p-col-1">

                                    </div>
                                    <div class="p-col-5">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-1">
                        <span>Seleccionar Todos:</span>
                    </div>
                    <div class="p-col-1">
                        <label for="lblsaldo"></label>
                        <p-checkbox value="valSelect" (onChange)="seleccionarall($event)"></p-checkbox>
                    </div>
                </div>
            </div>


            <app-aggridsaci [width]="" [height]="'520'" [enableSorting]="false" [enableFilter]="true"
                [rowData]="detreembolso" [frameworkComponents]="frameworkComponents" [animateRows]="true"
                [rowSelection]="rowSelection" [columnDefs]="columnDefsdetreemb" [defaultColDef]="defaultColDefreemb"
                [rowStyle]="rowStyle" [singleClickEdit]="false" (selected)="selectedPresuCon($event)" [botones]="true"
                [botonNuevo]="btngridnuevo" [botonBorrar]="btngridborrar" [botonRegresar]="btngridregresar" [mostrarGuardar]="false">
            </app-aggridsaci>
            <div class="p-col-12">
                <div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
                    <div class="p-col-2" style="text-align: left">
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>Base I.V.A.</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>Base Cero</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>Base No Grab.</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>Valor I.V.A.</span>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-grid" style="margin-top: -7px; margin-bottom: -7px">
                    <div class="p-col-2" style="text-align: left">
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>{{TotalBaseIva}}</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>{{TotalBaseCero}}</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>{{TotalBaseNoGrab}}</span>
                    </div>
                    <div class="p-col-2" style="text-align: left">
                        <span>{{TotalValorIva}}</span>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
<p-dialog header="Busqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
    appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
    <app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
        [consulta]="consulta" [busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)">
    </app-busquedadlg>
</p-dialog>
<p-dialog header="Documentos" [(visible)]="displayDialogDocumentos" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '50vw', height: 'auto'}">
	<app-uploadfiles *ngIf="displayDialogDocumentos" [txtOrigen]="'COMECNREEM'"
		[txtNumComprobante]="comencreembolsoService.encabezadoreembolso[indicador].ENCREE_LOTE"
		(signalSalir)="recibirSenalesDocumentos($event)">

	</app-uploadfiles>
</p-dialog>
<p-dialog header="Clave Autorización" [(visible)]="displayDialogAutorizacion" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" (onHide)="onHideAutorizacion()" [style]="{width: '40vw', height: 'auto'} ">
	<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="autorizacion"></p-toast>
	<div class="divgrups">
		<div class="p-grid">
			<div class="p-col-12">
				<div class="p-grid">
					<div class="p-col-1"></div>
					<div class="p-col-4">
						<label for="clave" style="font-weight: bold">Clave de Autorización:</label>
					</div>
					<div class="p-col-2"></div>
					<div class="p-col-4">
						<input type="password" pInputText id="clave" [(ngModel)]="claveAuth"
							(keydown.enter)="comprobarClave()" placeholder="Ingrese la clave">
					</div>
					<div class="p-col-1"></div>
				</div>
			</div>
		</div>
	</div>
</p-dialog>