import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../../usuario';
import { ConfirmationService, MessageService, SelectItem } from 'primeng';

import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { DatePipe } from '@angular/common';
import { InvbusquedaService } from '../../inv/invservicios/invbusqueda.service';
import { AuditoriagralService } from '../../advantage/advantageservices/auditoriagral.service';
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { UtilitariosService } from 'src/app/advantage/advantageservices/utilitarios.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { Comencreembolso } from "../cominterfaces/comencreembolso";
import { ComencreembolsoService } from "../comservicios/comencreembolso.service";
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { Comdetreembolso } from "../cominterfaces/comdetreembolso";
import { AggridBanComponent } from '../../ban/aggrid-ban/aggrid-ban.component';
import { ComtrnreembolsosService } from "../comservicios/comtrnreembolsos.service";
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-comencreembolso',
  templateUrl: './comencreembolso.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComencreembolsoComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  btnNuevo;
  btnGuardar;
  btnBorrar;
  indicador: any;
  cmbencestado: any[];
  selecttipoencest: any;
  lblTipoIDEPRO: string = ''
  lblTipoPRO: string = '';
  lblPaispago: string = '';
  cmbestado: any[];
  selecttipocest: any;

  cmbtipogasto: any[];
  selecttipogasto: any;

  lblTipoIdCre: string = '';
  lblTipocom: string = '';
  lblEmpleado: string = '';
  lblsercomnom: string = '';
  lblareadep: string = '';
  detallereembolso: Comdetreembolso;
  detreembolso: Comdetreembolso[] = []
  detreemselect: Comdetreembolso = {};
  //checks
  valSelect = 'valSelect'
  //BUSQUEDAS
  opcion: string;
  types: SelectItem[];
  arregloCons: any[];
  displayDialogBusquedaFast: boolean;
  busquedacampos: string[];
  tabla: string;
  where: string;
  consulta: string;
  spin = false;
  //
  iva: number;//CDPJ
  tariivacodigo: number;
  rowSelection = 'multiple';
  public frameworkComponents;
  rowStyle;
  defaultColDefreemb
  //grilla
  btngridnuevo
  btngridborrar
  btngridregresar
  //permisos  
  permisoReem: SegMaePermiso;

  TotalBaseIva: string;
  TotalBaseCero: string;
  TotalBaseNoGrab: string;
  TotalValorIva: string;
  displayDialogDocumentos = false;
  boolest: boolean = false;
  boolestespecial: boolean = false;
  boolestado99:boolean=false;
  displayDialogAutorizacion: boolean;
  claveAuth = '';
  autorizacionCodigo = '';
  bolAutorizacion: boolean;
  strEstado: string;
  EstadoAnt: string;

  valida: boolean;
  strbienmal: string;
  valorRucI: boolean;
  valorcedulaI: boolean;
  columnDefsdetreemb = [
    {
      headerName: 'S/N',
      field: 'SELECCIONADO',
      cellRendererFramework: AggridBanComponent,
      editable: () => {
        return false;
      },
      width: 50,
    },
    {
      headerName: 'Estado', field: 'DETREE_ESTADO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'TId', field: 'DETREE_TIPOIDEPRO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'Núm.Ide', field: 'DETREE_IDEPRO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'TipoC', field: 'DETREE_IDCRETRI', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'SEst', field: 'DETREE_SERIEEST', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'SPto', field: 'DETREE_SERIEPTOEMI', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'SecComp', field: 'DETREE_SECCOMPVTA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 90
    },
    {
      headerName: 'EmisionC', field: 'DETREE_FECHAEMISION', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 90
    },
    {
      headerName: 'AutComp', field: 'DETREE_AUTORIZACION', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 200
    },
    {
      headerName: 'BaseImp', field: 'DETREE_BASECERO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'BaseImpGra', field: 'DETREE_BASEIVA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'BaseNoGraIv', field: 'DETREE_BASENOIVA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'PorIv', field: 'DETREE_PORCENTAJEIVA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'MontoIce', field: 'DETREE_MONTOICE', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'MontoIva', field: 'DETREE_MONTOIVA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80, cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Empleado', field: 'DETREE_EMPLEADO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 90
    },
    {
      headerName: 'Tipo Gasto', field: 'DETREE_TIPGAS', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'Descripción', field: 'DETREE_DESCRIPCION', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 150
    },
    {
      headerName: 'AreaDpto', field: 'DETREE_AREA', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'Descrip.CC', field: 'DETREE_CENTROC', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
    {
      headerName: 'Servicio', field: 'SERCOM_CODIGO', editable: () => {
        return false;
      }, cellEditor: 'cellPrueba', width: 80
    },
  ]
  constructor(
    public comencreembolsoService: ComencreembolsoService,
    private init: NuevoComponentService, private confIniciales: ConfInicialesService,
    private busqService: InvbusquedaService, private datepipe: DatePipe, private trnreembService: ComtrnreembolsosService,
    private messageService: MessageService, private permisosService: PermisosService, private confirmationService: ConfirmationService,
    private auditoriagral: AuditoriagralService, private utilitariosService: UtilitariosService

  ) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };


    this.defaultColDefreemb = {
      editable: false,
      objeto: 'detreembolso',
      resizable: true
    };

  }
  async ngOnInit() {
    this.indicador = this.init.tabs[this.init.tabindex - 1].indice;
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btngridnuevo = true;
    this.btngridborrar = true;
    this.btngridregresar = true;
    this.strbienmal = '';
    this.TotalBaseIva = Number('0').toFixed(2)
    this.TotalBaseCero = Number('0').toFixed(2)
    this.TotalBaseNoGrab = Number('0').toFixed(2)
    this.TotalValorIva = Number('0').toFixed(2)

    this.comencreembolsoService.encabezadoreembolso[this.indicador] = {}
    this.detallereembolso = {}
    this.detreembolso = []
    this.cmbtipogasto = [{ codigo: '', name: '' }];
    this.selecttipogasto = { codigo: '', name: '' }
    this.selecttipocest = { codigo: '', name: '' }
    await this.getPermisos()
    this.iva = await this.confIniciales.obtenerImpuesto('I', 'A');
    await this.confIniciales.obtenerSrifeTariIva();
    await this.llenarcombos()
    for (let i = 0; this.confIniciales.srifetariiva[i]; i++) {
      let item = this.confIniciales.srifetariiva[i]
      if (item.TARIIVA_CODIGO !== '6' && item.TARIIVA_CODIGO !== '7' && item.TARIIVA_CODIGO !== '-1') {
        if (Number(item.TARIIVA_PORCENTAJE) === this.iva) {
          this.tariivacodigo = item.TARIIVA_CODIGO
        }

      }
    }

  }
  async getPermisos() {
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoReem = data[0];
        } else {
          this.permisoReem = null;
        }
      } else {
        this.permisoReem = null;
      }
    } catch (e) {
      this.permisoReem = null;
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo obtener los permisos del formulario'
      });
    }
  }
  manejarSenales(evento) {
    if (evento === 'Buscar') {
      if (this.permisoReem !== null) {
        if (this.permisoReem.PERTODO === 0) {
          if (this.permisoReem.PERSELECCION === 0) {
            this.messageService.add({
              key: 'encreemb',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.comencreembolsoService.usuario.identificacion
                + ', no tiene permiso de busqueda. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
      this.buscar()
    }
    if (evento === 'Nuevo') {
      if (this.permisoReem !== null) {
        if (this.permisoReem.PERTODO === 0) {
          if (this.permisoReem.PERINSERCION === 0) {
            this.messageService.add({
              key: 'encreemb',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.comencreembolsoService.usuario.identificacion
                + ', no tiene permiso de inserción. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
      document.getElementById('contorno').style.pointerEvents = 'all'
      document.getElementById('contorno2').style.pointerEvents = 'all'
      this.irnuevo();
    }
    if (evento === 'Cancelar') {
      this.cancelar()
    }
    if (evento === 'Guardar') {
      if (this.permisoReem !== null) {
        if (this.permisoReem.PERTODO === 0) {
          if (this.permisoReem.PERACTUALIZACION === 0) {
            this.messageService.add({
              key: 'encreemb',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.comencreembolsoService.usuario.identificacion
                + ', no tiene permiso de actualización. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
      this.empiezaguardar();
    }
    if (evento === 'Borrar') {
      if (this.permisoReem !== null) {
        if (this.permisoReem.PERTODO === 0) {
          if (this.permisoReem.PERELIMACION === 0) {
            this.messageService.add({
              key: 'encreemb',
              severity: 'error',
              summary: 'Información',
              detail: 'El usuario '
                + this.comencreembolsoService.usuario.identificacion
                + ', no tiene permiso de eliminación. Consulte con el Administrador del Sistema.'
            });
            return;
          }
        }
      }
      this.eliminar();
    }
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
    if (evento === 'Cambiarestado') {
      this.cambiarestado()
    }
  }
  async cambiarestado() {
    let valor = 0;
    let itemsA = 0;
    let itemsV = 0
    let itemsP = 0;
    let valServicio = 0;
    let valDescripcion = 0;
    let valDescripcionCentr = 0;
    let valIdcreditribu = 0
    this.detreembolso.map(variable => {
      if (variable.SELECCIONADO === true) {
        valor++
        if (variable.DETREE_ESTADO === 'A') {
          itemsA++;
        }
        if (variable.DETREE_ESTADO === 'V') {
          itemsV++;
        }
        if (variable.DETREE_ESTADO === 'P') {
          itemsP++;
        }
        if (variable.SERCOM_CODIGO === '' || variable.SERCOM_CODIGO === null || variable.SERCOM_CODIGO === undefined) {
          valServicio++
        }
        if (variable.DETREE_DESCRIPCION === '' || variable.DETREE_DESCRIPCION === null || variable.DETREE_DESCRIPCION === undefined) {
          valDescripcion++
        }
        if (variable.DETREE_CENTROC === '' || variable.DETREE_CENTROC === null || variable.DETREE_CENTROC === undefined) {
          valDescripcionCentr++
        }
        if (variable.DETREE_IDCRETRI === '' || variable.DETREE_IDCRETRI === null || variable.DETREE_IDCRETRI === undefined) {
          valIdcreditribu++
        }
      }

    })
    console.log(this.selecttipoencest.codigo)
    console.log(itemsA)
    console.log(itemsV, itemsP)
    if (valor === 0) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'No hay documentos seleccionados.'
      });
      return;
    }
    if (this.selecttipoencest.codigo === 'P' && (itemsV > 0 && itemsP > 0)) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Todos los documentos seleccionados deben estar en estado Aprobado para cambiar al estado pendiente'
      });
      return;
    }
    if (this.selecttipoencest.codigo === 'V' && (itemsV > 0 && itemsP > 0)) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Todos los documentos seleccionados deben estar en estado Aprobado para cambiar al estado validado'
      });
      return;
    }

    if (this.selecttipoencest.codigo === 'A' && (itemsV > 0 || itemsP > 0) && (itemsV > 0 && itemsP > 0)) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Todos los documentos seleccionados deben estar en estado Pendiente o Validado para cambiar al estado aprobado'
      });
      return;
    }
    /////-------cambiar al estado aprobado
    if (this.selecttipoencest.codigo === 'A' && itemsA === 0 && (itemsV > 0 || itemsP > 0)) {
      if (valServicio !== 0) {
        this.messageService.add({
          key: 'encreemb',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el código del servicio'
        });
        return;
      }
      if (valDescripcion !== 0) {
        this.messageService.add({
          key: 'encreemb',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese la descripción'
        });
        return;
      }
      if (valDescripcionCentr !== 0) {
        this.messageService.add({
          key: 'encreemb',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese la descripción del centro de costos'
        });
        return;
      }
      this.strEstado = 'A'
      this.EstadoAnt = 'P'
      this.claveAuth = '';
      this.autorizacionCodigo = 'AUTAPREE';
      this.displayDialogAutorizacion = true;

    }
    /////-------cambiar al estado pendiente
    if (this.selecttipoencest.codigo === 'P' && (itemsA > 0 || itemsV > 0)) {
      this.confirmationService.confirm({
        message: 'Está seguro de reversar el estado a pendiente?',
        header: 'Eliminar Reembolso',
        icon: 'pi pi-exclamation-triangle',
        key: 'encreembconf',
        accept: async () => {
          this.claveAuth = '';
          this.strEstado = 'P'
          this.EstadoAnt = 'A'
          this.autorizacionCodigo = 'AUTAPREE';
          this.displayDialogAutorizacion = false;

        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    } else if (this.selecttipoencest.codigo === 'V' && itemsA > 0) {/////-------cambiar al estado validado
      if (valIdcreditribu > 0) {
        this.messageService.add({
          key: 'encreemb',
          severity: 'error',
          summary: 'Información',
          detail: 'Ingrese el Id de crédito tributario'
        });
        return;
      }
      this.claveAuth = '';
      this.strEstado = 'V'
      this.EstadoAnt = 'A'
      this.autorizacionCodigo = 'AUTAPREE';
      this.displayDialogAutorizacion = true;

    } else if (this.selecttipoencest.codigo === 'A' && itemsV > 0) {/////-------cambiar al estado aprobado
      this.confirmationService.confirm({
        message: 'Está seguro de reversar el estado a aprobado?',
        header: 'Eliminar Reembolso',
        icon: 'pi pi-exclamation-triangle',
        key: 'encreembconf',
        accept: async () => {
          this.claveAuth = '';
          this.strEstado = 'A'
          this.EstadoAnt = 'V'
          this.autorizacionCodigo = 'AUTAPREE';
          this.displayDialogAutorizacion = true;

        },
        reject: () => {
          this.confirmationService.close();
        }
      });
    }

  }
  async cambiestadoFin() {
    try {
      let fecha = ''
      for (const variable of this.detreembolso) {
        if (variable.SELECCIONADO === true) {
          if (variable.DETREE_FECHAAUTORIZA === null || variable.DETREE_FECHAAUTORIZA === undefined || variable.DETREE_FECHAAUTORIZA === '') {
            fecha = this.datepipe.transform(new Date(), 'dd/MM/yyyy')
          } else {
            if (variable.DETREE_FECHAAUTORIZA.toString().includes('-')) {
              fecha = this.datepipe.transform(variable.DETREE_FECHAAUTORIZA)
            } else {
              fecha = variable.DETREE_FECHAAUTORIZA
            }
          }
          await this.comencreembolsoService.actualizarEstado(this.strEstado, this.EstadoAnt, fecha, variable.ENCREE_LOTE, variable.DETREE_LINEA)

          this.auditoriagral.registrarAuditoria('COM_DETREEMBOLSO',
            this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE + '/' + this.detallereembolso.DETREE_SECCOMPVTA + '/EstAct./' + this.strEstado + '/EstAnt.' + this.EstadoAnt, 'A',
            '', '01', '', '', '', '').subscribe();
        }
      }
      await this.enontrarregistro(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
    } catch (error) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al actualizar el estado'
      });
      return;
    }
  }
  onHideAutorizacion() {
    if (this.bolAutorizacion === false) {
      if (this.autorizacionCodigo === 'AUTAPREE') {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Información',
          detail: 'Debe ingresar correctamente'
            + ' la clave'
        });


      }
    }
  }
  comprobarClave() {
    this.utilitariosService.obtenerClave(this.autorizacionCodigo).subscribe(async (clave) => {
      let claveReal;
      claveReal = clave[0].AUTORIZACION_CLAVE;
      if (claveReal === this.claveAuth) {
        await this.cambiestadoFin();
        await this.enontrarregistro(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
        this.displayDialogAutorizacion = false;
      } else if (clave !== this.claveAuth) {
        this.messageService.add({
          key: 'autorizacion',
          severity: 'error',
          summary: 'Clave Autorización',
          detail: 'La clave que se ingresó es incorrecta, intente nuevamente'
        });
      }
    });
  }
  validarIdeExiste(ide, codigocliente) {
    this.validarIde(ide, codigocliente);

    if (this.valorcedulaI === true && this.valorRucI === true) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'warn',
        summary: 'Información',
        detail: 'Identificación no valida'
      });

      this.valida = true;
      this.strbienmal = 'Mal';
    } else {
      this.valida = false;
      this.strbienmal = 'Bien';
    }
  }
  validarIde(ide, codigocliente) {
    this.valorRucI = true;
    this.valorcedulaI = true;

    if (ide === 2) {
      let total = 0;
      const longitud = codigocliente.length;
      const longcheck = longitud - 1;

      if (codigocliente !== '' && longitud <= 10) {
        for (let i = 0; i < longcheck; i++) {
          if (i % 2 === 0) {
            let aux = Number(codigocliente.charAt(i)) * 2;
            if (aux > 9) {
              aux -= 9;
            }
            total += aux;
          } else {
            total += parseInt(codigocliente.charAt(i)); // parseInt o concatenar� en lugar de sumar
          }
        }

        total = total % 10 ? 10 - total % 10 : 0;

        this.valorcedulaI = Number(codigocliente.charAt(longitud - 1)) !== total;
      }
    }
    if (ide === 1) {
      const numero = codigocliente;
      const long = numero.length;
      let valor;
      let acu = 0;
      if (numero === '') {
        this.valorRucI = true;
      } else {
        for (let i = 0; i < long; i++) {
          valor = numero.substr(i, i + 1);
          if (valor === 0 || 1 || 2 || 3 || 4 || 5 || 6 || 7
            || 8 || 9) {
            acu = acu + 1;
          }
        }
        if (acu === long) {
          while (numero.substr(10, 13) !== '001') {
            this.valorRucI = true;
            return;
          }
          while (Number(numero.substr(0, 2)) > 24) {
            this.valorRucI = true;
            return;
          }
          this.valorRucI = false;
          const porcion1 = numero.substr(2, 3);
          if (Number(porcion1) < 6) {
            this.valorRucI = false;
          } else {
            if (Number(porcion1) === 6) {
              this.valorRucI = false;
            } else {
              if (Number(porcion1) === 9) {
                this.valorRucI = false;
              }
            }
          }
        } else {
          this.valorRucI = true;
        }
      }
    }
  }

  eliminar() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el reembolso ' + this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE,
      header: 'Eliminar Reembolso',
      icon: 'pi pi-exclamation-triangle',
      key: 'encreembconf',
      accept: async () => {
        try {
          await this.comencreembolsoService.eliminarecnreem(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
          this.messageService.add({
            key: 'encreemb',
            severity: 'success',
            summary: 'Información',
            detail: 'Éxito al eliminar el reembolso.'
          });
          this.auditoriagral.registrarAuditoria('COM_ENCREEMBOLSO',
            this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE, 'E',
            '', '01', '', '', '', '').subscribe();
          this.btnNuevo = false;
          this.btnGuardar = true;
          this.btnBorrar = false;
          this.btngridnuevo = true;
          this.btngridborrar = true;
          this.btngridregresar = true;
          this.TotalBaseIva = Number('0').toFixed(2)
          this.TotalBaseCero = Number('0').toFixed(2)
          this.TotalBaseNoGrab = Number('0').toFixed(2)
          this.TotalValorIva = Number('0').toFixed(2)
          this.lblEmpleado = '';
          this.lblPaispago = '';
          this.lblTipoIDEPRO = '';
          this.lblTipoIdCre = '';
          this.lblTipoPRO = '';
          this.lblTipocom = '';
          this.lblareadep = '';
          this.lblsercomnom = '';
          this.comencreembolsoService.encabezadoreembolso[this.indicador] = {}
          this.detallereembolso = {}
          this.detreembolso = [];
          this.selecttipogasto = { codigo: '', name: '' }
          this.selecttipocest = { codigo: 'P', name: 'Pendiente' }
          document.getElementById('contorno').style.pointerEvents = 'none'
          document.getElementById('contorno2').style.pointerEvents = 'none'
        } catch (error) {
          this.messageService.add({
            key: 'encreemb',
            severity: 'error',
            summary: 'Información',
            detail: 'Ocurrio un error al eliminar el reembolso'
          });
          return;
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async empiezaguardar() {
    if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_CAJA === null ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_CAJA === undefined ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_CAJA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'La caja no puede estar vacía'
      });
      return;
    }
    if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION === null ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION === undefined ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'La fecha no puede estar vacía'
      });
      return;
    }
    if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_SALDO_CAJA === null ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_SALDO_CAJA === undefined ||
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_SALDO_CAJA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El valor de la caja inicial no puede estar vacío'
      });
      return;
    }
    await this.validaDetallereem();
  }
  async validaDetallereem() {
    if (this.selecttipocest.codigo === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un estado'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_TIPOIDEPRO) || this.detallereembolso.DETREE_TIPOIDEPRO === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Tipo de Identificación del Proveedor está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_IDEPRO) || this.detallereembolso.DETREE_IDEPRO === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo No. de Identificación del Proveedor está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_PROTIPO) || this.detallereembolso.DETREE_PROTIPO === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Tipo de Proveedor está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_FECHAEMISION) || this.detallereembolso.DETREE_FECHAEMISION === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo F. emisión del comprobante de venta está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_BASECERO) || this.detallereembolso.DETREE_BASECERO === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Base Imponible tarifa 0% IVA está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_BASEIVA) || this.detallereembolso.DETREE_BASEIVA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Base Imponible tarifa IVA diferente de 0% está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_BASENOIVA) || this.detallereembolso.DETREE_BASENOIVA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Base Imponible no objeto de IVA está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_MONTOICE) || this.detallereembolso.DETREE_MONTOICE === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Monto ICE está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_MONTOIVA) || this.detallereembolso.DETREE_MONTOIVA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Monto IVA está vacío.'
      });
      return;
    }
    if (isNullOrUndefined(this.detallereembolso.DETREE_PORCENTAJEIVA) || this.detallereembolso.DETREE_PORCENTAJEIVA === '') {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'El campo Porcentaje de IVA está vacío.'
      });
      return;
    }
    await this.finguardar();
  }
  async finguardar() {
    try {
      if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION.toString().includes('-')) {
        this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION = this.datepipe.transform(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION, 'dd/MM/yyyy')
      } else {
        this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION = this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_FECHAEMISION;
      }
      const data = await this.comencreembolsoService.guardarencreem(this.comencreembolsoService.encabezadoreembolso[this.indicador]);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const item of data) {
          console.log(item[':B1']);
          this.detallereembolso.ENCREE_LOTE = item[':B1'];
          if (this.detallereembolso.DETREE_FECHAEMISION.toString().includes('-')) {
            this.detallereembolso.DETREE_FECHAEMISION = this.datepipe.transform(this.detallereembolso.DETREE_FECHAEMISION, 'dd/MM/yyyy')
          }
          this.detallereembolso.DETREE_TIPGAS = this.selecttipogasto.name
          this.detallereembolso.DETREE_ESTADO = this.selecttipocest.codigo;
          await this.comencreembolsoService.guardardetreem(this.detallereembolso);
          if (this.detallereembolso.DETREE_LINEA === 0) {
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.messageService.add({
              key: 'encreemb',
              severity: 'success',
              summary: 'Información',
              detail: 'Éxito al insertar el registro'
            });
            this.auditoriagral.registrarAuditoria('COM_ENCREEMBOLSO',
              this.detallereembolso.ENCREE_LOTE, 'I',
              '', '01', '', '', '', '').subscribe();
            this.auditoriagral.registrarAuditoria('COM_DETREEMBOLSO',
              this.detallereembolso.ENCREE_LOTE + '/' + this.detallereembolso.DETREE_SECCOMPVTA, 'I',
              '', '01', '', '', '', '').subscribe();
            await this.enontrarregistro(this.detallereembolso.ENCREE_LOTE)
          } else {
            this.btnNuevo = false;
            this.btnGuardar = true;
            this.btnBorrar = false;
            this.messageService.add({
              key: 'encreemb',
              severity: 'success',
              summary: 'Información',
              detail: 'Éxito al actualizar el registro'
            });
            this.auditoriagral.registrarAuditoria('COM_ENCREEMBOLSO',
              this.detallereembolso.ENCREE_LOTE, 'A',
              '', '01', '', '', '', '').subscribe();
            this.auditoriagral.registrarAuditoria('COM_DETREEMBOLSO',
              this.detallereembolso.ENCREE_LOTE + '/' + this.detallereembolso.DETREE_SECCOMPVTA, 'A',
              '', '01', '', '', '', '').subscribe();
            await this.enontrarregistro(this.detallereembolso.ENCREE_LOTE)
          }
        }
      }
    } catch (error) {
      this.messageService.add({
        key: 'encreemb',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al guardar el reembolso'
      });
      return;
    }
  }
  calculariva() {
    let calc = 0.0;
    calc = (Number(this.detallereembolso.DETREE_BASEIVA)
      * Number(this.detallereembolso.DETREE_PORCENTAJEIVA) / 100);
    this.detallereembolso.DETREE_MONTOIVA = calc.toFixed(2);
  }

  calculartotales() {
    let dblTotalBaseIva = 0;
    let dblTotalBaseCero = 0;
    let dblTotalBaseNoGrab = 0;
    let dblTotalValorIva = 0;

    for (let i = 0; i < this.detreembolso.length; i++) {
      dblTotalBaseIva = dblTotalBaseIva
        + Number(this.detreembolso[i].DETREE_BASEIVA);
      dblTotalBaseCero = dblTotalBaseCero
        + Number(this.detreembolso[i].DETREE_BASECERO);
      dblTotalBaseNoGrab = dblTotalBaseNoGrab
        + Number(this.detreembolso[i].DETREE_BASENOIVA);
      dblTotalValorIva = dblTotalValorIva
        + Number(this.detreembolso[i].DETREE_MONTOIVA);
    }

    this.TotalBaseIva = dblTotalBaseIva.toFixed(2);
    this.TotalBaseCero = dblTotalBaseCero.toFixed(2);
    this.TotalBaseNoGrab = dblTotalBaseNoGrab.toFixed(2);
    this.TotalValorIva = dblTotalValorIva.toFixed(2);
  }
  cancelar() {
    if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE !== null &&
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE !== undefined &&
      this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE !== '') {
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.detallereembolso = {}
      this.lblEmpleado = '';
      this.lblPaispago = '';
      this.lblTipoIDEPRO = '';
      this.lblTipoIdCre = '';
      this.lblTipoPRO = '';
      this.lblTipocom = '';
      this.lblareadep = '';
      this.lblsercomnom = '';
      this.selecttipogasto = { codigo: '', name: '' }
      this.selecttipocest = { codigo: '', name: '' }
      this.TotalBaseIva = Number('0').toFixed(2)
      this.TotalBaseCero = Number('0').toFixed(2)
      this.TotalBaseNoGrab = Number('0').toFixed(2)
      this.TotalValorIva = Number('0').toFixed(2)
      this.enontrarregistro(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
    } else {
      //this.irnuevo();
      this.btnNuevo = false;
      this.btnGuardar = true;
      this.btnBorrar = false;
      this.btngridnuevo = true;
      this.btngridborrar = true;
      this.btngridregresar = true;
      this.TotalBaseIva = Number('0').toFixed(2)
      this.TotalBaseCero = Number('0').toFixed(2)
      this.TotalBaseNoGrab = Number('0').toFixed(2)
      this.TotalValorIva = Number('0').toFixed(2)
      this.lblEmpleado = '';
      this.lblPaispago = '';
      this.lblTipoIDEPRO = '';
      this.lblTipoIdCre = '';
      this.lblTipoPRO = '';
      this.lblTipocom = '';
      this.lblareadep = '';
      this.lblsercomnom = '';
      this.comencreembolsoService.encabezadoreembolso[this.indicador] = {}
      this.detallereembolso = {}
      this.detreembolso = [];
      this.selecttipogasto = { codigo: '', name: '' }
      this.selecttipocest = { codigo: '', name: '' }
      document.getElementById('contorno').style.pointerEvents = 'none'
      document.getElementById('contorno2').style.pointerEvents = 'none'
    }
  }
  irnuevo() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
    this.btngridnuevo = true;
    this.btngridborrar = true;
    this.btngridregresar = true;
    this.boolest = false;
    this.boolestespecial = false;
    this.boolestado99=false;
    this.TotalBaseIva = Number('0').toFixed(2)
    this.TotalBaseCero = Number('0').toFixed(2)
    this.TotalBaseNoGrab = Number('0').toFixed(2)
    this.TotalValorIva = Number('0').toFixed(2)
    this.lblEmpleado = '';
    this.lblPaispago = '';
    this.lblTipoIDEPRO = '';
    this.lblTipoIdCre = '';
    this.lblTipoPRO = '';
    this.lblTipocom = '';
    this.lblareadep = '';
    this.lblsercomnom = '';
    this.comencreembolsoService.encabezadoreembolso[this.indicador] = {}
    this.detallereembolso = {}
    this.detreembolso = [];
    this.comencreembolsoService.encabezadoreembolso[this.indicador] = {
      ENCREE_LOTE: '',
      COM_CODIGO: '',
      ENCREE_FECHAEMISION: new Date(),
      USU_IDENTIFICACION: this.comencreembolsoService.usuario.identificacion,
      ENCREE_CAJA: '',
      ENCREE_LOCALIZACION: '',
      ENCREE_SALDO_CAJA: Number('0').toFixed(2),
      CAJ_NOMBRE: ''
    }
    this.detallereembolso = {
      ENCREE_LOTE: '',
      COM_CODIGO: '01',
      DETREE_LINEA: 0,
      ENCFACPRO_NUMERO: '',
      DETREE_IDEPRO: '',
      DETREE_SERIEEST: '',
      DETREE_SERIEPTOEMI: '',
      DETREE_SECCOMPVTA: '',
      DETREE_TIPCOMP: '',
      DETREE_TIPOIDEPRO: '',
      DETREE_FECHAEMISION: new Date(),
      DETREE_AUTORIZACION: '',
      DETREE_BASECERO: '0.00',
      DETREE_BASEIVA: '0.00',
      DETREE_BASENOIVA: '0.00',
      DETREE_PORCENTAJEIVA: Number(this.iva).toFixed(2),
      DETREE_MONTOICE: '0.00',
      DETREE_MONTOIVA: '0.00',
      DETREE_BASEIMPEXEREEMB: '0.00',
      DETREE_PAISPAGO: '593',
      DETREE_PROTIPO: '',
      DETREE_EMPLEADO: '',
      DETREE_TIPGAS: '',
      DETREE_DESCRIPCION: '',
      DETREE_AREA: '',
      DETREE_CENTROC: '',
      DETREE_ESTADO: '',
      SERCOM_CODIGO: '',
      DETREE_IDCRETRI: '',
      DETREE_USUAPRUEBA: '',
      DETREE_FECHAAUTORIZA: '',
      DETREE_USUVALIDA: '',
      TARIIVA_CODIGO: this.tariivacodigo
    }
    this.selecttipogasto = { codigo: '', name: '' }
    this.selecttipocest = { codigo: 'P', name: 'Pendiente' }
    document.getElementById('ENCREE_CAJA').focus();
  }
  ngOnDestroy(): void {
    this.confIniciales.instanciasEncReem--;
  }
  buscar() {
    this.opcion = 'BUSQUEDA'
    this.types = [
      { label: 'LOTE', value: 'ENCREE_LOTE' },
      { label: 'FECHA EMISION', value: 'ENCREE_FECHAEMISION' },
      { label: 'COD CAJA', value: 'ENCREE_CAJA' },
      { label: 'SALDO', value: 'ENCREE_SALDO_CAJA' },
    ];
    this.busquedacampos = ['', '', '', ''];
    this.tabla = 'COM_ENCREEMBOLSO';
    this.where = ''
    this.consulta = '';
    this.busqService.busquedaEncReem().subscribe((res: any[]) => {
      this.arregloCons = res;
      this.displayDialogBusquedaFast = true;
    });
  }
  manejarSeleccion(opcion) {
    switch (this.opcion) {
      case 'BUSQUEDA':
        this.enontrarregistro(opcion.ENCREE_LOTE);
        break;
      case 'DETREE_TIPOIDEPRO':
        this.detallereembolso.DETREE_TIPOIDEPRO = opcion.CODSRI_CODIGO
        this.lblTipoIDEPRO = opcion.CODSRI_DESCRIPCION
        document.getElementById('DETREE_IDEPRO').focus();
        break;
      case 'DETREE_PROTIPO':
        this.detallereembolso.DETREE_PROTIPO = opcion.CODSRI_CODIGO
        this.lblTipoPRO = opcion.CODSRI_DESCRIPCION
        document.getElementById('DETREE_PAISPAGO').focus();
        break;
      case 'DETREE_PAISPAGO':
        this.detallereembolso.DETREE_PAISPAGO = opcion.CODSRI_CODIGO
        this.lblPaispago = opcion.CODSRI_DESCRIPCION
        document.getElementById('DETREE_IDCRETRI').focus();
        break;
      case 'DETREE_IDETRIB':
        this.detallereembolso.DETREE_IDCRETRI = opcion.CODSRI_CODIGO
        this.lblTipoIdCre = opcion.CODSRI_DESCRIPCION
        document.getElementById('DETREE_TIPCOMP').focus();
        break;
      case 'DETREE_TIPCOMP':
        this.detallereembolso.DETREE_TIPCOMP = opcion.CODSRI_CODIGO
        this.lblTipocom = opcion.CODSRI_DESCRIPCION
        if(opcion.CODSRI_CODIGO === '99'){
          this.boolestado99=true
        }else{
          this.boolestado99=false
        }
        document.getElementById('DETREE_SERIEEST').focus();
        break;
      case 'DETREE_EMPLEADO':
        this.detallereembolso.DETREE_EMPLEADO = opcion.REF_CODIGO
        this.lblEmpleado = opcion.REF_NOMBRE
        document.getElementById('DETREE_EMPLEADO').focus();
        break;
      case 'ENCREE_CAJA':
        this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_CAJA = opcion.REF_CODIGO;
        this.comencreembolsoService.encabezadoreembolso[this.indicador].CAJ_NOMBRE = opcion.REF_NOMBRE
        document.getElementById('ENCREE_SALDO_CAJA').focus();
        break;
      case 'DETREE_AREA':
        this.detallereembolso.DETREE_AREA = opcion.REF_CODIGO;
        this.lblareadep = opcion.REF_NOMBRE;
        document.getElementById('DETREE_CENTROC').focus();
        break;
      case 'SERCOM_CODIGO':
        this.detallereembolso.SERCOM_CODIGO = opcion.SERCOM_CODIGO
        this.lblsercomnom = opcion.SERCOM_DESCRIPCION
        document.getElementById('DETREE_DESCRIPCION').focus();
        break;

      default:
        break;
    }
    this.displayDialogBusquedaFast = false;
  }
  async  llenarcombos() {

    const data = await this.trnreembService.ecllenarConsulta()
    //console.log(data)
    this.cmbtipogasto = [];
    this.cmbtipogasto = [{ codigo: '', name: '' }]
    for (const rs of data) {
      console.log(rs)
      this.cmbtipogasto.push({ codigo: rs.REF_CODIGO, name: rs.REF_NOMBRE });
    }

    this.cmbestado = [
      { codigo: '', name: '' },
      { codigo: 'A', name: 'Aprobado' },
      { codigo: 'V', name: 'Validado' },
      { codigo: 'P', name: 'Pendiente' },
      { codigo: 'O', name: 'Insertado Factura' },
    ]
    this.cmbencestado = [
      { codigo: '', name: '' },
      { codigo: 'P', name: 'Pendiente' },
      { codigo: 'A', name: 'Aprobado' },
      { codigo: 'V', name: 'Validado' },
    ]
  }
  async  enontrarregistro(lote) {
    try {
      const data = await this.comencreembolsoService.getencreem(lote);
      if (data !== null && data !== undefined && data.length > 0) {
        for (const item of data) {
          const data2 = await this.comencreembolsoService.getnomcaj(item.ENCREE_CAJA);
          const data3 = await this.comencreembolsoService.getdetreem(item.ENCREE_LOTE);
          if (data2 !== null && data2 !== undefined && data2.length > 0) {
            for (const caj of data2) {
              item.CAJ_NOMBRE = caj.REF_NOMBRE
            }
          }
          item.ENCREE_FECHAEMISION = new Date(item.ENCREE_FECHAEMISION);
          item.ENCREE_SALDO_CAJA = item.ENCREE_SALDO_CAJA === null || item.ENCREE_SALDO_CAJA === undefined ? Number('0').toFixed(2) : Number(item.ENCREE_SALDO_CAJA).toFixed(2);
          this.comencreembolsoService.encabezadoreembolso[this.indicador] = item;
          if (data3 !== null && data3 !== undefined && data3.length > 0) {
            this.detreembolso = data3
            for (const det of this.detreembolso) {
              det.DETREE_BASECERO = Number(det.DETREE_BASECERO).toFixed(2);
              det.DETREE_BASEIMPEXEREEMB = Number(det.DETREE_BASEIMPEXEREEMB).toFixed(2);
              det.DETREE_BASEIVA = Number(det.DETREE_BASEIVA).toFixed(2);
              det.DETREE_BASENOIVA = Number(det.DETREE_BASENOIVA).toFixed(2);
              det.DETREE_MONTOICE = Number(det.DETREE_MONTOICE).toFixed(2);
              det.DETREE_MONTOIVA = Number(det.DETREE_MONTOIVA).toFixed(2);
              det.DETREE_FECHAAUTORIZA = this.datepipe.transform(det.DETREE_FECHAAUTORIZA, 'dd/MM/yyyy');
              det.DETREE_FECHAEMISION = this.datepipe.transform(det.DETREE_FECHAEMISION, 'dd/MM/yyyy')
              det.SELECCIONADO = false;
            }
            this.aggrid.refreshaggrid(this.detreembolso, this.defaultColDefreemb.objeto);
            this.calculartotales();
          } else {
            this.TotalBaseIva = Number('0').toFixed(2)
            this.TotalBaseCero = Number('0').toFixed(2)
            this.TotalBaseNoGrab = Number('0').toFixed(2)
            this.TotalValorIva = Number('0').toFixed(2)
            this.detallereembolso = {
              ENCREE_LOTE: '',
              COM_CODIGO: '01',
              DETREE_LINEA: 0,
              ENCFACPRO_NUMERO: '',
              DETREE_IDEPRO: '',
              DETREE_SERIEEST: '',
              DETREE_SERIEPTOEMI: '',
              DETREE_SECCOMPVTA: '',
              DETREE_TIPCOMP: '',
              DETREE_TIPOIDEPRO: '',
              DETREE_FECHAEMISION: new Date(),
              DETREE_AUTORIZACION: '',
              DETREE_BASECERO: '0.00',
              DETREE_BASEIVA: '0.00',
              DETREE_BASENOIVA: '0.00',
              DETREE_PORCENTAJEIVA: Number(this.iva).toFixed(2),
              DETREE_MONTOICE: '0.00',
              DETREE_MONTOIVA: '0.00',
              DETREE_BASEIMPEXEREEMB: '0.00',
              DETREE_PAISPAGO: '593',
              DETREE_PROTIPO: '',
              DETREE_EMPLEADO: '',
              DETREE_TIPGAS: '',
              DETREE_DESCRIPCION: '',
              DETREE_AREA: '',
              DETREE_CENTROC: '',
              DETREE_ESTADO: '',
              SERCOM_CODIGO: '',
              DETREE_IDCRETRI: '',
              DETREE_USUAPRUEBA: '',
              DETREE_FECHAAUTORIZA: '',
              DETREE_USUVALIDA: '',
              TARIIVA_CODIGO: this.tariivacodigo
            }
          }
        }
        document.getElementById('contorno').style.pointerEvents = 'all'
        document.getElementById('contorno2').style.pointerEvents = 'all'
        this.btngridnuevo = false;
        this.btngridborrar = false;
        this.btngridregresar = false;
      }
    } catch (error) {

    }

  }
  setFocus(evento) {
    switch (evento.target.id) {
      case 'ENCREE_CAJA':
        this.busquedacaja(evento.target.value);
        break;
      case 'DETREE_TIPOIDEPRO':
        this.busquedatipoId(evento.target.value);
        break;
      case 'DETREE_IDEPRO':
        document.getElementById('DETREE_PROTIPO').focus();
        break;
      case 'DETREE_PROTIPO':
        this.busquedatipoprov(evento.target.value);
        break;
      case 'DETREE_PAISPAGO':
        this.busquedatipaispago(evento.target.value);
        break;
      case 'DETREE_IDCRETRI':
        this.busquedaIdCreditrib(evento.target.value);
        break;
      case 'DETREE_TIPCOMP':
        this.busquedaticomprobante(evento.target.value);
        break;
      case 'DETREE_SERIEEST':
        document.getElementById('DETREE_SERIEPTOEMI').focus();
        break;
      case 'DETREE_SERIEPTOEMI':
        document.getElementById('DETREE_SECCOMPVTA').focus();
        break;
      case 'DETREE_SECCOMPVTA':
        this.detallereembolso.DETREE_SECCOMPVTA = this.autoCompletar(this.detallereembolso.DETREE_SECCOMPVTA, 9);
        document.getElementById('DETREE_AUTORIZACION').focus();
        break;
      case 'DETREE_AUTORIZACION':
        document.getElementById('DETREE_BASECERO').focus();
        break;
      case 'DETREE_BASECERO':
        document.getElementById('DETREE_BASEIMPEXEREEMB').focus();
        break;
      case 'DETREE_BASEIMPEXEREEMB':
        document.getElementById('DETREE_BASEIVA').focus();
        this.calculariva()
        break;
      case 'DETREE_BASEIVA':
        document.getElementById('DETREE_BASENOIVA').focus();
        this.calculariva()
        break;
      case 'DETREE_BASENOIVA':
        document.getElementById('DETREE_PORCENTAJEIVA').focus();
        break;
      case 'DETREE_PORCENTAJEIVA':
        document.getElementById('DETREE_MONTOICE').focus();
        this.calculariva()
        break;
      case 'DETREE_MONTOICE':
        document.getElementById('DETREE_MONTOIVA').focus();
        break;
      case 'DETREE_MONTOIVA':
        document.getElementById('DETREE_EMPLEADO').focus();
        break;
      case 'DETREE_EMPLEADO':
        this.busquedaEmpleado(evento.target.value);
        break;
      case 'SERCOM_CODIGO':
        this.busquedasercomcodigo(evento.target.value);
        break;
      case 'DETREE_DESCRIPCION':
        document.getElementById('DETREE_AREA').focus();
        break;
      case 'DETREE_AREA':
        this.busquedaareadep(evento.target.value);
        break;
      default:
        break;
    }
  }
  autoCompletar(strCadena, intTamanioCadena): string {
    let intAux = 0;
    let indice = 0;
    let strAutoCompletar = '';

    intAux = strCadena.length;
    for (indice = 1; indice <= intTamanioCadena - intAux; indice++) {
      strAutoCompletar = '0' + strAutoCompletar;
    }

    strAutoCompletar = strAutoCompletar + strCadena;

    return strAutoCompletar;
  }
  botonesmodificar() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = false;
  }
  busquedacaja(parametro) {
    this.opcion = 'ENCREE_CAJA';

    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO=\'CAJLOT\'';
    this.comencreembolsoService.getencnomcaj(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReemCaj(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReemCaj(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedatipoId(parametro) {
    this.opcion = 'DETREE_TIPOIDEPRO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TI\'';

    this.trnreembService.erabrirTIPOIDEPRO(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.lblTipoIDEPRO = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('DETREE_IDEPRO').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedatipoprov(parametro) {
    this.opcion = 'DETREE_PROTIPO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TP\'';

    this.trnreembService.erabrirPROTIPO(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedatipaispago(parametro) {
    this.opcion = 'DETREE_PAISPAGO';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'PA\'';

    this.trnreembService.erabrirPAISPAGO(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaIdCreditrib(parametro) {
    let strFechaEmision
    if (this.detallereembolso.DETREE_FECHAEMISION.toString().includes('-')) {
      strFechaEmision = this.datepipe.transform(this.detallereembolso.DETREE_FECHAEMISION, 'dd/MM/yyyy');
    } else {
      strFechaEmision = this.detallereembolso.DETREE_FECHAEMISION
    }

    let strSentencia = '';
    strSentencia = strSentencia + ' to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')>= codsri_fechaini';
    strSentencia = strSentencia + ' and to_date( \'' + strFechaEmision + '\' ,\'dd/mm/yyyy\')<= codsri_fechafin';

    if (parametro === null) {
      parametro = '';
    }
    this.opcion = 'DETREE_IDETRIB';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref.', value: 'CODSRI_REF' },
      { label: 'Código Con.', value: 'CON_CODIGO' },
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.consulta = '';
    this.tabla = 'ANEX_MAECODSRI';
    this.where = 'CODSRI_TIPO=\'CT\' AND ' + strSentencia;

    this.comencreembolsoService.erCodSriDescripcion1(strFechaEmision, parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.lblTipoIdCre = eR[0].CODSRI_DESCRIPCION;
          document.getElementById('DETREE_TIPCOMP').focus();
        } else {
          this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri2(strSentencia, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaticomprobante(parametro) {
    this.opcion = 'DETREE_TIPCOMP';
    this.types = [
      { label: 'Código', value: 'CODSRI_CODIGO' },
      { label: 'Descripción', value: 'CODSRI_DESCRIPCION' },
      { label: 'Tipo', value: 'CODSRI_TIPO' },
      { label: 'Año', value: 'CODSRI_ANIO' },
      { label: 'Ref', value: 'CODSRI_REF' },
      { label: 'Código CON', value: 'CON_CODIGO' }
    ];
    this.busquedacampos = [parametro, '', '', '', '', ''];
    this.tabla = 'ANEX_MAECODSRI';
    this.consulta = 'CODSRI_CODIGO,CODSRI_DESCRIPCION,CODSRI_TIPO,CODSRI_ANIO,CODSRI_REF,CON_CODIGO';
    this.where = 'CODSRI_TIPO=\'TC\'';

    this.trnreembService.erabrirTIPCOMP(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaAnexMaeCodSri12(this.where, parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaEmpleado(parametro) {
    this.opcion = 'DETREE_EMPLEADO';
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO=\'EMPLE\'';
    this.trnreembService.erabrirEMPLEADO(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReferencia3(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReferencia3(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedasercomcodigo(parametro) {
    this.opcion = 'SERCOM_CODIGO';
    this.types = [
      { label: 'CÓDIGO', value: 'SERCOM_CODIGO' },
      { label: 'NOMBRE', value: 'SERCOM_DESCRIPCION' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'COM_MAESERVICIO';
    this.consulta = '*';
    this.where = ' SERCOM_CODIGO IS NOT NULL ORDER BY 1';
    this.comencreembolsoService.ersercom(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReemSercom(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReemSercom(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }
  busquedaareadep(parametro) {
    this.opcion = 'DETREE_AREA';
    this.types = [
      { label: 'CÓDIGO', value: 'REF_CODIGO' },
      { label: 'NOMBRE', value: 'REF_NOMBRE' }
    ];
    this.busquedacampos = [parametro, ''];
    this.tabla = 'SACI_MAEREFERENCIA';
    this.consulta = '*';
    this.where = 'REF_TIPO=\'AREADP\'';
    if(parametro === null || parametro === undefined){
      parametro=''
    }
    this.comencreembolsoService.erabrirArea(parametro).subscribe(eR => {
      if (eR !== null) {
        if (eR[0] !== undefined) {
          this.manejarSeleccion(eR[0]);
        } else {
          this.busqService.busquedaSaciMaeReemAreadep(parametro).subscribe((datos: any[]) => {
            this.arregloCons = datos;
            this.displayDialogBusquedaFast = true;
          });
        }
      } else {
        this.busqService.busquedaSaciMaeReemAreadep(parametro).subscribe((datos: any[]) => {
          this.arregloCons = datos;
          this.displayDialogBusquedaFast = true;
        });
      }
    });
  }

  seleccionarall(event) {
    this.detreembolso.map(variable => {
      if (variable.DETREE_ESTADO !== 'O') {
        variable.SELECCIONADO = true
      }
    })
    this.aggrid.refreshaggrid(this.detreembolso, this.defaultColDefreemb.objeto)
  }
  selectedPresuCon(params) {

    console.log(params.object)
    console.log(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
    if (params === 'nuevo') {
      console.log(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
      if (isNullOrUndefined(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
        || this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE === '') {
        return;
      } else {
        this.boolest = false;
        this.boolestespecial = false;
        this.boolestado99=false;
        this.lblEmpleado = '';
        this.lblPaispago = '';
        this.lblTipoIDEPRO = '';
        this.lblTipoIdCre = '';
        this.lblTipoPRO = '';
        this.lblTipocom = '';
        this.lblareadep = '';
        this.lblsercomnom = '';
        this.detallereembolso = {
          ENCREE_LOTE: '',
          COM_CODIGO: '01',
          DETREE_LINEA: 0,
          ENCFACPRO_NUMERO: '',
          DETREE_IDEPRO: '',
          DETREE_SERIEEST: '',
          DETREE_SERIEPTOEMI: '',
          DETREE_SECCOMPVTA: '',
          DETREE_TIPCOMP: '',
          DETREE_TIPOIDEPRO: '',
          DETREE_FECHAEMISION: new Date(),
          DETREE_AUTORIZACION: '',
          DETREE_BASECERO: '0.00',
          DETREE_BASEIVA: '0.00',
          DETREE_BASENOIVA: '0.00',
          DETREE_PORCENTAJEIVA: Number(this.iva).toFixed(2),
          DETREE_MONTOICE: '0.00',
          DETREE_MONTOIVA: '0.00',
          DETREE_BASEIMPEXEREEMB: '0.00',
          DETREE_PAISPAGO: '593',
          DETREE_PROTIPO: '',
          DETREE_EMPLEADO: '',
          DETREE_TIPGAS: '',
          DETREE_DESCRIPCION: '',
          DETREE_AREA: '',
          DETREE_CENTROC: '',
          DETREE_ESTADO: '',
          SERCOM_CODIGO: '',
          DETREE_IDCRETRI: '',
          DETREE_USUAPRUEBA: '',
          DETREE_FECHAAUTORIZA: '',
          DETREE_USUVALIDA: '',
          TARIIVA_CODIGO: this.tariivacodigo
        }
        this.selecttipogasto = { codigo: '', name: '' }
        this.selecttipocest = { codigo: 'P', name: 'Pendiente' }
        document.getElementById('DETREE_TIPOIDEPRO').focus();
      }
      return;
    }
    if (params === 'eliminar') {
      this.eliminardet()
      return;
    }
    const indi = this.detreembolso.indexOf(params.object);
    console.log('indi', indi)
    this.detreembolso.map(variable => {
      if (this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefreemb.objeto].getFocusedCell().column.colId === 'SELECCIONADO') {

        if (

          variable.DETREE_LINEA === params.object.DETREE_LINEA

        ) {
          if (variable.DETREE_ESTADO !== 'O') {
            console.log('>>>1')
            variable.SELECCIONADO = true;
            this.aggrid.refreshaggridindexSNMVSCROLL(this.detreembolso, this.defaultColDefreemb.objeto, indi)
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefreemb.objeto].setFocusedCell(indi, 'SELECCIONADO');
          } else {
            variable.SELECCIONADO = false;
            console.log('>>>2')
            this.aggrid.refreshaggridindexSNMVSCROLL(this.detreembolso, this.defaultColDefreemb.objeto, indi)
            this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefreemb.objeto].setFocusedCell(indi, 'SELECCIONADO');
            if (indi === 0) {
              this.aggrid.refreshaggridindex(this.detreembolso, this.defaultColDefreemb.objeto, indi - 1)
              this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefreemb.objeto].setFocusedCell(indi, 'SELECCIONADO');
            }
          }
        } else {
        }
        this.aggrid.refreshaggridindexSNMVSCROLL(this.detreembolso, this.defaultColDefreemb.objeto, indi)
        this.aggrid.init.grids[this.init.tabs[this.init.tabindex].indice + '_' + this.defaultColDefreemb.objeto].setFocusedCell(indi, 'SELECCIONADO');
      }
    });
    if (params.object === undefined || params.object === null) {
      return;
    }
    this.detallereembolso = {}
    this.detallereembolso = params.object;

    this.llenardet();
  }
  async llenardet() {
    //llenan los combos
    for (const combo of this.cmbtipogasto) {
      if (combo.name === this.detallereembolso.DETREE_TIPGAS) {
        this.selecttipogasto = { codigo: combo.codigo, name: combo.name };
      }
    }
    for (const combo of this.cmbestado) {
      if (combo.codigo === this.detallereembolso.DETREE_ESTADO) {
        this.selecttipocest = { codigo: combo.codigo, name: combo.name };
      }
    }
    if (this.selecttipocest.codigo === 'A') {
      this.boolest = true
      this.boolestado99=true
      this.boolestespecial = false;
    }
    if (this.selecttipocest.codigo === 'O' || this.selecttipocest.codigo === 'V') {
      this.boolest = true
      this.boolestado99=true
      this.boolestespecial = true;
    }
    if (this.selecttipocest.codigo === 'P') {
      this.boolest = false
      this.boolestespecial = false;
      if(this.detallereembolso.DETREE_TIPCOMP === '99'){
        this.boolestado99=true
      }else{
        this.boolestado99=false
      }
    }
    // se llenan los labels
    this.trnreembService.ercodsriTIPOIDEPRO(this.detallereembolso.DETREE_TIPOIDEPRO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblTipoIDEPRO = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    this.trnreembService.erpaispagocodsri(this.detallereembolso.DETREE_PAISPAGO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblPaispago = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    this.trnreembService.ertipcompcodsri(this.detallereembolso.DETREE_TIPCOMP).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblTipocom = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    this.trnreembService.erprotipocodsri(this.detallereembolso.DETREE_PROTIPO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblTipoPRO = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    this.comencreembolsoService.eridcreditrib(this.detallereembolso.DETREE_IDCRETRI).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblTipoIdCre = er[0].CODSRI_DESCRIPCION;
        }
      }
    });
    this.comencreembolsoService.erabrirArea(this.detallereembolso.DETREE_AREA).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblareadep = er[0].REF_NOMBRE;
        }
      }
    });
    this.comencreembolsoService.ersercom(this.detallereembolso.SERCOM_CODIGO).subscribe(er => {
      if (er !== null) {
        if (er[0] !== undefined) {
          this.lblsercomnom = er[0].SERCOM_DESCRIPCION;
        }
      }
    });

  }
  async eliminardet() {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea eliminar el reembolso ' + this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE,
      header: 'Eliminar Reembolso',
      icon: 'pi pi-exclamation-triangle',
      key: 'encreembconf',
      accept: async () => {
        try {
          if (this.detallereembolso.DETREE_LINEA === 0) {

          } else {
            await this.comencreembolsoService.eliminardetreem(this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE)
          }
        } catch (error) {
          this.messageService.add({
            key: 'encreemb',
            severity: 'error',
            summary: 'Información',
            detail: 'Ocurrio un error al eliminar el detalle del reembolso'
          });
          return;
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }
  async almacenardoc() {
    if (this.comencreembolsoService.encabezadoreembolso[this.indicador].ENCREE_LOTE !== '') {
      const usu = await this.confIniciales.obtenerPermisoUsuario(this.comencreembolsoService.usuario.identificacion);
      if (usu[0].USU_ALMACENARDOC === 1 || usu[0].USU_VISUALIZARDOC === 1) {
        this.displayDialogDocumentos = true;
      } else {
        this.messageService.add({
          key: 'encreemb',
          severity: 'info',
          summary: 'Información',
          detail: 'No tiene los permisos necesarios. Consulte con el Administrador del Sistema'
        });
      }
    }
  }

  recibirSenalesDocumentos(valor) {
    if (valor === true) {
      this.displayDialogDocumentos = false;
    }
  }
}
