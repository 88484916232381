import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { Presuencplanc } from "../coninterfaces/presuencplanc";
import { Presudetplanc } from "../coninterfaces/presudetplanc";
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class PresuplancuentasService {
  public encabezadoPresuSeleccionadoarray: Presuencplanc[] = [];

  public way: string;
  public _detpresupuestoarray: Presudetplanc[] = [];
  get detpresupuestoarray(): Presudetplanc[] {
    return this._detpresupuestoarray;
  }
  set detpresupuestoarray(value: Presudetplanc[]) {
    this._detpresupuestoarray = value;
  }
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  consultaplancdet(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/198bf3d56bbb9e00000000000000', {
      elementos: {
        p_CON_ENCPRESANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultaplancenc(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/662fcf55d76678000000', {
      elementos: {
        p_CON_ENCPRESANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarplancenc(item: Presuencplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/257e3b7d75d99e00000000000000', {
      elementos: {
        p_CON_ENCPRESANIO: item.CON_ENCPRESANIO,
        p_CON_ENCDESCRIPCION: item.CON_ENCDESCRIPCION,
        p_COM_CODIGO: item.COM_CODIGO,
        p_CON_ENCPRESCONSOLIDA: item.CON_ENCPRESCONSOLIDA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarplancenc(item: Presuencplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/299df2ab2', {
      elementos: {
        p_CON_ENCPRESANIO: item.CON_ENCPRESANIO,
        p_CON_ENCDESCRIPCION: item.CON_ENCDESCRIPCION,
        p_COM_CODIGO: item.COM_CODIGO,
        p_CON_ENCPRESCONSOLIDA: item.CON_ENCPRESCONSOLIDA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarplancdet(item: Presudetplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/299df2ab2021777444', {
      elementos: {
        p_CON_ENCPRESANIO :item.CON_ENCPRESANIO,
        p_COM_CODIGO :'01',
        p_CON_CODIGO :item.CON_CODIGO,
        p_CON_DETPRES01 :item.CON_DETPRES01,
        p_CON_DETPRES02 :item.CON_DETPRES02,
        p_CON_DETPRES03 :item.CON_DETPRES03,
        p_CON_DETPRES04 :item.CON_DETPRES04,
        p_CON_DETPRES05 :item.CON_DETPRES05,
        p_CON_DETPRES06 :item.CON_DETPRES06,
        p_CON_DETPRES07 :item.CON_DETPRES07,
        p_CON_DETPRES08 :item.CON_DETPRES08,
        p_CON_DETPRES09 :item.CON_DETPRES09,
        p_CON_DETPRES10 :item.CON_DETPRES10,
        p_CON_DETPRES11 :item.CON_DETPRES11,
        p_CON_DETPRES12 :item.CON_DETPRES12,
        p_CON_DETPRESACM :item.CON_DETPRESACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarplancdet(item: Presudetplanc) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/3ab2b4aea75d9a00000213561', {
      elementos: {
        p_CON_ENCPRESANIO :item.CON_ENCPRESANIO,
        p_COM_CODIGO :'01',
        p_CON_CODIGO :item.CON_CODIGO,
        p_CON_DETPRES01 :item.CON_DETPRES01,
        p_CON_DETPRES02 :item.CON_DETPRES02,
        p_CON_DETPRES03 :item.CON_DETPRES03,
        p_CON_DETPRES04 :item.CON_DETPRES04,
        p_CON_DETPRES05 :item.CON_DETPRES05,
        p_CON_DETPRES06 :item.CON_DETPRES06,
        p_CON_DETPRES07 :item.CON_DETPRES07,
        p_CON_DETPRES08 :item.CON_DETPRES08,
        p_CON_DETPRES09 :item.CON_DETPRES09,
        p_CON_DETPRES10 :item.CON_DETPRES10,
        p_CON_DETPRES11 :item.CON_DETPRES11,
        p_CON_DETPRES12 :item.CON_DETPRES12,
        p_CON_DETPRESACM :item.CON_DETPRESACM
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarregistro(stranio) {
    return this.http.post<any[]>(this.way + '/cont/presplanc/3ab2b4aea75d9a000000000000000', {
      elementos: {
        p_CON_ENCPRESANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  sumarAcumumulado(index){
    let item:Presudetplanc=this.detpresupuestoarray[index]
    this.detpresupuestoarray[index].CON_DETPRESACM=
    Number(
      Number(item.CON_DETPRES01)+Number(item.CON_DETPRES02)+Number(item.CON_DETPRES03)+Number(item.CON_DETPRES04)+
      Number(item.CON_DETPRES05)+Number(item.CON_DETPRES06)+Number(item.CON_DETPRES07)+Number(item.CON_DETPRES08)+
      Number(item.CON_DETPRES09)+Number(item.CON_DETPRES10)+Number(item.CON_DETPRES11)+Number(item.CON_DETPRES12)
    ).toFixed(2)
  }
  consolidarcuentas(stranio){
    return this.http.post<any[]>(this.way + '/cont/presplanc/cc5f98ac9aadb00000000', {
      elementos: {
        p_CON_ENCPRESANIO: stranio,
        p_COM_CODIGO: '01',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
