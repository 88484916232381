import { Injectable } from '@angular/core';
import { banEntifin } from "../baninterfaces/banentifin";
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { Observable } from 'rxjs';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})


export class BanmaeentifinService {
  private way: string;
  private _banmaeentifin: banEntifin[];
  get banmaeentifin(): banEntifin[] {
    return this._banmaeentifin;
  }
  set banmaeentifin(value: banEntifin[]) {
    this._banmaeentifin = value;
  }

  constructor(private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService) {
    this.way = sessionStorage.getItem('way');
  }
  getBanEntifin() {
    return this.http.post<any[]>(this.way + '/ban/banentifin/107756abbafd94000', {
      elementos: {},
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  insertarnuevo(item: banEntifin) {
    return this.http.post<any[]>(this.way + '/ban/banentifin/12bf1dbebafd94000', {
      elementos: {
        p_entfin_codigo: item.ENTFIN_CODIGO,
        p_encfin_nombre: item.ENCFIN_NOMBRE,
        p_encfin_ruc: item.ENCFIN_RUC,
        p_pais_codigo: item.PAIS_CODIGO,
        p_ref_tipo: item.REF_TIPO
        },
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  actualizardato(item: banEntifin) {
    return this.http.post<any[]>(this.way + '/ban/banentifin/299df2ab2', {
      elementos: {
        p_entfin_codigo: item.ENTFIN_CODIGO,
        p_encfin_nombre: item.ENCFIN_NOMBRE,
        p_encfin_ruc: item.ENCFIN_RUC,
        p_pais_codigo: item.PAIS_CODIGO,
        p_ref_tipo: item.REF_TIPO
      },
    },
      this.confIniciales.httpOptions()).toPromise();
  }
  eliminardato(item) {
    return this.http.post<any[]>(this.way + '/ban/banentifin/eacad2ba96abb80000000', {
      elementos: {
        p_entfin_codigo: item,

      },
    },
      this.confIniciales.httpOptions()).toPromise();
  }
}
