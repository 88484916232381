import { Component, OnInit, ViewChild } from '@angular/core';
import { BanmaeentifinService } from "../banservicios/banmaeentifin.service";
import { CellEditorPrueba } from '../../advantage/config/cell-edit-prueba';
import { PermisosService } from '../../advantage/advantageservices/permisos.service';
import { SegMaePermiso } from '../../advantage/advantageinterfaces/segmaepermiso';
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
import { AggridsaciComponent } from '../../advantage/aggridsaci/aggridsaci.component';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { banEntifin } from '../baninterfaces/banentifin';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';

@Component({
  selector: 'app-banmaeentifin',
  templateUrl: './banmaeentifin.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class BanmaeentifinComponent implements OnInit {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  btnNuevo: boolean;
  btnGuardar: boolean;
  btnBorrar: boolean;
  btnRegresar: boolean;
  //grid
  rowSelection = 'multiple';
  public frameworkComponents;
  defaultColDefBanentinfin;
  rowStyle;
  //permisos
  permisoEntifin: SegMaePermiso;
  //grilla
  detselecentifin: banEntifin;
  columnDefsBanEntifin = [
    {
      headerName: 'Código', field: 'ENTFIN_CODIGO', cellEditor: 'cellPrueba', width: 100, editable: (params) => {

        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10
        }
      }
    },
    {
      headerName: 'Nombre', field: 'ENCFIN_NOMBRE', cellEditor: 'cellPrueba', width: 200, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: false,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 200
        }
      }
    },
    {
      headerName: 'Ruc', field: 'ENCFIN_RUC', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
    {
      headerName: 'País', field: 'PAIS_CODIGO', cellEditor: 'cellPrueba', width: 150, editable: (params) => {
        return true;
      },
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 35,
          opcionbuscar: true
        }
      }
    },
  ];
  constructor(public banmaeentifinService: BanmaeentifinService, private permisosService: PermisosService,
    private init: NuevoComponentService, private messageService: MessageService, private auditoriagral: AuditoriagralService ) {
    this.agTable();
  }
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
    this.defaultColDefBanentinfin = {
      editable: true,
      width: 130,
      objeto: 'maeBanEntfin'
    };

  }
  ngOnInit(): void {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    this.detselecentifin = {}
    this.getPermisos()
    this.mostrarDatos();
  }
  async getPermisos() {
    console.log(this.init.tabs[this.init.tabindex - 1]);
    console.log(this.init.tabs[this.init.tabindex - 1].component);
    try {
      const data = await this.permisosService.getPermisosFrm(this.init.tabs[this.init.tabindex - 1].component);
      if (data !== null) {
        if (data[0] !== undefined) {
          this.permisoEntifin = data[0];
        } else {
          this.permisoEntifin = null;
        }
      } else {
        this.permisoEntifin = null;
      }
    } catch (e) {
      this.permisoEntifin = null;
      this.messageService.add({
        key: 'maeentifin',
        severity: 'error',
        summary: 'Información',
        detail: 'No se pudo obtener los permisos del formulario.'
      });
    }
  }
  manejarSenales(evento) {
    if (evento === 'Nuevo') {
      if (this.permisoEntifin.PERTODO === 1) {
        this.nuevo();
      } else {
        if (this.permisoEntifin.PERINSERCION === 1) {
          this.nuevo();
        } else {
          this.messageService.add({
            key: 'maeentifin',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de inserción, acción denegada.'
          });
        }
      }
    }
    if (evento === 'Borrar') {
      if (this.permisoEntifin.PERTODO === 1) {
        this.eliminar();
      } else {
        if (this.permisoEntifin.PERELIMACION === 1) {
          this.eliminar();
        } else {
          this.messageService.add({
            key: 'maeentifin',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de rliminación, acción denegada.'
          });
        }
      }
    }
    if (evento === 'Guardar') {
      if (this.permisoEntifin.PERTODO === 1) {
        this.guardar();
      } else {
        if (this.permisoEntifin.PERACTUALIZACION === 1) {
          this.guardar();
        } else {
          this.messageService.add({
            key: 'maeentifin',
            severity: 'error',
            summary: 'Información',
            detail: 'El usuario no tiene permisos de actualización, acción denegada.'
          });
        }
      }
    }
    if (evento === 'Cancelar') {
      this.mostrarDatos();
    }
    if (evento === 'Salir') {
      this.init.tabs.splice(this.init.tabindex, 1);
    }
  }
  async eliminar(){
    try {
      console.log(this.detselecentifin.ENTFIN_CODIGO)
      if(this.detselecentifin.NUEVO === true){
        this.banmaeentifinService.banmaeentifin =  this.banmaeentifinService.banmaeentifin.filter((val, j) => j !==
        this.banmaeentifinService.banmaeentifin.indexOf(this.detselecentifin));
        this.aggrid.refreshaggrid(this.banmaeentifinService.banmaeentifin, this.defaultColDefBanentinfin.objeto);
      }else{
        await this.banmaeentifinService.eliminardato(this.detselecentifin.ENTFIN_CODIGO);
        await this.mostrarDatos()
          this.messageService.add({
            key: 'maeentifin',
            severity: 'success',
            summary: 'Información',
            detail: 'El registro se eliminó correctamente'
          });
          this.auditoriagral.registrarAuditoria('BAN_MAEENTFIN', String(this.detselecentifin.ENTFIN_CODIGO), 'E', '',
          '01', '', '', '', '').subscribe(() => {
        });  
      }
      
    } catch (error) {
      this.messageService.add({
        key: 'maeentifin',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al eliminar'
      });
    }
  }
  nuevo() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
    const entifin: banEntifin = {
      ENTFIN_CODIGO: '',
      ENCFIN_NOMBRE: '',
      ENCFIN_RUC: '',
      PAIS_CODIGO: '',
      REF_TIPO: 'P',
      NUEVO: true,
    };
    this.banmaeentifinService.banmaeentifin.push(entifin);
    this.aggrid.refreshaggrid(this.banmaeentifinService.banmaeentifin, this.defaultColDefBanentinfin.objeto);
    this.detselecentifin = this.banmaeentifinService.banmaeentifin[this.banmaeentifinService.banmaeentifin.length - 1]
  }
  guardar() {
    if (this.detselecentifin === null || this.detselecentifin === undefined || this.detselecentifin === '') {
      this.messageService.add({
        key: 'maeentifin',
        severity: 'error',
        summary: 'Información',
        detail: 'Seleccione un registro'
      });
    } else {
      //console.log('1')
      if (this.detselecentifin.ENTFIN_CODIGO === undefined || this.detselecentifin.ENTFIN_CODIGO === null || this.detselecentifin.ENTFIN_CODIGO === '') {
        this.messageService.add({
          key: 'maeentifin',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo código no puede estar vacío'
        });
        return;
      } else if (this.detselecentifin.ENCFIN_NOMBRE === undefined || this.detselecentifin.ENCFIN_NOMBRE === null || this.detselecentifin.ENCFIN_NOMBRE === '') {
        this.messageService.add({
          key: 'maeentifin',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo nombre no puede estar vacío'
        });
        return;
      } else if (this.detselecentifin.ENCFIN_RUC === undefined || this.detselecentifin.ENCFIN_RUC === null || this.detselecentifin.ENCFIN_RUC === '') {
        this.messageService.add({
          key: 'maeentifin',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo ruc no puede estar vacío'
        });
        return;
      } else if (this.detselecentifin.PAIS_CODIGO === undefined || this.detselecentifin.PAIS_CODIGO === null || this.detselecentifin.PAIS_CODIGO === '') {
        this.messageService.add({
          key: 'maeentifin',
          severity: 'error',
          summary: 'Información',
          detail: 'El campo país no puede estar vacío'
        });
        return;
      } else {
        this.finguardar();
      }
    }
  }
  async finguardar() {
    try {
      if(this.detselecentifin.NUEVO === true){
        await this.banmaeentifinService.insertarnuevo(this.detselecentifin);
        await this.mostrarDatos()
        this.messageService.add({
          key: 'maeentifin',
          severity: 'success',
          summary: 'Información',
          detail: 'El registro se insertó correctamente'
        });
        this.auditoriagral.registrarAuditoria('BAN_MAEENTFIN', String(this.detselecentifin.ENTFIN_CODIGO), 'I', '',
                '01', '', '', '', '').subscribe(() => {
              });
      }else{
        await this.banmaeentifinService.actualizardato(this.detselecentifin);
        await this.mostrarDatos()
        this.messageService.add({
          key: 'maeentifin',
          severity: 'success',
          summary: 'Información',
          detail: 'El registro se actualizó correctamente'
        });
        this.auditoriagral.registrarAuditoria('BAN_MAEENTFIN', String(this.detselecentifin.ENTFIN_CODIGO), 'A', '',
        '01', '', '', '', '').subscribe(() => {
      });
      }
    } catch (error) {
      this.messageService.add({
        key: 'maeentifin',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al guardar'
      });
    }
  }
  async mostrarDatos() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnBorrar = false;
    this.btnRegresar = true;
    try {
      const data = await this.banmaeentifinService.getBanEntifin()
      this.banmaeentifinService.banmaeentifin = data
      if (this.banmaeentifinService.banmaeentifin.length > 0) {
        for (const dato of this.banmaeentifinService.banmaeentifin) {
          dato.NUEVO = false;
        }
        this.aggrid.refreshaggrid(this.banmaeentifinService.banmaeentifin, this.defaultColDefBanentinfin.objeto);
      }
    } catch (error) {
      this.messageService.add({
        key: 'maeentifin',
        severity: 'error',
        summary: 'Información',
        detail: 'Ocurrio un error al consultar los datos.'
      });
    }

  }

  selectedBanCaja(params) {
    if (params.object === undefined) {
      return;
    }
    this.detselecentifin = params.object;
  }
  cambio(evnto) {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnBorrar = true;
  }

}
