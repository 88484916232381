import {Component, EventEmitter, Input, Output, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CellEditorPrueba} from '../../advantage/config/cell-edit-prueba';
import {AggridsaciComponent} from '../../advantage/aggridsaci/aggridsaci.component';
import {ComtrndistribucionccService} from '../comservicios/comtrndistribucioncc.service';
import {ComTrndistribucioncc} from '../cominterfaces/comtrndistribucioncc';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuditoriagralService} from '../../advantage/advantageservices/auditoriagral.service';
import {NuevoComponentService} from '../../servicios/nuevo-component.service';
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';//CDPJ
@Component({
  selector: 'app-comtrndistribucioncc',
  templateUrl: './comtrndistribucioncc.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class ComtrndistribucionccComponent implements OnInit, OnChanges {
  // @ts-ignore
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;
  
  @Input() strnumfac: any;
  @Input() detalle: any;
  @Input() decimalesTOTALDetFacPro: any;
  //GSRF
  @Output() salir = new EventEmitter<boolean>();
  
  botonNuevo: boolean;
  botonGuardar: boolean;
  botonBorrar: boolean;
  botonRegresar: boolean;
  oculto: boolean;
  
  largo: string;
  
  strCodigo: string;
  strDescripcion: string;
  strImporte: string;
  
  distriCCSelected: ComTrndistribucioncc;
  
  public frameworkComponents;
  rowStyle;
  desCodigo: boolean;
  defaultColDefDistri;
  rowSelection = 'multiple';
  columnDefsDistri = [
    {
      headerName: 'Cuenta', field: 'CON_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'CCostos', field: 'CEN_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 35,
            opcionbuscar: true
          }
        }
    },
    {
      headerName: 'Nombre', field: 'CEN_NOMBRE', width: 200, cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 45
          }
        }
    },
    {
      headerName: '% ', field: 'TRNDIS_PORCE', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    {
      headerName: 'Valor', field: 'TRNDIS_VALOR', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 2,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        }
    },
    /*{
      headerName: 'Tipo Gasto', field: 'TIPGAS_CODIGO', cellEditor: 'cellPrueba',
      cellEditorParams:
        {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: 0,
            obligatorio: true,
            fecha: false,
            tamanocelda: 10
          }
        }
    }*/
    {
      headerName: 'T.Gasto', field: 'TIPGAS_CODIGO', cellEditor: 'cellPrueba', editable: (params) => {
        return this.comprobarTipoGasto(params.data);
      },
      // , editable: true,
      cellEditorParams:  {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 45
        }
      }

    },
  
  ];
  
  constructor(public distriCCService: ComtrndistribucionccService, private datePipe: DatePipe,
              private messageService: MessageService, private auditoriagral: AuditoriagralService,
              private confirmationService: ConfirmationService, private init: NuevoComponentService,
              private confIniciales: ConfInicialesService) {
    this.agTable();
  }
  
  agTable() {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return {background: '#D6EAF8'};
      }
    };
    this.defaultColDefDistri = {
      editable: true,
      width: 100,
      objeto: 'districc'
    };
  }
  
  
  ngOnInit() {
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = true;
    this.oculto = true;
    
    this.largo = '500';
    //CDPJ
    this.confIniciales.getDecimales('frmCON_ENCASI_L').subscribe((dec) => {
      if (dec !== null) {
        dec.map((decimales) => {
          if (decimales.NUMDEC_TIPDOC === 'detdistri') {
            if (decimales.NUMDEC_CAMPO === 'VALOR') {
              this.distriCCService.decimalesvalorDet = Number(decimales.NUMDEC_NUMDEC);
            }
          }
        });
      }
        this.columnDefsDistri[4].cellEditorParams = {
          values: {
            mayusculas: false,
            numeros: true,
            alphabetic: true,
            saltoslinea: 1,
            newrow: false,
            numerodecimales: this.distriCCService.decimalesvalorDet,
            obligatorio: true,
            fecha: false,
            tamanocelda: 32,
            tienecalculos: true
          }
        };
        this.aggrid.refreshColumnDefs();
      })
    //CDPJ
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const e in changes) {
      if (e === 'detalle' && this.detalle !== null && this.detalle !== undefined) {
        this.strCodigo = this.detalle.DETFACPRO_CODIGO;
        this.strDescripcion = this.detalle.DETFACPRO_DESCRIPCION;
        this.strImporte = Number(this.detalle.DETFACPRO_TOTAL).toFixed(this.decimalesTOTALDetFacPro);
        this.distriCCService.dblValor = Number(this.strImporte);
        this.distriCCService.decimalesTOTALDetFacPro = this.decimalesTOTALDetFacPro;
        this.confIniciales.getDecimales('frmCON_ENCASI_L').subscribe((dec) => {
          if (dec !== null) {
            dec.map((decimales) => {
              if (decimales.NUMDEC_TIPDOC === 'detdistri') {
                if (decimales.NUMDEC_CAMPO === 'VALOR') {
                  this.distriCCService.decimalesvalorDet = Number(decimales.NUMDEC_NUMDEC);
                }
              }
            });
          }
          console.log('this.distriCCService.decimalesvalorDet',this.distriCCService.decimalesvalorDet)
            this.columnDefsDistri[4].cellEditorParams = {
              values: {
                mayusculas: false,
                numeros: true,
                alphabetic: false,
                saltoslinea: 1,
                newrow: false,
                numerodecimales: this.distriCCService.decimalesvalorDet,
                obligatorio: true,
                fecha: false,
                tamanocelda: 32,
                tienecalculos: true
              }
            };
            this.aggrid.refreshColumnDefs();
          })
        this.mostrarDistri();
      }
    }
  }
  
  manejarSenales(valor) {
    
    if (valor === 'Nuevo') {
      this.nuevo();
    }
    
    if (valor === 'Guardar') {
      this.guardar();
    }
    
    if (valor === 'Borrar') {
      this.eliminar();
    }
    
    if (valor === 'Cancelar') {
      this.cancelar();
    }
  
    if (valor === 'Salir') {
      //this.init.tabs.splice(this.init.tabindex, 1);
       //GSRF
      //CDPJ
      if(this.validartotalimporte() === true){
        this.salir.emit(true);
      }
       
      //CDPJ
    }
    
  }
   //CDPJ
   validartotalimporte(){
    let booltototal=true;
    let intIndiceLista = 0;
    let item: ComTrndistribucioncc;
    let dblTotalImporte = 0;
    if(this.distriCCService.distribucionCC.length === 0){
      booltototal=true;
      return booltototal;
    }
    for (intIndiceLista = 0; intIndiceLista < this.distriCCService.distribucionCC.length; intIndiceLista++) {
      item = this.distriCCService.distribucionCC[intIndiceLista];
      dblTotalImporte = dblTotalImporte + Number(item.TRNDIS_VALOR);
      dblTotalImporte=Number(dblTotalImporte.toFixed(this.distriCCService.decimalesvalorDet))
    }    
    console.log('dblTotalImporte',dblTotalImporte);
    console.log('this.distriCCService.dblValor',this.distriCCService.dblValor)
    if (dblTotalImporte !== Number(this.distriCCService.dblValor.toFixed(this.distriCCService.decimalesvalorDet)) && dblTotalImporte !== 0) {
      this.messageService.add({
        key: 'distriCC',
        severity: 'warn',
        summary: 'Información',
        detail: 'El total de distribución no es igual al importe.'
      });
      booltototal=false;
    }
    return booltototal;
  }
  //CDPJ
  seleccionDistriCC(params) {
    if (params.object !== undefined) {
      this.distriCCSelected = params.object;
    }
  }
  
  listarDatosAutomatica() {
    if (this.botonGuardar === true) {
      this.distriCCService.distribucionCC = [];
      this.distriCCService.listarAutomatica(this.strnumfac, this.detalle.DETFACPRO_LINEA).subscribe(data => {
        let item: ComTrndistribucioncc;
        for (const rs of data) {
          item = rs;
          item.TRNDIS_LINEA = 0;
          this.distriCCService.distribucionCC.push(item);
        }
        this.aggrid.refreshaggrid(this.distriCCService.distribucionCC, this.defaultColDefDistri.objeto);
        this.distriCCService.calcularTotal();
        this.botonesmodificar();
      });
    } else {
      this.messageService.add({
        key: 'distriCC',
        severity: 'warn',
        summary: 'Información',
        detail: 'Error, Guarde las modificaciones'
      });
    }
    
  }
  
  cambio(cambio) {
    if (cambio === true) {
      this.botonGuardar = false;
      this.botonRegresar = false;
      this.botonNuevo = false;
    }
  }
  
  mostrarDistri() {
    this.distriCCService.listardatos(this.strnumfac, this.detalle.DETFACPRO_LINEA).subscribe(data => {
      this.distriCCService.distribucionCC = data;
      for(const data of this.distriCCService.distribucionCC){
        data.LINEA=data.TRNDIS_LINEA;
        this.distriCCSelected=data;
      }
      this.distriCCService.calcularTotal();
    });
  }
  
  nuevo() {
    const districc: ComTrndistribucioncc = {
      ENCFACPRO_NUMERO: this.strnumfac,
      DETFACPRO_LINEA: this.detalle.DETFACPRO_LINEA,
      COM_CODIGO: '01',
      CEN_CODIGO: '',
      CEN_NOMBRE: '',
      TRNDIS_VALOR: '0.00',
      TRNDIS_PORCE: '0.00',
      CON_CODIGO: '',
      TRNDIS_LINEA: 0,
      TIPGAS_CODIGO: '',
      CONTROL: '',
      LINEA:0
    };
    const indi = this.distriCCService.distribucionCC.indexOf(this.distriCCSelected);
    
    if (indi === -1) {
      districc.CLAVE = '1' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.distriCCService.distribucionCC.push(districc);
      this.distriCCSelected = districc;
      this.aggrid.refreshaggrid(this.distriCCService.distribucionCC, this.defaultColDefDistri.objeto);
      this.aggrid.gridApi.setFocusedCell(0, 'CON_CODIGO');
    } else {
      districc.CLAVE = (indi + 1) + '' + this.datePipe.transform(new Date(), 'ddMMyyyyHHmmss');
      this.distriCCService.distribucionCC.splice(indi + 1, 0, districc);
      this.distriCCSelected = districc;
      this.aggrid.refreshaggridindex(this.distriCCService.distribucionCC, this.defaultColDefDistri.objeto, indi);
      this.aggrid.gridApi.setFocusedCell(indi + 1, 'CON_CODIGO');
    }
    
    this.botonesmodificar();
  }
  
  
  guardar() {
    this.sumarTotalImporte();
  }
  
  eliminar() {
    // const intIndiceFila = this.distriCCService.distribucionCC.findIndex(distri =>
    //   distri.CLAVE === this.distriCCSelected.CLAVE);
    let intIndiceFila=0
    if(this.distriCCSelected.LINEA === 0){
        intIndiceFila = this.distriCCService.distribucionCC.findIndex(distri =>
          distri.CLAVE === this.distriCCSelected.CLAVE);
    }else{
      intIndiceFila = this.distriCCService.distribucionCC.findIndex(distri =>
        distri.TRNDIS_LINEA === this.distriCCSelected.TRNDIS_LINEA);
    }
    let item: ComTrndistribucioncc;
    
    if (intIndiceFila !== -1) {
      item = this.distriCCService.distribucionCC[intIndiceFila];
    } else {
      this.messageService.add({
        key: 'distriCC',
        severity: 'error',
        summary: 'Error',
        detail: '! Seleccione una fila.... !'
      });
      return;
    }
    
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar el registro?',
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'distriCCconf',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        if(this.distriCCSelected.LINEA === 0){
          this.distriCCService.distribucionCC.splice(intIndiceFila, 1);
          this.aggrid.refreshaggrid(this.distriCCService.distribucionCC, this.defaultColDefDistri.objeto);
       }else{
        this.distriCCService.EliminarDistribucionCC(item).subscribe(data => {
          this.auditoriagral.registrarAuditoria('COM_TRNDISTRIBUCIONCC', item.ENCFACPRO_NUMERO + '/' + item.DETFACPRO_LINEA + '/' + item.CEN_CODIGO, 'E',
            '', '01', '', '', '', '').subscribe();
          
          this.distriCCService.distribucionCC.splice(intIndiceFila,
            1);
          this.aggrid.refreshaggrid(this.distriCCService.distribucionCC, this.defaultColDefDistri.objeto);
        })
       }
       if(this.distriCCService.distribucionCC.length>0){
         
        this.distriCCSelected=this.distriCCService.distribucionCC[intIndiceFila-1];
      };
      },
      reject: () => {
      }
    });
  }
  
  cancelar() {
    this.mostrarDistri();
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }
  
  
  botonesmodificar() {
    this.botonNuevo = false;
    this.botonGuardar = false;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }
  
  async sumarTotalImporte() {
    let intIndiceLista = 0;
    let item: ComTrndistribucioncc;
    let dblTotalImporte = 0;
    
    for (intIndiceLista = 0; intIndiceLista < this.distriCCService.distribucionCC.length; intIndiceLista++) {
      item = this.distriCCService.distribucionCC[intIndiceLista];
      dblTotalImporte = dblTotalImporte + Number(item.TRNDIS_VALOR);
      dblTotalImporte=Number(dblTotalImporte.toFixed(this.distriCCService.decimalesvalorDet))
    }
    console.log('dblTotalImporte',dblTotalImporte);
    console.log('this.distriCCService.dblValor',this.distriCCService.dblValor)
    if (dblTotalImporte !== this.distriCCService.dblValor && dblTotalImporte !== 0) {
      this.messageService.add({
        key: 'distriCC',
        severity: 'warn',
        summary: 'Información',
        detail: 'El total de distribución no es igual al importe.'
      });
    } else {
      await this.guardarDistribucion();
    }
  }
  
  async guardarDistribucion() {
    let errores = 0;
    let intIndiceLista = 0;
    let item: ComTrndistribucioncc;
    
    
    for (intIndiceLista = 0; intIndiceLista < this.distriCCService.distribucionCC.length; intIndiceLista++) {
      item = this.distriCCService.distribucionCC[intIndiceLista];
      if (item.CONTROL === '') { // Inserta un nuevo registro
        if (this.verificarDatos() === true) {
          await this.distriCCService.InsertarDistribucionCC(item).toPromise().catch(e=>{
            this.messageService.add({
              key: 'encfacpro',
              severity: 'error',
              summary: 'Información',
              detail: 'Error al guardar la distribución'
            });
          })
          //this.distriCCService.InsertarDistribucionCC(item).subscribe(data => {
            item.CONTROL = '1';
            // registrarAuditoria
            this.auditoriagral.registrarAuditoria('COM_TRNDISTRIBUCIONCC', item.ENCFACPRO_NUMERO + '/' + item.DETFACPRO_LINEA + '/' + item.CEN_CODIGO, 'I',
              '', '01', '', '', '', '').subscribe();
          //});
        } else {
          errores++;
        }
      } else { // fin inserta información
        if (item.CONTROL !== '') { // actualiza información
          if (this.verificarDatos() === true) {
            await  this.distriCCService.ActualDistribucionCC(item).toPromise().catch(e=>{
              this.messageService.add({
                key: 'encfacpro',
                severity: 'error',
                summary: 'Información',
                detail: 'Error al actualizar la distribución'
              });
            })
            //this.distriCCService.ActualDistribucionCC(item).subscribe(data => {
              this.auditoriagral.registrarAuditoria('COM_TRNDISTRIBUCIONCC', item.ENCFACPRO_NUMERO + '/' + item.DETFACPRO_LINEA + '/' + item.CEN_CODIGO, 'A',
                '', '01', '', '', '', '').subscribe();
            //});
          } else {
            errores++;
          }
        }
      } // fin actualiza información
    }
    
    setTimeout(() => {
      if (errores > 0) {
        this.messageService.add({
          key: 'encfacpro',
          severity: 'error',
          summary: 'Información',
          detail: 'Error al guardar la distribución'
        });
      } else {
        this.messageService.add({
          key: 'distriCC',
          severity: 'success',
          summary: 'Información',
          detail: 'Distribución guardada correctamente'
        });
      }
      this.cancelar()
    }, 500);
    
    this.botonNuevo = false;
    this.botonGuardar = true;
    this.botonBorrar = false;
    this.botonRegresar = false;
  }
  
  
  verificarDatos(): boolean {
    let intIndiceLista = 0;
    let item: ComTrndistribucioncc;
    
    for (intIndiceLista = 0; intIndiceLista < this.distriCCService.distribucionCC.length; intIndiceLista++) {
      item = this.distriCCService.distribucionCC[intIndiceLista];
      
      if (item.CEN_CODIGO === '') {
        this.messageService.add({
          key: 'distriCC',
          severity: 'error',
          summary: 'Error',
          detail: 'Código del centro de costos es incorrecto.'
        });
        return false;
      }
    }
    return true;
  }
  
  comprobarTipoGasto(districc) {
    if (districc.BOLTIPOGASTO === true) {
      return true;
    } else {
      return false;
    }
  }
  
  comprobarTipoGastocell(districc) {
    if (districc.BOLTIPOGASTO === true) {
      return {
        values: {
          mayusculas: false,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: true
        }
      };
    } else {
      return {
        values: {
          mayusculas: false,
          numeros: false,
          alphabetic: false,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: false,
          fecha: false,
          clave: false,
          tamanocelda: 32,
          opcionbuscar: false
        }
      };
    }
  }
}
