import {
  Component, OnInit, Input, ComponentFactoryResolver, ViewChild, ViewContainerRef, Output, EventEmitter, OnChanges, SimpleChanges, Type
} from '@angular/core';
import { MaestroComponent } from '../pdv/maestro/maestro.component';
import { PdvprincipalComponent } from '../pdv/pdvprincipal/pdvprincipal.component';
import { InvmaebodegaComponent } from '../inv/invmaebodega/invmaebodega.component';
import { InvmaearticuloComponent } from '../inv/invmaearticulo/invmaearticulo.component';
import { InvmaegruposComponent } from '../inv/invmaegrupos/invmaegrupos.component';
import { InvmaeunidadesComponent } from '../inv/invmaeunidades/invmaeunidades.component';
import { InventradaComponent } from '../inv/inventrada/inventrada.component';
import { Router } from '@angular/router';
import { MantenimientoReportesComponent } from '../mantenimiento-reportes/mantenimiento-reportes.component';
import { ReportesComponent } from '../reportes/reportes.component';
import { ReportesService } from '../servicios/reporte.service';
import { InvsalidaComponent } from '../inv/invsalida/invsalida.component';
import { VenmaeclienteComponent } from '../ven/venmaecliente/venmaecliente.component';
import { InvtomafisicaComponent } from '../inv/invtomafisica/invtomafisica.component';
import { BancierreCajaComponent } from '../ban/bancierre-caja/bancierre-caja.component';
import { PedprincipalComponent } from '../ven/pedido/pedprincipal/pedprincipal.component';
import { InvprrecalkardexComponent } from '../inv/invprrecalkardex/invprrecalkardex.component';
import { InvcontdocumentosComponent } from '../inv/invcontdocumentos/invcontdocumentos.component';
import { VenmaeciudadComponent } from '../ven/venmaeciudad/venmaeciudad.component';
import { VenmaedescuentosComponent } from '../ven/venmaedescuentos/venmaedescuentos.component';
import { VenmaegruposComponent } from '../ven/venmaegrupos/venmaegrupos.component';
import { VenmaeserviciosComponent } from '../ven/venmaeservicios/venmaeservicios.component';
import { VenmaetransportistasComponent } from '../ven/venmaetransportistas/venmaetransportistas.component';
import { VenmaepromocionComponent } from '../ven/venmaepromocion/venmaepromocion.component';
import { CommaegrupoComponent } from '../com/commaegrupo/commaegrupo.component';
import { EletrndocumentoComponent } from '../ven/eletrndocumento/eletrndocumento.component';
import { CommaeserviciosComponent } from '../com/commaeservicio/commaeservicio.component';
import { CommaecargosComponent } from '../com/commaecargos/commaecargos.component';
import { CxcbancosClienteComponent } from '../cxc/cxcbancos-cliente/cxcbancos-cliente.component';
import { VenmaevendedorComponent } from '../ven/venmaevendedor/venmaevendedor.component';
import { VenmaepryComponent } from '../ven/venmaepry/venmaepry.component';
import { CxccomisionComponent } from '../cxc/cxccomision/cxccomision.component';
import { VenencfacComponent } from '../ven/venencfac/venencfac.component';
import { CxcmaeretencionComponent } from '../cxc/cxcmaeretencion/cxcmaeretencion.component';
import { CxcformaPagoComponent } from '../cxc/cxcforma-pago/cxcforma-pago.component';
import { CxctarjetaCreditoComponent } from '../cxc/cxctarjeta-credito/cxctarjeta-credito.component';
import { BanCajaComponent } from '../ban/ban-caja/ban-caja.component';
import { VenencpedComponent } from '../ven/venencped/venencped.component';
import { BanmaebanComponent } from '../ban/banmaeban/banmaeban.component';
import { CommaeproveedorComponent } from '../com/commaeproveedor/commaeproveedor.component';
import { ConstruccionComponent } from '../advantage/construccion/construccion.component';
import { ConfInicialesService } from '../servicios/conf-iniciales.service';
import { VenencguiaRemisionComponent } from '../ven/venencguia-remision/venencguia-remision.component';
import { ConmaecenComponent } from '../cont/conmaecen/conmaecen.component';
import { ConmaetipoDiarioComponent } from '../cont/conmaetipo-diario/conmaetipo-diario.component';
import { ConmaeconComponent } from '../cont/conmaecon/conmaecon.component';
import { UtilitariosService } from '../advantage/advantageservices/utilitarios.service';
import { ComencordcomComponent } from '../com/comencordcom/comencordcom.component';
import { InvrecalcularcostopmdComponent } from '../inv/invrecalcularcostopmd/invrecalcularcostopmd.component';
import { InvactpreciosComponent } from '../inv/invactprecios/invactprecios.component';
import { CxcenccuotaComponent } from '../ven/cxcenccuota/cxcenccuota.component';
import { ComnotrecComponent } from '../com/comnotrec/comnotrec.component';
import { VenencdevfacComponent } from '../ven/venencdevfac/venencdevfac.component';
import { InvmaeloteComponent } from '../ven/invmaelote/invmaelote.component';
import { ComencfacproComponent } from '../com/comencfacpro/comencfacpro.component';
import { ComencdevfacComponent } from '../com/comencdevfac/comencdevfac.component';
import { AnexmaecodsriComponent } from '../anex/anexmaecodsri/anexmaecodsri.component';
import { CxccarteraactivaComponent } from '../cxc/cxccarteraactiva/cxccarteraactiva.component';
import { CxctrnpagoretComponent } from '../cxc/cxctrnpagoret/cxctrnpagoret.component';
import { CxptrnpagodocumentoComponent } from '../com/cxptrnpagodocumento/cxptrnpagodocumento.component';
import { ConcontabilizadocComponent } from '../cont/concontabilizadoc/concontabilizadoc.component';
import { SacimaeperiodoComponent } from '../cont/sacimaeperiodo/sacimaeperiodo.component';
import { ConcierrecontableComponent } from '../cont/concierrecontable/concierrecontable.component';
import { BanconcilbancariaComponent } from '../ban/banconcilbancaria/banconcilbancaria.component';
import { ConencasilComponent } from '../cont/conencasil/conencasil.component';
import { BantrnbanComponent } from '../ban/bantrnban/bantrnban.component';
import { CxptrncobroComponent } from '../cxp/cxptrncobro/cxptrncobro.component';
import { ComliquidcomComponent } from '../com/comliquidcom/comliquidcom.component';
import { BancashmanagementComponent } from '../ban/bancashmanagement/bancashmanagement.component';
import { SacimaeauditoriaComponent } from '../advantage/sacimaeauditoria/sacimaeauditoria.component';
import { BanbandejapagosComponent } from '../ban/banbandejapagos/banbandejapagos.component';
import { BantrntarjetaComponent } from '../ban/bantrntarjeta/bantrntarjeta.component';
import { SegmaecompaniaComponent } from '../seg/segmaecompania/segmaecompania.component';
import { BantrncajaComponent } from '../ban/bantrncaja/bantrncaja.component';
import { InvenctransferComponent } from '../inv/invenctransfer/invenctransfer.component';
import { SegmaeusuarioComponent } from '../seg/segmaeusuario/segmaeusuario.component';
import { NomcfgrgenComponent } from '../nom/nomcfgrgen/nomcfgrgen.component';
import { NommaedepartamentoComponent } from '../nom/nommaedepartamento/nommaedepartamento.component';
import { NommaeperiodoComponent } from '../nom/nommaeperiodo/nommaeperiodo.component';
import { NommaetiporolComponent } from '../nom/nommaetiporol/nommaetiporol.component';
import { NommaetablitaretComponent } from '../nom/nommaetablitaret/nommaetablitaret.component';
import { SegmaeautorizacionComponent } from '../seg/segmaeautorizacion/segmaeautorizacion.component';
import { SegmaeorganizacionComponent } from '../seg/segmaeorganizacion/segmaeorganizacion.component';
import { SacimaeconfiguracionComponent } from '../seg/sacimaeconfiguracion/sacimaeconfiguracion.component';
import { SegmaeperfilComponent } from '../seg/segmaeperfil/segmaeperfil.component';
import { CxpenccuotaComponent } from '../cxp/cxpenccuota/cxpenccuota.component';
import { SegconformacionComponent } from '../seg/segconformacion/segconformacion.component';
import { SegmaetiprefComponent } from '../seg/segmaetipref/segmaetipref.component';
import { InvutilexistenciasComponent } from '../inv/invutilexistencias/invutilexistencias.component';
import { InvnumtransferenciaComponent } from '../inv/invnumtransferencia/invnumtransferencia.component';
import { InvtrnajustesComponent } from '../inv/invtrnajustes/invtrnajustes.component';
import { SegmaenumeraciongComponent } from '../seg/segmaenumeraciong/segmaenumeraciong.component';
import { SacimaenumeracionCComponent } from '../seg/sacimaenumeracion-c/sacimaenumeracion-c.component';
import { SegcambioclaveComponent } from '../seg/segcambioclave/segcambioclave.component';
import { SegmaereferenciaComponent } from '../seg/segmaereferencia/segmaereferencia.component';
import { SegmaereppantallaComponent } from '../seg/segmaereppantalla/segmaereppantalla.component';
import { VenmaeprovinciasComponent } from '../ven/venmaeprovincias/venmaeprovincias.component';
import { ConasidescuadreComponent } from '../cont/conasidescuadre/conasidescuadre.component';
import { CondlginicializarcontadoresComponent } from '../cont/condlginicializarcontadores/condlginicializarcontadores.component';
import { NommaerubroComponent } from '../nom/nommaerubro/nommaerubro.component';
import { FirmardocumentoComponent } from '../ven/firmardocumento/firmardocumento.component';
import { InvtrnreservasComponent } from '../inv/invtrnreservas/invtrnreservas.component';
import { AnexgenerararchivosComponent } from '../anex/anexgenerararchivos/anexgenerararchivos.component';
import { AnexgenerarxmlComponent } from '../anex/anexgenerarxml/anexgenerarxml.component';
import { SacimaeempresaComponent } from '../seg/sacimaeempresa/sacimaeempresa.component';
import { NomencrolgenecabeComponent } from '../nom/nomencrolgenecabe/nomencrolgenecabe.component';
import { NomcierreperiodoComponent } from '../nom/nomcierreperiodo/nomcierreperiodo.component';
import { NomexpliqutiarchtxtComponent } from '../nom/nomexpliqutiarchtxt/nomexpliqutiarchtxt.component';
import { NomempleadoComponent } from '../nom/nomempleado/nomempleado.component';
import { NommaedatosadiComponent } from '../nom/nommaedatosadi/nommaedatosadi.component';
import { NomproccontabComponent } from '../nom/nomproccontab/nomproccontab.component';
import { NomrhucabhoraextrComponent } from '../nom/nomrhucabhoraextr/nomrhucabhoraextr.component';
import { NomencrolcerradosComponent } from '../nom/nomencrolcerrados/nomencrolcerrados.component';
import { GenerarpedidosComponent } from '../ven/generarpedidos/generarpedidos.component';
import { VentransforpedComponent } from '../ven/ventransforped/ventransforped.component';
import { NomrhucabimpurentComponent } from '../nom/nomrhucabimpurent/nomrhucabimpurent.component';
import { NomtrannovedadComponent } from '../nom/nomtrannovedad/nomtrannovedad.component';
import { NomencrolComponent } from '../nom/nomencrol/nomencrol.component';
import { ProdencordproComponent } from '../prod/prodencordpro/prodencordpro.component';
import { ProdmaecostoindComponent } from '../prod/prodmaecostoind/prodmaecostoind.component';
import { ProdcostosxfechaComponent } from '../prod/prodcostosxfecha/prodcostosxfecha.component';
import { ProdrecalcularcostosindComponent } from '../prod/prodrecalcularcostosind/prodrecalcularcostosind.component';
import { NomutilidadesComponent } from '../nom/nomutilidades/nomutilidades.component';
import { NomliqudecitercComponent } from '../nom/nomliqudeciterc/nomliqudeciterc.component';
import { NomtransotregresosComponent } from '../nom/nomtransotregresos/nomtransotregresos.component';
import { InvconsultalotesComponent } from '../ven/invconsultalotes/invconsultalotes.component';
import { ImportadatosexcelComponent } from '../nom/importadatosexcel/importadatosexcel.component';
import { TecmaeetiquetaComponent } from '../tec/tecmaeetiqueta/tecmaeetiqueta.component';
import { TecmaeequipoComponent } from '../tec/tecmaeequipo/tecmaeequipo.component';
import { TecencmantComponent } from '../tec/tecencmant/tecencmant.component';
import { TecencordtraComponent } from '../tec/tecencordtra/tecencordtra.component';
import { ActmaegrupoComponent } from '../act/actmaegrupo/actmaegrupo.component';
import { InvrecalcularcostopromprodComponent } from '../inv/invrecalcularcostopromprod/invrecalcularcostopromprod.component';
import { ActmaepolizaComponent } from '../act/actmaepoliza/actmaepoliza.component';
import { ActmaeactivoComponent } from '../act/actmaeactivo/actmaeactivo.component';
import { VenencptovtaComponent } from '../ven/venencptovta/venencptovta.component';
import { ActenckardexComponent } from '../act/actenckardex/actenckardex.component';
import { ActencsalidaComponent } from '../act/actencsalida/actencsalida.component';
import { SacimaereferenciaactarComponent } from '../act/sacimaereferenciaactar/sacimaereferenciaactar.component';
import { SacimaereferenciaactdpComponent } from '../act/sacimaereferenciaactdp/sacimaereferenciaactdp.component';
import { SacimaereferenciaactofComponent } from '../act/sacimaereferenciaactof/sacimaereferenciaactof.component';
import { SacimaereferenciaactmtslafComponent } from '../act/sacimaereferenciaactmtslaf/sacimaereferenciaactmtslaf.component';
import { SacimaereferenciaactarService } from '../act/actservicios/sacimaereferenciaactar.service';
import { VenconsulpedComponent } from '../ven/venconsulped/venconsulped.component';//GSRF
import { AneximpdocsriComponent } from '../anex/aneximpdocsri/aneximpdocsri.component';//GSRF
import {CxctrnretencionesComponent} from '../cxc/cxctrnretenciones/cxctrnretenciones.component';//CDPJ
import {CxctrncobranzasComponent} from '../cxc/cxctrncobranzas/cxctrncobranzas.component';//CDPJ
import {NomenviorolesComponent} from '../nom/nomenvioroles/nomenvioroles.component';//CDPJ
import { ComimpdoceleComponent } from '../com/comimpdocele/comimpdocele.component';//GSRF
import {SegencenviocorreoComponent} from '../seg/segencenviocorreo/segencenviocorreo.component'//CDPJ
import {CxctrnabonoComponent} from '../cxc/cxctrnabono/cxctrnabono.component'//CDPJ
import {CxctrncancelacionComponent} from '../cxc/cxctrncancelacion/cxctrncancelacion.component'//CDPJ
import { BantrninversionesComponent } from "../ban/bantrninversiones/bantrninversiones.component";//CDPJ
import { ProdencprodprocesoComponent } from "../prod/prodencprodproceso/prodencprodproceso.component";
import { InvhistorialserialComponent } from "../inv/invhistorialserial/invhistorialserial.component";
import { InvtrnajustenegComponent } from "../inv/invtrnajusteneg/invtrnajusteneg.component";
import { BanmaeentifinComponent } from '../ban/banmaeentifin/banmaeentifin.component';
import { PresuplancuentasComponent } from "../cont/presuplancuentas/presuplancuentas.component";
import { PresucentrocostosComponent } from '../cont/presucentrocostos/presucentrocostos.component';
import { ComencreembolsoComponent } from "../com/comencreembolso/comencreembolso.component";
@Component({
  selector: 'app-listacomponentes',
  templateUrl: './listacomponentes.component.html',
  styleUrls: ['./listacomponentes.component.css']
})

export class ListacomponentesComponent implements OnInit {
  @Input('componete') componente: string;
  @Input('label') label: string;
  @Input('modprefijo') modprefijo: string;
  @Output() menuOpciones = new EventEmitter<any[]>();
  // @ts-ignore
  // @ViewChild('tabContent', {read: ViewContainerRef})
  // tabContent: ViewContainerRef;
  @ViewChild('tabContent', { static: true, read: ViewContainerRef })
  tabContent: ViewContainerRef;
  opcodigo: number;

  constructor(public cfr: ComponentFactoryResolver, private utilitariosService: UtilitariosService,
    private router: Router, private reporte: ReportesService, private confIniciales: ConfInicialesService) {
  }

  ngOnInit() {
    this.opcodigo = 0;
    let factory;
    switch (this.componente) {
      /*
      ----------------------------------------
      ----------------PDVTOUCH----------------
      ----------------------------------------
      */
      case 'frmTOUCHPANTALLA': {
        // this.router.navigate(['conf']);
        factory = this.cfr.resolveComponentFactory(MaestroComponent);
        break;
      }
      case 'frmTOUCHFACTURA': {
        // this.router.navigate(['pdvtouch']);
        factory = this.cfr.resolveComponentFactory(PdvprincipalComponent);
        break;
      }
      case 'frmTOUCHPEDIDO': {
        factory = this.cfr.resolveComponentFactory(PedprincipalComponent);
        break;
      }

      /*
      ----------------------------------------
      ----------------VENTAS------------------
      ----------------------------------------
      */
     case 'frmVEN_MAECLIENTE': { // Victor
      // factory = this.cfr.resolveComponentFactory(VenmaeclienteComponent);
      // break;
      //CDPJ
      if (this.confIniciales.instanciasClientes === 0) {
        factory = this.cfr.resolveComponentFactory(VenmaeclienteComponent);
        this.confIniciales.instanciasClientes++;
        break;
      } else {
        break;
      }
      //CDPJ
    }
    case 'frmVEN_MAEVENDEDOR': {
        if (this.confIniciales.instanciaVendedor === 0) {
          factory = this.cfr.resolveComponentFactory(VenmaevendedorComponent);
          this.confIniciales.instanciaVendedor++;
          break;
        } else {
          break;
        }
      }
      case 'frmVEN_MAECIUDAD': {
        factory = this.cfr.resolveComponentFactory(VenmaeciudadComponent);
        break;
      }
      case 'frmVEN_MAEDSCTO': {
        factory = this.cfr.resolveComponentFactory(VenmaedescuentosComponent);
        break;
      }
      case 'frmVEN_MAEGRUPO': {
        factory = this.cfr.resolveComponentFactory(VenmaegruposComponent);
        break;
      }
      case 'frmVEN_MAESERVICIO': {
        factory = this.cfr.resolveComponentFactory(VenmaeserviciosComponent);
        break;
      }
      case 'frmVEN_MAETRANSPORT': {
        if (this.confIniciales.instanciaTransportista === 0) {
          factory = this.cfr.resolveComponentFactory(VenmaetransportistasComponent);
          this.confIniciales.instanciaTransportista++;
          break;
        } else {

        }
      }
      case 'frmVEN_MAEPROMOCION': {
        factory = this.cfr.resolveComponentFactory(VenmaepromocionComponent);
        break;
      }
      case 'frmVEN_MAEPRY': {
        factory = this.cfr.resolveComponentFactory(VenmaepryComponent);
        break;
      }
      case 'frmTrnDocumento': {
        factory = this.cfr.resolveComponentFactory(EletrndocumentoComponent);
        break;
      }
      // case 'frmVEN_ENCFAC': { // Victor
      //   factory = this.cfr.resolveComponentFactory(VenencfacComponent);
      //   break;
      // }
      // case 'frmVEN_ENCPED': {
      //   this.menuOpciones.emit(this.utilitariosService.menuParamsPed());
      //   factory = this.cfr.resolveComponentFactory(VenencpedComponent);
      //   break;
      // }
      // case 'frmVEN_ENCGRE': {
      //   factory = this.cfr.resolveComponentFactory(VenencguiaRemisionComponent);
      //   break;
      // }
      // case 'frmVEN_ENCDVF': {
      //   factory = this.cfr.resolveComponentFactory(VenencdevfacComponent);
      //   break;
      // }
      case 'frmVEN_ENCFAC': { // Victor
        // factory = this.cfr.resolveComponentFactory(VenencfacComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciaFacturas === 0) {
          factory = this.cfr.resolveComponentFactory(VenencfacComponent);
          this.confIniciales.instanciaFacturas++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmVEN_ENCPED': {
        // this.menuOpciones.emit(this.utilitariosService.menuParamsPed());
        // factory = this.cfr.resolveComponentFactory(VenencpedComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciaPedidos === 0) {
          factory = this.cfr.resolveComponentFactory(VenencpedComponent);
          this.confIniciales.instanciaPedidos++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmVEN_ENCGRE': {
        // factory = this.cfr.resolveComponentFactory(VenencguiaRemisionComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciaGuias === 0) {
          factory = this.cfr.resolveComponentFactory(VenencguiaRemisionComponent);
          this.confIniciales.instanciaGuias++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmVEN_ENCDVF': {
        // factory = this.cfr.resolveComponentFactory(VenencdevfacComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasDevFac === 0) {
          factory = this.cfr.resolveComponentFactory(VenencdevfacComponent);
          this.confIniciales.instanciasDevFac++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmFAR_MAELOTE': { // Victor
        factory = this.cfr.resolveComponentFactory(InvmaeloteComponent);
        break;
      }
      case 'frmFirmarDocumento': {
        factory = this.cfr.resolveComponentFactory(FirmardocumentoComponent);
        break;
      }
      case 'frmVEN_GENERARPEDIDOS': {
        factory = this.cfr.resolveComponentFactory(GenerarpedidosComponent);
        break;
      }
      case 'frmVEN_TRANSFORPED': {
        factory = this.cfr.resolveComponentFactory(VentransforpedComponent);
        break;
      }
      case 'frmVEN_ENCPTOVTA': { // Victor
        factory = this.cfr.resolveComponentFactory(VenencptovtaComponent);
        break;
      }
      case 'frmVEN_CONSULPED': { // GSRF
        factory = this.cfr.resolveComponentFactory(VenconsulpedComponent);
        break;
      }
      /*
      ----------------------------------------
      --------------INVENTARIO----------------
      ----------------------------------------
      */
     case 'frmINV_MAEARTICULO': { // Victor
      // factory = this.cfr.resolveComponentFactory(InvmaearticuloComponent);
      // break;
       //CDPJ
       if (this.confIniciales.instanciasArticulos === 0) {
        factory = this.cfr.resolveComponentFactory(InvmaearticuloComponent);
        this.confIniciales.instanciasArticulos++;
        break;
      } else {
        break;
      }
      //CDPJ
    }
      case 'frmINV_MAEBODEGA': {
        if (this.confIniciales.instanciaBodega === 0) {
          factory = this.cfr.resolveComponentFactory(InvmaebodegaComponent);
          this.confIniciales.instanciaBodega++;
          break;
        } else {
          break;
        }
      }
      case 'frmINV_MAEGRUPO': {
        if (this.confIniciales.instanciaGrupo === 0) {
          factory = this.cfr.resolveComponentFactory(InvmaegruposComponent);
          this.confIniciales.instanciaGrupo++;
          break;
        } else {
          break;
        }
      }
      case 'frmINV_MAEUNIDAD': {
        if (this.confIniciales.instanciaUnidades === 0) {
          factory = this.cfr.resolveComponentFactory(InvmaeunidadesComponent);
          this.confIniciales.instanciaUnidades++;
          break;
        } else {
          break;
        }
      }
      case 'frmINV_ENCKARDEX': {
        if (this.label === 'Entradas') {
          factory = this.cfr.resolveComponentFactory(InventradaComponent);
        } else if (this.label === 'Salidas') {
          factory = this.cfr.resolveComponentFactory(InvsalidaComponent);
        }
        break;
      }
      case 'frmINV_TRNTOMAFISICA': {
        factory = this.cfr.resolveComponentFactory(InvtomafisicaComponent);
        break;
      }
      case 'frmINV_RecalcularCostoPromedio': {
        factory = this.cfr.resolveComponentFactory(InvrecalcularcostopmdComponent);
        break;
      }
      case 'FrmINV_ActuallizarPrecios': {
        factory = this.cfr.resolveComponentFactory(InvactpreciosComponent);
        break;
      }
      case 'frmINV_RecalcularKardex': {
        if (this.confIniciales.instanciaCalcKardex === 0) {
          factory = this.cfr.resolveComponentFactory(InvprrecalkardexComponent);
          this.confIniciales.instanciaCalcKardex++;
          break;
        } else {
          break;
        }
      }
      case 'frmINV_CONTABILIZAR': {
        factory = this.cfr.resolveComponentFactory(InvcontdocumentosComponent);
        break;
      }
      case 'frmINV_ENCTRANSFER': {
        factory = this.cfr.resolveComponentFactory(InvenctransferComponent);
        break;
      }
      case 'frmINV_ConsultaExistencia': {
        factory = this.cfr.resolveComponentFactory(InvutilexistenciasComponent);
        break;
      }
      case 'frmINV_TRNNUMTRANSFER': {
        factory = this.cfr.resolveComponentFactory(InvnumtransferenciaComponent);
        break;
      }
      case 'frmINV_TRNAJUSTES': {
        factory = this.cfr.resolveComponentFactory(InvtrnajustesComponent);
        break;
      }
      case 'frmINV_TRNRESERVAS': {
        factory = this.cfr.resolveComponentFactory(InvtrnreservasComponent);
        break;
      }
      case 'frmINV_CONSULTARLOTES': {
        factory = this.cfr.resolveComponentFactory(InvconsultalotesComponent);
        break;
      }
      case 'dlgRecalcularCostoPromProd': {
        factory = this.cfr.resolveComponentFactory(InvrecalcularcostopromprodComponent);
        break;
      }
      case 'frmINV_SERIALHISTORIAL': {
        factory = this.cfr.resolveComponentFactory(InvhistorialserialComponent);
        break;
      }
      case 'frmINV_TRNAJUSTESNEG': {
        factory = this.cfr.resolveComponentFactory(InvtrnajustenegComponent);
        break;
      }
      /*
      ----------------------------------------
      --------------CUENTASXCOBRAR------------
      ----------------------------------------
      */
      case 'frmCXC_MAEBANCLI': {
        factory = this.cfr.resolveComponentFactory(CxcbancosClienteComponent);
        break;
      }
      case 'frmCXC_MAECOMISION': {
        factory = this.cfr.resolveComponentFactory(CxccomisionComponent);
        break;
      }
      case 'frmCXC_MAEFORMAPAGO': {
        factory = this.cfr.resolveComponentFactory(CxcformaPagoComponent);
        break;
      }
      case 'frmCXC_MAETARJETA': {
        factory = this.cfr.resolveComponentFactory(CxctarjetaCreditoComponent);
        break;
      }
      // case 'frmCXC_TRNCOBRO': {
      //   factory = this.cfr.resolveComponentFactory(CxccarteraactivaComponent);
      //   break;
      // }
      // case 'frmCXC_ENCCUOTA': { // Víctor
      //   factory = this.cfr.resolveComponentFactory(CxcenccuotaComponent);
      //   break;
      // }
      // case 'frmCXC_TRNPAGORET': {
      //   factory = this.cfr.resolveComponentFactory(CxctrnpagoretComponent);
      //   break;
      // }
      case 'frmCXC_TRNCOBRO': {
        // factory = this.cfr.resolveComponentFactory(CxccarteraactivaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCartActiv === 0) {
          factory = this.cfr.resolveComponentFactory(CxccarteraactivaComponent);
          this.confIniciales.instanciasCartActiv++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      //CDPJ
      case 'frmCXC_TRNRETENCIONES':{
        if (this.confIniciales.instanciasIngreRet === 0) {
          factory = this.cfr.resolveComponentFactory(CxctrnretencionesComponent);
          this.confIniciales.instanciasIngreRet++;
          break;
        } else {
          break;
        }
      }
      case 'frmCXC_TRNCOBRANZAS':{
        if (this.confIniciales.instanciasCobranza === 0) {
          factory = this.cfr.resolveComponentFactory(CxctrncobranzasComponent);
          this.confIniciales.instanciasCobranza++;
          break;
        } else {
          break;
        }
      }
      case 'frmCXC_ABONO':{
        if (this.confIniciales.instanciasTrnabono === 0) {
          factory = this.cfr.resolveComponentFactory(CxctrnabonoComponent);
          this.confIniciales.instanciasTrnabono++;
          break;
        } else {
          break;
        }
      }
      case 'frmCXC_CANCELACION':{
        if (this.confIniciales.instanciasTrncancelacion === 0) {
          factory = this.cfr.resolveComponentFactory(CxctrncancelacionComponent);
          this.confIniciales.instanciasTrncancelacion++;
          break;
        } else {
          break;
        }
      }
      //CDPj
      case 'frmCXC_ENCCUOTA': { // Víctor
        // factory = this.cfr.resolveComponentFactory(CxcenccuotaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCuotasCli === 0) {
          factory = this.cfr.resolveComponentFactory(CxcenccuotaComponent);
          this.confIniciales.instanciasCuotasCli++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCXC_TRNPAGORET': {
        // factory = this.cfr.resolveComponentFactory(CxctrnpagoretComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasPagoRet === 0) {
          factory = this.cfr.resolveComponentFactory(CxctrnpagoretComponent);
          this.confIniciales.instanciasPagoRet++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      /*
      ----------------------------------------
      --------------COMPRAS-------------------
      ----------------------------------------
      */
     case 'frmCOM_MAEPROVEEDOR_N': {
      // factory = this.cfr.resolveComponentFactory(CommaeproveedorComponent);
      // break;
      //CDPJ
      if (this.confIniciales.instanciasProveedores === 0) {
        factory = this.cfr.resolveComponentFactory(CommaeproveedorComponent);
        this.confIniciales.instanciasProveedores++;
        break;
      } else {
        break;
      }
      //CDPJ
    }
      case 'frmCOM_MAESERVICIO': {
        factory = this.cfr.resolveComponentFactory(CommaeserviciosComponent);
        break;
      }
      case 'frmSACI_MAERETENCION': {
        factory = this.cfr.resolveComponentFactory(CxcmaeretencionComponent);
        break;
      }
      case 'frmCOM_MAEGRUPO': {
        factory = this.cfr.resolveComponentFactory(CommaegrupoComponent);
        break;
      }
      case 'frmCOM_MAECARGO': {
        factory = this.cfr.resolveComponentFactory(CommaecargosComponent);
        break;
      }
      // case 'frmCOM_ENCFACPRO': {
      //   factory = this.cfr.resolveComponentFactory(ComencfacproComponent);
      //   break;
      // }
      // case 'frmCOM_ENCDEVOLUCION': {
      //   factory = this.cfr.resolveComponentFactory(ComencdevfacComponent);
      //   break;
      // }
      // case 'frmCOM_ENCNOTREC': {
      //   factory = this.cfr.resolveComponentFactory(ComnotrecComponent);
      //   break;
      // }
      // case 'frmCOM_ENCORDCOM': {
      //   factory = this.cfr.resolveComponentFactory(ComencordcomComponent);
      //   break;
      // }
      // case 'frmCOM_LIQUIDCOM': {
      //   factory = this.cfr.resolveComponentFactory(ComliquidcomComponent);
      //   break;
      // }
      case 'frmCOM_ENCFACPRO': {
        // factory = this.cfr.resolveComponentFactory(ComencfacproComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasFacComp === 0) {
          factory = this.cfr.resolveComponentFactory(ComencfacproComponent);
          this.confIniciales.instanciasFacComp++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCOM_ENCDEVOLUCION': {
        // factory = this.cfr.resolveComponentFactory(ComencdevfacComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasDevFacComp === 0) {
          factory = this.cfr.resolveComponentFactory(ComencdevfacComponent);
          this.confIniciales.instanciasDevFacComp++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCOM_ENCNOTREC': {
        // factory = this.cfr.resolveComponentFactory(ComnotrecComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasNotRec === 0) {
          factory = this.cfr.resolveComponentFactory(ComnotrecComponent);
          this.confIniciales.instanciasNotRec++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCOM_ENCORDCOM': {
        // factory = this.cfr.resolveComponentFactory(ComencordcomComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasOrdCom === 0) {
          factory = this.cfr.resolveComponentFactory(ComencordcomComponent);
          this.confIniciales.instanciasOrdCom++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCOM_LIQUIDCOM': {
        // factory = this.cfr.resolveComponentFactory(ComliquidcomComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasLiqComp === 0) {
          factory = this.cfr.resolveComponentFactory(ComliquidcomComponent);
          this.confIniciales.instanciasLiqComp++;
          break;
        } else {
          break;
        }
        //CDPJ
      }//GSRF 
      case 'frmCOM_IMPDOCELE': {
        factory = this.cfr.resolveComponentFactory(ComimpdoceleComponent);
        break;
      }//GSRF
      case 'frmCOM_ENCREEMBOLSO': {
        if (this.confIniciales.instanciasEncReem === 0) {
          factory = this.cfr.resolveComponentFactory(ComencreembolsoComponent);
          this.confIniciales.instanciasEncReem++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      
      /*
      ----------------------------------------
      --------------BANCOS--------------------
      ----------------------------------------
       */
      case 'frmBAN_MAECAJA': {
        factory = this.cfr.resolveComponentFactory(BanCajaComponent);
        break;
      }
      case 'frmBAN_MAEBAN': {
        factory = this.cfr.resolveComponentFactory(BanmaebanComponent);
        break;
      }
      case 'frmBAN_cierreCaja': {
        // factory = this.cfr.resolveComponentFactory(BancierreCajaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCierreCaja === 0) {
          factory = this.cfr.resolveComponentFactory(BancierreCajaComponent);
          this.confIniciales.instanciasCierreCaja++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_consilbancaria': {
        // factory = this.cfr.resolveComponentFactory(BanconcilbancariaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasConcilBanca === 0) {
          factory = this.cfr.resolveComponentFactory(BanconcilbancariaComponent);
          this.confIniciales.instanciasConcilBanca++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_TRNBAN': {
        // factory = this.cfr.resolveComponentFactory(BantrnbanComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasMoviBancos === 0) {
          factory = this.cfr.resolveComponentFactory(BantrnbanComponent);
          this.confIniciales.instanciasMoviBancos++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_CASHMANAGEMENT': {
        // factory = this.cfr.resolveComponentFactory(BancashmanagementComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCashManage === 0) {
          factory = this.cfr.resolveComponentFactory(BancashmanagementComponent);
          this.confIniciales.instanciasCashManage++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_BANDEJAPAGOS': {
        // factory = this.cfr.resolveComponentFactory(BanbandejapagosComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasBandejapago === 0) {
          factory = this.cfr.resolveComponentFactory(BanbandejapagosComponent);
          this.confIniciales.instanciasBandejapago++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_TRNTARJETA': {
        // factory = this.cfr.resolveComponentFactory(BantrntarjetaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasDeposiTarjCredi === 0) {
          factory = this.cfr.resolveComponentFactory(BantrntarjetaComponent);
          this.confIniciales.instanciasDeposiTarjCredi++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmBAN_TRNCAJ': {
        // factory = this.cfr.resolveComponentFactory(BantrncajaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasMoviCaja === 0) {
          factory = this.cfr.resolveComponentFactory(BantrncajaComponent);
          this.confIniciales.instanciasMoviCaja++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case'frmBAN_TRNINVERSION':{
        if (this.confIniciales.instanciasTrninversiones === 0) {
          factory = this.cfr.resolveComponentFactory(BantrninversionesComponent);
          this.confIniciales.instanciasTrninversiones++;
          break;
        } else {
          break;
        }
        
      }
      case 'frmBAN_MAEENTFIN': {
        factory = this.cfr.resolveComponentFactory(BanmaeentifinComponent);
        break;
      }
      case 'frmBAN_MAEENTFIN': {
        factory = this.cfr.resolveComponentFactory(BanmaeentifinComponent);
        break;
      }
      case 'frmCON_ENCPRESCON':{
        factory = this.cfr.resolveComponentFactory(PresuplancuentasComponent);
        break;
      }
      // case 'frmBAN_cierreCaja': {
      //   factory = this.cfr.resolveComponentFactory(BancierreCajaComponent);
      //   break;
      // }
      // case 'frmBAN_consilbancaria': {
      //   factory = this.cfr.resolveComponentFactory(BanconcilbancariaComponent);
      //   break;
      // }
      // case 'frmBAN_TRNBAN': {
      //   factory = this.cfr.resolveComponentFactory(BantrnbanComponent);
      //   break;
      // }
      // case 'frmBAN_CASHMANAGEMENT': {
      //   factory = this.cfr.resolveComponentFactory(BancashmanagementComponent);
      //   break;
      // }
      // case 'frmBAN_BANDEJAPAGOS': {
      //   factory = this.cfr.resolveComponentFactory(BanbandejapagosComponent);
      //   break;
      // }
      // case 'frmBAN_TRNTARJETA': {
      //   factory = this.cfr.resolveComponentFactory(BantrntarjetaComponent);
      //   break;
      // }
      // case 'frmBAN_TRNCAJ': {
      //   factory = this.cfr.resolveComponentFactory(BantrncajaComponent);
      //   break;
      // }
      /*
      ----------------------------------------
      --------------CONTABILIDAD--------------
      ----------------------------------------
       */
      case 'frmCON_MAECON': {
        // factory = this.cfr.resolveComponentFactory(ConmaeconComponent);
        // break;
         //CDPJ
         if (this.confIniciales.instanciasCntacontable === 0) {
          factory = this.cfr.resolveComponentFactory(ConmaeconComponent);
          this.confIniciales.instanciasCntacontable++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCON_MAECEN': {
        factory = this.cfr.resolveComponentFactory(ConmaecenComponent);
        break;
      }
      case 'frmCON_MAETIPDIA': {
        factory = this.cfr.resolveComponentFactory(ConmaetipoDiarioComponent);
        break;
      }
      case 'frmSACI_MAEPERIODO': {
        factory = this.cfr.resolveComponentFactory(SacimaeperiodoComponent);
        break;
      }
      case 'frmCON_ContabilizaDoc': {
        factory = this.cfr.resolveComponentFactory(ConcontabilizadocComponent);
        break;
      }
      case 'frmCON_CierreContable': {
        factory = this.cfr.resolveComponentFactory(ConcierrecontableComponent);
        break;
      }
      case 'frmCON_ENCASI_L': {
        factory = this.cfr.resolveComponentFactory(ConencasilComponent);
        break;
      }
      case 'frmCON_ENCPRESCEN': {
        factory = this.cfr.resolveComponentFactory(PresucentrocostosComponent);
        break;
      }
      /*
      ----------------------------------------
      --------------ANEXOS--------------------
      ----------------------------------------
      */
      case 'frmANEX_MAECODSRI': {
        factory = this.cfr.resolveComponentFactory(AnexmaecodsriComponent);
        break;
      }
      case 'frmANEX_GenerarArchivos': {
        factory = this.cfr.resolveComponentFactory(AnexgenerararchivosComponent);
        break;
      }

      case 'frmANEX_GenerarXML': {
        factory = this.cfr.resolveComponentFactory(AnexgenerarxmlComponent);
        break;
      }//GSRF 
      case 'frmANEX_impdocsri': {
        factory = this.cfr.resolveComponentFactory(AneximpdocsriComponent);
        break;
      }//GSRF

      /*
      ----------------------------------------
      --------------CUENTASXPAGAR-------------
      ----------------------------------------
      */
      // case 'frmCXP_TrnPagoDocumento': {
      //   factory = this.cfr.resolveComponentFactory(CxptrnpagodocumentoComponent);
      //   break;
      // }
      // case 'frmCXP_TRNCOBRO': {
      //   factory = this.cfr.resolveComponentFactory(CxptrncobroComponent);
      //   break;
      // }
      // case 'frmCXP_ENCCUOTA': {
      //   factory = this.cfr.resolveComponentFactory(CxpenccuotaComponent);
      //   break;
      // }
      case 'frmCXP_TrnPagoDocumento': {
        // factory = this.cfr.resolveComponentFactory(CxptrnpagodocumentoComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasPagoDoc === 0) {
          factory = this.cfr.resolveComponentFactory(CxptrnpagodocumentoComponent);
          this.confIniciales.instanciasPagoDoc++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCXP_TRNCOBRO': {
        // factory = this.cfr.resolveComponentFactory(CxptrncobroComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCartPas === 0) {
          factory = this.cfr.resolveComponentFactory(CxptrncobroComponent);
          this.confIniciales.instanciasCartPas++;
          break;
        } else {
          break;
        }
        //CDPJ
      }
      case 'frmCXP_ENCCUOTA': {
        // factory = this.cfr.resolveComponentFactory(CxpenccuotaComponent);
        // break;
        //CDPJ
        if (this.confIniciales.instanciasCuotas === 0) {
          factory = this.cfr.resolveComponentFactory(CxpenccuotaComponent);
          this.confIniciales.instanciasCuotas++;
          break;
        } else {
          break;
        }
        //CDPJ
      }

      /*
      ----------------------------------------
      --------------NOMINA--------------------
      ----------------------------------------
      */
      case 'frmNOM_CFGRGEN': {
        factory = this.cfr.resolveComponentFactory(NomcfgrgenComponent);
        break;
      }
      case 'frmNOM_MAEDEPARTAMENTO': {
        factory = this.cfr.resolveComponentFactory(NommaedepartamentoComponent);
        break;
      }
      case 'frmNOM_MAEPERIODO': {
        factory = this.cfr.resolveComponentFactory(NommaeperiodoComponent);
        break;
      }
      case 'frmNOM_MAEPROVINCIA': {
        // factory = this.cfr.resolveComponentFactory(NommaeprovinciasComponent);
        factory = this.cfr.resolveComponentFactory(VenmaeprovinciasComponent);
        break;
      }
      case 'frmNOM_TIPOROL': {
        factory = this.cfr.resolveComponentFactory(NommaetiporolComponent);
        break;
      }

      case 'frmNOM_MAETABLITARET': {
        factory = this.cfr.resolveComponentFactory(NommaetablitaretComponent);
        break;
      }

      case 'frmNOM_ENCROLGENECABE': {
        factory = this.cfr.resolveComponentFactory(NomencrolgenecabeComponent);
        break;
      }

      case 'frmNOM_CIERREPERIODO': {
        factory = this.cfr.resolveComponentFactory(NomcierreperiodoComponent);
        break;
      }

      case 'frmEXPORTAR_DATOS_ARCHPLAN': {
        factory = this.cfr.resolveComponentFactory(NomexpliqutiarchtxtComponent);
        break;
      }

      case 'frmNOM_CONTABILIZAR': {
        factory = this.cfr.resolveComponentFactory(NomproccontabComponent);
        break;
      }

      case 'frmNOM_RHU_CABHORAEXTR': {
        factory = this.cfr.resolveComponentFactory(NomrhucabhoraextrComponent);
        break;
      }

      case 'frmNOM_ENCROL_CERRADOS': {
        factory = this.cfr.resolveComponentFactory(NomencrolcerradosComponent);
        break;
      }

      case 'frmNOM_ENCROL': {
        factory = this.cfr.resolveComponentFactory(NomencrolComponent);
        break;
      }

      case 'frmNOM_UTILIDADES': {
        factory = this.cfr.resolveComponentFactory(NomutilidadesComponent);
        break;
      }

      case 'frmNOM_TRANSOTREGRESOS': {
        factory = this.cfr.resolveComponentFactory(NomtransotregresosComponent);
        break;
      }

      case 'frmIMPORTA_DATOS_EXCEL': {
        factory = this.cfr.resolveComponentFactory(ImportadatosexcelComponent);
        break;
      }
      //CDPJ
      case 'frmNOM_ENVIOROLES': {
        factory = this.cfr.resolveComponentFactory(NomenviorolesComponent);
        break;
      }
      //CDPJ

      /*
      ----------------------------------------
      --------------REPORTES------------------
      ----------------------------------------
      */
      case 'frmRL_MAESACI_RL': {
        factory = this.cfr.resolveComponentFactory(MantenimientoReportesComponent);
        break;
      }
      case 'frmLIS_GENERAL': {
        factory = this.cfr.resolveComponentFactory(ReportesComponent);
        break;
      }
      /*
      ----------------------------------------
      --------------DEFAULT-------------------
      ----------------------------------------
      */
      case 'frmSACI_AUDITORIA': {
        factory = this.cfr.resolveComponentFactory(SacimaeauditoriaComponent);
        break;
      }
      case 'frmSACI_MAEAUDITORIA': {
        factory = this.cfr.resolveComponentFactory(SacimaeauditoriaComponent);
        break;
      }
      case 'frmSEG_MAECOMPANIA': {
        factory = this.cfr.resolveComponentFactory(SegmaecompaniaComponent);
        break;
      }
      case 'frmSEG_MAEUSUARIO': {
        factory = this.cfr.resolveComponentFactory(SegmaeusuarioComponent);
        break;
      }
      case 'frmSACI_CORREOS': {
        if (this.confIniciales.instanciasCorreos === 0) {
          factory = this.cfr.resolveComponentFactory(SegencenviocorreoComponent);
          this.confIniciales.instanciasCorreos++;
          break;
        } else {
          break;
        }
      }
      /*
      ----------------------------------------
      --------------PRODUCCION-----------------
      ----------------------------------------
      */
      case 'frmPROD_ENCORDPRO': {
        factory = this.cfr.resolveComponentFactory(ProdencordproComponent);
        break;
      }
      case 'frmPROD_MAECOSTOIND': {
        factory = this.cfr.resolveComponentFactory(ProdmaecostoindComponent);
        break;
      }
      case 'frmPROD_RecalcularCostosInd': {
        factory = this.cfr.resolveComponentFactory(ProdrecalcularcostosindComponent);
        break;
      }
      case'frmPROD_ENCORDPROPROCESOS':{
        factory = this.cfr.resolveComponentFactory(ProdencprodprocesoComponent);
        // if (this.confIniciales.instanciasProdencordprodprocesos === 0) {
        //   factory = this.cfr.resolveComponentFactory(ProdencprodprocesoComponent);
        //   this.confIniciales.instanciasProdencordprodprocesos++;
        //   break;
        // } else {
        //   break;
        // }
        break;
      }
      /*
      ----------------------------------------
      --------------SEGURIDAD-----------------
      ----------------------------------------
      */
      case 'frmSACI_MAECONFIGURACION': {
        factory = this.cfr.resolveComponentFactory(SacimaeconfiguracionComponent);
        break;
      }
      case 'DlgConformacionNew': {
        factory = this.cfr.resolveComponentFactory(SegconformacionComponent);
        break;
      }
      case 'frmSEG_MAEAUTORIZACION': {
        factory = this.cfr.resolveComponentFactory(SegmaeautorizacionComponent);
        break;
      }
      case 'frmSEG_MAEORGANIZACION': {
        factory = this.cfr.resolveComponentFactory(SegmaeorganizacionComponent);
        break;
      }
      case 'frmSEG_MAEPERFIL': {
        factory = this.cfr.resolveComponentFactory(SegmaeperfilComponent);
        break;
      }
      case 'frmSEG_MAETIPREF': {
        factory = this.cfr.resolveComponentFactory(SegmaetiprefComponent);
        break;
      }
      case 'frmSACI_MAENUMERACIONG': {
        factory = this.cfr.resolveComponentFactory(SegmaenumeraciongComponent);
        break;
      }
      case 'frmSACI_MAENUMERACIONC': {
        factory = this.cfr.resolveComponentFactory(SacimaenumeracionCComponent);
        break;
      }
      case 'frmSEG_CAMBIOCLAVE': {
        factory = this.cfr.resolveComponentFactory(SegcambioclaveComponent);
        break;
      }
      case 'frmSACI_MAEREFERENCIA': {
        factory = this.cfr.resolveComponentFactory(SegmaereferenciaComponent);
        break;
      }
      case 'frmSACI_MAECFNGIMPR': {
        factory = this.cfr.resolveComponentFactory(SegmaereppantallaComponent);
        break;
      }
      case 'frmVEN_MAEPROVINCIA': {
        factory = this.cfr.resolveComponentFactory(VenmaeprovinciasComponent);
        break;
      }
      case 'frmCON_ASIDESCUADRE': {
        factory = this.cfr.resolveComponentFactory(ConasidescuadreComponent);
        break;
      }
      case 'DlgInicializarContadores': {
        factory = this.cfr.resolveComponentFactory(CondlginicializarcontadoresComponent);
        break;
      }
      case 'frmNOM_MAERUBRO': {
        factory = this.cfr.resolveComponentFactory(NommaerubroComponent);
        break;
      }
      case 'frmSACI_MAEEMPRESAS': {
        factory = this.cfr.resolveComponentFactory(SacimaeempresaComponent);
        break;
      }
      case 'frmNOM_EMPLEADO': {
        factory = this.cfr.resolveComponentFactory(NomempleadoComponent);
        break;
      }
      case 'frmNOM_DATOS_ADICIONALES': {
        factory = this.cfr.resolveComponentFactory(NommaedatosadiComponent);
        break;
      }
      case 'frmNOM_RHU_CABIMPURENT': {
        factory = this.cfr.resolveComponentFactory(NomrhucabimpurentComponent);
        break;
      } case 'frmNOM_TRANNOVEDAD': {
        factory = this.cfr.resolveComponentFactory(NomtrannovedadComponent);
        break;
      }
      case 'FrmPROD_CostosXFecha': {
        factory = this.cfr.resolveComponentFactory(ProdcostosxfechaComponent);
        break;
      }
      case 'frmNOM_LIQUDECITERC': {
        factory = this.cfr.resolveComponentFactory(NomliqudecitercComponent);
        break;
      }

      /*
      ----------------------------------------
      -------SERVICIO TECNICO-----------------
      ----------------------------------------
      */
      case 'frmTEC_MAEETIQUETA': {
        factory = this.cfr.resolveComponentFactory(TecmaeetiquetaComponent);
        break;
      }
      case 'frmTEC_MAEEQUIPO': {
        factory = this.cfr.resolveComponentFactory(TecmaeequipoComponent);
        break;
      }
      case 'frmTEC_ENCMANT': {
        factory = this.cfr.resolveComponentFactory(TecencmantComponent);
        break;
      }
      case 'frmVEN_ENCORDTRA_N': {
        factory = this.cfr.resolveComponentFactory(TecencordtraComponent);
        break;
      }

      /*
      ----------------------------------------
      -------ACTIVOS FIJOS-----------------
      ----------------------------------------
      */
      case 'frmACT_MAEGRUPO': {
        factory = this.cfr.resolveComponentFactory(ActmaegrupoComponent);
        break;
      }
      case 'frmACT_MAEPOLIZA': {
        factory = this.cfr.resolveComponentFactory(ActmaepolizaComponent);
        break;
      }
      case 'frmACT_MaeActivo': {
        factory = this.cfr.resolveComponentFactory(ActmaeactivoComponent);
        break;
      }
      case 'frmACT_EncKardex': {
        factory = this.cfr.resolveComponentFactory(ActenckardexComponent);
        break;
      }
      case 'frmACT_ENCSALIDA': {
        factory = this.cfr.resolveComponentFactory(ActencsalidaComponent);
        break;
      }
      case 'frmSACI_MAEREFERENCIAACTAR': {
        factory = this.cfr.resolveComponentFactory(SacimaereferenciaactarComponent);
        break;
      }
      case 'frmSACI_MAEREFERENCIAACTDP': {
        factory = this.cfr.resolveComponentFactory(SacimaereferenciaactdpComponent);
        break;
      }
      case 'frmSACI_MAEREFERENCIAACTOF': {
        factory = this.cfr.resolveComponentFactory(SacimaereferenciaactofComponent);
        break;
      }
      case 'frmSACI_MAEREFERENCIAMTSLAF': {
        factory = this.cfr.resolveComponentFactory(SacimaereferenciaactmtslafComponent);
        break;
      }

      default: {
        factory = this.cfr.resolveComponentFactory(ConstruccionComponent);
        break;
      }
    }

    if (factory !== undefined) {
      // console.log(this.tabContent);
      this.tabContent.clear();
      this.tabContent.createComponent(factory);
    }
  }

}
