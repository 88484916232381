import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { DatePipe } from '@angular/common';
import { ConfInicialesService } from 'src/app/servicios/conf-iniciales.service';
import { PresucentrocostosDET_APIRes, PresucentrocostosDET_APIReq, PresucentrocostosENC } from '../coninterfaces/presucentrocostos';
@Injectable({
  providedIn: 'root'
})
export class PresucentrocostosService {

  private url: string;
  private way: string;
  private strPREINCIVAE;
  private strNODEEXNEG;
  public _presuCXC: PresucentrocostosDET_APIRes[] = []

  get presuCXC(): PresucentrocostosDET_APIRes[] {
    return this._presuCXC;
  }
  set presuCXC(value: PresucentrocostosDET_APIRes[]) {
    this._presuCXC = value;
  }

  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
  ) {
    this.url = sessionStorage.getItem('url');
    this.way = sessionStorage.getItem('way');
  }

  // ===============APIS BACKEND===============
  // ===============ENCABEZADO===============
  insertencprescencostos(obj: { anio: string, desc: string, code: string }): Promise<any[]> {
    const { anio, desc, code } = obj
    const url = this.way + '/cont/prescentrocostos/257e3b7d75d99e00000000000000'
    const body = {
      elementos: {
        p_cen_encpresanio: anio,
        p_cen_encdescripcion: desc,
        p_com_codigo: code
      },
    }
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  actualizaencprescencostos(obj: { anio: string, desc: string, code: string }): Promise<any[]> {
    const { anio, desc, code } = obj
    console.log("well this", obj)
    const url = this.way + '/cont/prescentrocostos/299df2ab2'
    const body = {
      elementos: {
        p_CEN_ENCPRESANIO: anio,
        p_CEN_ENCDESCRIPCION: desc,
        p_COM_CODIGO: code
      },
    }
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  eliminaencprescencostos(obj: { anio: string, desc: string, code: string }): Promise<any[]> {
    const { anio, desc, code } = obj
    const url = this.way + '/cont/prescentrocostos/3ab2b4aea75d9a000000000000000'
    const body = {
      elementos: {
        p_CEN_ENCPRESANIO: anio,
        p_COM_CODIGO: code
      },
    }
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }

  // ===============FIN-ENCABEZADO===============
  // ===============DETALLE===============
  insertdetprescencostos(year: string, p_com_codigo = "01", item: PresucentrocostosDET_APIRes): Promise<any[]> {
    const {
      CEN_CODIGO: p_cen_codigo,
      // ojo
      // cen_nombre:p_com_codigo,
      // ojo
      CEN_DETPRES01: p_cen_detpres01,
      CEN_DETPRES02: p_cen_detpres02,
      CEN_DETPRES03: p_cen_detpres03,
      CEN_DETPRES04: p_cen_detpres04,
      CEN_DETPRES05: p_cen_detpres05,
      CEN_DETPRES06: p_cen_detpres06,
      CEN_DETPRES07: p_cen_detpres07,
      CEN_DETPRES08: p_cen_detpres08,
      CEN_DETPRES09: p_cen_detpres09,
      CEN_DETPRES10: p_cen_detpres10,
      CEN_DETPRES11: p_cen_detpres11,
      CEN_DETPRES12: p_cen_detpres12,
      CEN_DETPRESACM: p_cen_detpresacm,
    } = item
    const url = this.way + '/cont/prescentrocostos/3ab2b4aea75d9a00000213561'
    const body = {
      elementos: {
        p_cen_encpresanio: year,
        p_com_codigo,
        p_cen_codigo,
        p_cen_detpres01,
        p_cen_detpres02,
        p_cen_detpres03,
        p_cen_detpres04,
        p_cen_detpres05,
        p_cen_detpres06,
        p_cen_detpres07,
        p_cen_detpres08,
        p_cen_detpres09,
        p_cen_detpres10,
        p_cen_detpres11,
        p_cen_detpres12,
        p_cen_detpresacm
      },
    }
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  actualizadetprescencostos(year: string, item: PresucentrocostosDET_APIRes): Promise<any[]> {
    const {
      CEN_CODIGO: p_cen_codigo,
      // ojo
      // cen_nombre:p_com_codigo,
      // ojo
      CEN_DETPRES01: p_cen_detpres01,
      CEN_DETPRES02: p_cen_detpres02,
      CEN_DETPRES03: p_cen_detpres03,
      CEN_DETPRES04: p_cen_detpres04,
      CEN_DETPRES05: p_cen_detpres05,
      CEN_DETPRES06: p_cen_detpres06,
      CEN_DETPRES07: p_cen_detpres07,
      CEN_DETPRES08: p_cen_detpres08,
      CEN_DETPRES09: p_cen_detpres09,
      CEN_DETPRES10: p_cen_detpres10,
      CEN_DETPRES11: p_cen_detpres11,
      CEN_DETPRES12: p_cen_detpres12,
      CEN_DETPRESACM: p_cen_detpresacm,
    } = item
    const p_cen_encpresanio = year
    const p_com_codigo = "01"
    const url = this.way + '/cont/prescentrocostos/299df2ab2021777444'
    const body = {
      elementos: {
        p_cen_encpresanio,
        p_com_codigo,
        p_cen_codigo,
        p_cen_detpres01,
        p_cen_detpres02,
        p_cen_detpres03,
        p_cen_detpres04,
        p_cen_detpres05,
        p_cen_detpres06,
        p_cen_detpres07,
        p_cen_detpres08,
        p_cen_detpres09,
        p_cen_detpres10,
        p_cen_detpres11,
        p_cen_detpres12,
        p_cen_detpresacm
      },
    }
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  eliminadetprescencostos(year: any, comCode: string, cenCode: string): Promise<any[]> {
    const url = this.way + '/cont/prescentrocostos/3ab2b4aea6bbba000000000000000'
    const body = {
      elementos: {
        p_cen_encpresanio: year,
        p_cen_codigo: cenCode,
        p_com_codigo: comCode
      }
    }
    console.log("delete values", body)
    return this.http.post<any[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  // ===============DETALLE===============
  // ===============CONSULTA===============
  consuldetprescencostos(year: any, comCode: string): Promise<any[]> {
    const url = this.way + '/cont/prescentrocostos/198bf3d56bbb9e00000000000000'
    const body = {
      elementos: {
        p_CEN_ENCPRESANIO: year,
        p_COM_CODIGO: comCode,
      },
    }
    return this.http.post<PresucentrocostosDET_APIRes[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  consulencprescencostos(year: any, comCode: string) {
    const url = this.way + '/cont/prescentrocostos/662fcf55d76678000000'
    const body = {
      elementos: {
        p_CEN_ENCPRESANIO: year,
        p_COM_CODIGO: comCode,
      },
    }
    return this.http.post<PresucentrocostosENC[]>(url, body, this.confIniciales.httpOptions()).toPromise();
  }
  // ===============CONSULTA===============
  // ===============APIS BACKEND===============
  calcularacm(index) {
    // const currentItem = this.itemSelected
    // console.log("before,", currentItem)

    // const numberKeyes = Object.keys(currentItem).filter(key => key.startsWith("CEN_DETPRES"))

    // const sumNumberKeyes = numberKeyes.reduce((acc, key) => acc + Number(currentItem[key]), 0)

    // currentItem.CEN_DETPRESACM = sumNumberKeyes

    // this.itemSelected = currentItem

    this.presuCXC[index].CEN_DETPRESACM = Number(
      Number(this.presuCXC[index].CEN_DETPRES01) +
      Number(this.presuCXC[index].CEN_DETPRES02) +
      Number(this.presuCXC[index].CEN_DETPRES03) +
      Number(this.presuCXC[index].CEN_DETPRES04) +
      Number(this.presuCXC[index].CEN_DETPRES05) +
      Number(this.presuCXC[index].CEN_DETPRES06) +
      Number(this.presuCXC[index].CEN_DETPRES07) +
      Number(this.presuCXC[index].CEN_DETPRES08) +
      Number(this.presuCXC[index].CEN_DETPRES09) +
      Number(this.presuCXC[index].CEN_DETPRES10) +
      Number(this.presuCXC[index].CEN_DETPRES11) +
      Number(this.presuCXC[index].CEN_DETPRES12)
    )
  }

}