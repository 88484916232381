import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Usuario } from './usuario';
import { UsuarioEsquema } from './usuarioEsquema';
import { Router } from '@angular/router';
import { CalculosServiceService } from './servicios/calculos-service.service';
import { NuevoComponentService } from './servicios/nuevo-component.service';
import { ConfInicialesService } from './servicios/conf-iniciales.service';
import { VenencfacService } from './ven/venservicios/venencfac.service';
import { VenencpedService } from './ven/venservicios/venencped.service';
import { VenEncped } from './ven/veninterfaces/venencped';
import { VenencpedComponent } from './ven/venencped/venencped.component';
import { UtilitariosService } from './advantage/advantageservices/utilitarios.service';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
  styleUrls: ['./app.main.component.css']
})
export class AppMainComponent implements OnInit {
  @ViewChild(VenencpedComponent) venPed: VenencpedComponent;
  menuMode = 'static';
  opcionesMenuDerecho: any[];
  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  layoutMenuScroller: HTMLDivElement;

  lightMenu = true;

  topbarColor = 'layout-topbar-blue';

  menuClick: boolean;

  userMenuClick: boolean;

  notificationMenuClick: boolean;

  rightMenuClick: boolean;

  resetMenu: boolean;

  menuHoverActive: boolean;

  topbarUserMenuActive: boolean;

  topbarNotificationMenuActive: boolean;

  rightPanelMenuActive: boolean;

  inlineUser: boolean;

  isRTL: boolean;

  newTab: boolean;

  botonesnuevosalir: boolean;


  constructor(public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    public route: Router,
    private calc: CalculosServiceService,
    public init: NuevoComponentService,
    private confIniciales: ConfInicialesService,
    public encfacService: VenencfacService,
    public encpedService: VenencpedService,
    public utilitariosService: UtilitariosService
  ) {
    /*let recarga = JSON.parse(sessionStorage.getItem('recarga'));
    if (recarga === null) {
      sessionStorage.setItem('recarga', JSON.stringify({value: true}));
    }
    window.addEventListener('beforeunload', (event) => {
      confIniciales.cerrarSesion();
      event.preventDefault();
      event.returnValue = '/';
      recarga = JSON.parse(sessionStorage.getItem('recarga'));
      if (recarga === null) {
      } else {
        sessionStorage.setItem('recarga', JSON.stringify({value: 'recarga'}));
      }
    });*/
  }

  ngOnInit(): void {
    // this.utilitariosService.updateDatosEnUso(1);
    this.usuarioEsquema.loadStorage();
    this.usuario.loadStorage();
    this.opcionesMenuDerecho = [];
    this.newTab = false;
    this.botonesnuevosalir = false;

    if (this.usuario.login === false) {
      this.route.navigate(['login']);
    } else {
      this.init.tabs = [
        {
          label: 'Inicio',
          component: 'Inicio'
        }
      ];
      this.init.tabindex = 0;
    }
  }

  onTabChange(tabIndex) {
    this.init.tabindex = tabIndex;

    if (this.init.tabs[tabIndex] === undefined) {
      return;
    }

    // console.log(this.init.tabs[tabIndex]);
    if (this.init.tabs[tabIndex].modprefijo === 'PDV') {
      if (this.init.tabs[tabIndex].label === 'Facturación') {
        this.botonesnuevosalir = true;
      } else {
        this.botonesnuevosalir = false;
      }
    } else {
      this.botonesnuevosalir = false;
    }

  }

  cerrarTab(tabIndex) {
    this.init.tabs.splice(tabIndex, 1);
  }

  public openNewTab(opcion, label, modprefijo, menu) {
    // console.log(opcion, label, modprefijo, menu);
    if (modprefijo === 'PDV') {
      if (label === 'Facturación') {
        this.botonesnuevosalir = true;
      } else {
        this.botonesnuevosalir = false;
      }
    } else {
      this.botonesnuevosalir = false;
    }

    this.newTab = true;
    this.init.indice++;
    let posicion;
    posicion = this.init.obtenerComponenteNum();

    if (opcion === 'frmSACI_CORREOS') {//CDPJ
      if (this.confIniciales.instanciasCorreos === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
       // Presupuesto de centro de costos
      // 20/08/2024
    }
    // else if (opcion === 'frmCON_ENCPRESCEN') {//CDPJ
    //   if (this.confIniciales.instanciasCntacontable === 0) {
    //     this.init.tabs.push({
    //       label: label,
    //       component: opcion,
    //       modprefijo: modprefijo,
    //       indice: this.init.indice,
    //       labelmenu: menu
    //     });
    //     this.calc.editFocus(0, posicion);
    //     this.init.tabindex = this.init.tabs.length;
    //   }
    // }
    else if (opcion === 'frmCON_MAECON') {//CDPJ
      if (this.confIniciales.instanciasCntacontable === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmINV_MAEBODEGA') {
      if (this.confIniciales.instanciaBodega === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if (opcion === 'frmVEN_MAEVENDEDOR') {
      if (this.confIniciales.instanciaVendedor === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if (opcion === 'frmINV_MAEGRUPO') {
      if (this.confIniciales.instanciaGrupo === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if (opcion === 'frmINV_MAEUNIDAD') {
      if (this.confIniciales.instanciaUnidades === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if (opcion === 'frmINV_RecalcularKardex') {
      if (this.confIniciales.instanciaCalcKardex === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmINV_MAEARTICULO') {//CDPJ
      if (this.confIniciales.instanciasArticulos === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if (opcion === 'frmVEN_MAETRANSPORT') {
      if (this.confIniciales.instanciaTransportista === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } //CDPJ
    else if (opcion === 'frmVEN_ENCFAC') {
      if (this.confIniciales.instanciaFacturas === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmVEN_ENCPED') {
      if (this.confIniciales.instanciaPedidos === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmVEN_ENCGRE') {
      if (this.confIniciales.instanciaGuias === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmVEN_ENCDVF') {
      if (this.confIniciales.instanciasDevFac === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmVEN_MAECLIENTE') {//CDPJ
      if (this.confIniciales.instanciasClientes === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_ENCFACPRO') {
      if (this.confIniciales.instanciasFacComp === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_ENCNOTREC') {
      if (this.confIniciales.instanciasNotRec === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_ENCORDCOM') {
      if (this.confIniciales.instanciasOrdCom === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_ENCDEVOLUCION') {
      if (this.confIniciales.instanciasDevFacComp === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_LIQUIDCOM') {
      if (this.confIniciales.instanciasLiqComp === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_ENCREEMBOLSO') {//CDPJ
      if (this.confIniciales.instanciasEncReem === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCOM_MAEPROVEEDOR_N') {//CDPJ
      if (this.confIniciales.instanciasProveedores === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_TRNPAGORET') {
      if (this.confIniciales.instanciasPagoRet === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_ENCCUOTA') {
      console.log('frmCXC_ENCCUOTA',this.confIniciales.instanciasCuotasCli)
      if (this.confIniciales.instanciasCuotasCli === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_TRNCOBRO') {
      if (this.confIniciales.instanciasCartActiv === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_TRNRETENCIONES') {//CDPJ
      if (this.confIniciales.instanciasIngreRet === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_TRNCOBRANZAS') {//CDPJ
      if (this.confIniciales.instanciasCobranza === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_ABONO') {//CDPJ
      if (this.confIniciales.instanciasTrnabono === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXC_CANCELACION') {//CDPJ
      if (this.confIniciales.instanciasTrncancelacion === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXP_TrnPagoDocumento') {
      console.log('frmCXC_ENCCUOTA',this.confIniciales.instanciasPagoDoc)
      if (this.confIniciales.instanciasPagoDoc === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXP_TRNCOBRO') {
      if (this.confIniciales.instanciasCartPas === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmCXP_ENCCUOTA') {
      if (this.confIniciales.instanciasCuotas === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_cierreCaja') {
      if (this.confIniciales.instanciasCierreCaja === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_consilbancaria') {
      if (this.confIniciales.instanciasConcilBanca === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_TRNBAN') {
      if (this.confIniciales.instanciasMoviBancos === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_CASHMANAGEMENT') {
      if (this.confIniciales.instanciasCashManage === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_BANDEJAPAGOS') {
      if (this.confIniciales.instanciasBandejapago === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_TRNTARJETA') {
      if (this.confIniciales.instanciasDeposiTarjCredi === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }else if (opcion === 'frmBAN_TRNCAJ') {
      if (this.confIniciales.instanciasMoviCaja === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    } else if(opcion === 'frmBAN_TRNINVERSION'){
      if (this.confIniciales.instanciasTrninversiones === 0) {
        this.init.tabs.push({
          label: label,
          component: opcion,
          modprefijo: modprefijo,
          indice: this.init.indice,
          labelmenu: menu
        });
        this.calc.editFocus(0, posicion);
        this.init.tabindex = this.init.tabs.length;
      }
    }
    // else if(opcion === 'frmPROD_ENCORDPROPROCESOS'){
    //   if (this.confIniciales.instanciasProdencordprodprocesos === 0) {
    //     this.init.tabs.push({
    //       label: label,
    //       component: opcion,
    //       modprefijo: modprefijo,
    //       indice: this.init.indice,
    //       labelmenu: menu
    //     });
    //     this.calc.editFocus(0, posicion);
    //     this.init.tabindex = this.init.tabs.length;
    //   }
    // }
    //CDPJ
    else {
      this.init.tabs.push({
        label: label,
        component: opcion,
        modprefijo: modprefijo,
        indice: this.init.indice,
        labelmenu: menu
      });
      this.calc.editFocus(0, posicion);
      this.init.tabindex = this.init.tabs.length;
    }
  }

  onLayoutClick() {
    if (!this.userMenuClick) {
      this.topbarUserMenuActive = false;
    }

    if (!this.notificationMenuClick) {
      this.topbarNotificationMenuActive = false;
    }

    if (!this.rightMenuClick) {
      this.rightPanelMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    this.userMenuClick = false;
    this.rightMenuClick = false;
    this.notificationMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;
    this.topbarNotificationMenuActive = false;
    this.rightPanelMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onMenuButtonClick2(event) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;
    this.topbarNotificationMenuActive = false;
    this.rightPanelMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = false;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = true;
    } else {
      this.staticMenuMobileActive = false;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onMenuButtonClick3(event) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;
    this.topbarNotificationMenuActive = false;
    this.rightPanelMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = true;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = false;
    } else {
      this.staticMenuMobileActive = true;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }


  onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopbarUserMenuButtonClick(event) {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarNotificationMenuButtonClick(event) {
    this.notificationMenuClick = true;
    this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onRightMenuClick(event) {
    this.rightMenuClick = true;
    this.rightPanelMenuActive = !this.rightPanelMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  isHorizontal() {
    return this.menuMode === 'horizontal';
  }

  isSlim() {
    return this.menuMode === 'slim';
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isStatic() {
    return this.menuMode === 'static';
  }

  isMobile() {
    return window.innerWidth < 1025;
  }

  isDesktop() {
    return window.innerWidth > 896;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  changeMenuMode(menuMode: string) {
    this.menuMode = menuMode;
    this.staticMenuDesktopInactive = false;
    this.overlayMenuActive = false;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  cambiarOpciones(valor) {
    this.opcionesMenuDerecho = valor;
  }

  doSomething() {
  }

  /*ngOnDestroy(): void {
    this.utilitariosService.updateDatosEnUso(0);
    alert('cerrando');
  }*/


}
