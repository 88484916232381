<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="preplanc"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="PrePlanc" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" 
        [botonNuevo]="btnNuevo" 
        [botonGuardar]="btnGuardar"
        [botonBorrar]="btnBorrar"
        [botonRegresar]="false" 
        [barraBotonesDesplaz]="true" [barraBotones3]="true"
        [barraBotonesAsiCon]="true" [barraBotones5]="true"  [botonconsolida]="true"
        [botonBuscarPermanente]="true"
        [botonImprimirPermanente]="true"
            ></app-invmaebarra>
    </div>
</div>
<div class="divgrups">
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Año:</span>
                </div>
                <div class="p-col-1">
                    <input pInputText type="text" size="4"  id="CON_ENCPRESANIO" [disabled]="boolnuevo" oninput="this.value= this.value.replace(/[^0-9]/g, '');"
                    (keydown.enter)="busquedaanio(presuplancuentasService.encabezadoPresuSeleccionadoarray[indicador].CON_ENCPRESANIO)"
                        [(ngModel)]="presuplancuentasService.encabezadoPresuSeleccionadoarray[indicador].CON_ENCPRESANIO" autocomplete="off"
                        [maxLength]="4">
                </div>
                <div class="p-col-1">
                    <button class="littlebuttons"
                        style="background-image: url(../../../assets/images/iconos/buscar.png);"
                        (click)="busquedaanio(presuplancuentasService.encabezadoPresuSeleccionadoarray[indicador].CON_ENCPRESANIO)"
                    ></button>
                </div>

            </div>  
        </div>
       <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col-1">
                    <span>Descripción:</span>
                </div>
                <div class="p-col-11">
                    <input pInputText type="text" size="14"  id="CON_ENCDESCRIPCION" [disabled]="boolconsolida" 
                        [(ngModel)]="presuplancuentasService.encabezadoPresuSeleccionadoarray[indicador].CON_ENCDESCRIPCION" autocomplete="off"
                        (input)="cambio($event)" [maxLength]="10" >
                </div>
            </div>
       </div>
    </div>
</div>
<div class="divg" style="height: 650px">
    <app-aggridsaci [width]="" [height]="-1500" [enableSorting]="false" [enableFilter]="true"
        [rowData]="presuplancuentasService.detpresupuestoarray" [frameworkComponents]="frameworkComponents" [animateRows]="true"
        [rowSelection]="rowSelection" [columnDefs]="columnDefspresuCon" [defaultColDef]="defaultColDefPresucon"
        [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false" (selected)="selectedPresuCon($event)"
        (cambios)="cambio($event)"
        ></app-aggridsaci>
</div>
<div style="position: fixed;
			z-index: 999;
			height: 2em;
			width: 2em;
			overflow: visible;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;">
	<p-progressSpinner *ngIf="spin" [style]="{width: '100px', height: '100px'}" animationDuration=".5s">
	</p-progressSpinner>
	
</div>

<p-dialog header="Búsqueda de Datos" [(visible)]="displayDialogBusquedaFast" [transitionOptions]="'0ms'" [modal]="true"
	appendTo="body" [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types" [arregloConsulta]="arregloCons" [tabla]="tabla" [where]="where"
		[busquedaParams]="busquedacampos" (opcionSeleccionada)="manejarSeleccion($event)"></app-busquedadlg>
</p-dialog>