import { Component, ViewChild } from '@angular/core';
import { MessageService, ConfirmationService, SelectItem } from 'primeng';
import { NuevoComponentService } from 'src/app/servicios/nuevo-component.service';
import { Usuario } from 'src/app/usuario';
import { CellEditorPrueba } from 'src/app/advantage/config/cell-edit-prueba';
import { Invutilextencia } from 'src/app/inv/invinterfaces/invutilextencia';
import { AggridsaciComponent } from 'src/app/advantage/aggridsaci/aggridsaci.component';
import { PresucentrocostosENC, PresucentrocostosDET_APIRes } from '../coninterfaces/presucentrocostos';
import { PresucentrocostosService } from '../conservicios/presucentrocostos.service';
import { ColDef } from 'ag-grid';
import { AuditoriagralService } from 'src/app/advantage/advantageservices/auditoriagral.service';
import { InvbusquedaService } from 'src/app/inv/invservicios/invbusqueda.service';

@Component({
  selector: 'app-presucentrocostos',
  templateUrl: './presucentrocostos.component.html',
  styleUrls: ['../../advantage/styles/saciwebtrn.css']
})
export class PresucentrocostosComponent {
  @ViewChild(AggridsaciComponent) aggrid: AggridsaciComponent;

  // topbar buttons
  btnNuevo: boolean = false
  btnBorrar: boolean = true
  btnGuardar: boolean = true;
  BtnRegresar: boolean = true;

  btnGridBorrar: boolean = true
  displayDialogBusquedaFast: boolean = false
  modalOpen: boolean = false;

  isBtnNewClicked: boolean = false;
  isRecentlySave: boolean = false;
  rowStyle: any;
  largo = '560';
  rowSelection = 'multiple';
  opcion: string = ""
  indice: number;
  types: { label: string, value: string }[]
  busquedacampos: string[]
  arregloCons: string[]
  tabla: string
  where: string

  // HEADER
  headerItemCXC: PresucentrocostosENC

  // DETAILS 
  seletedInvExistencia: Invutilextencia;
  itemSelected: PresucentrocostosDET_APIRes;

  defaultColDefCentrocostos = {
    editable: true,
    width: 130,
    objeto: 'presuCCGrid'
  };;

  public frameworkComponents;

  spin = false;

  // inputs
  txtYear = '';
  txtDescription = '';
  txtYearAvailable = true;
  txtDescriptionAvailable = true

  columnDef: ColDef[] = [
    {
      headerName: 'CC', field: 'CEN_CODIGO'
      , width: 80,
      editable: false,
    },
    {
      headerName: 'Descripción', field: 'CEN_NOMBRE', cellEditor: 'cellPrueba', width: 120, editable: false,
      cellEditorParams:
      {
        values: {
          mayusculas: true,
          numeros: true,
          alphabetic: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 0,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },
    {
      headerName: 'Enero', field: 'CEN_DETPRES01', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Febrero', field: 'CEN_DETPRES02', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Marzo', field: 'CEN_DETPRES03', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Abril', field: 'CEN_DETPRES04', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Mayo', field: 'CEN_DETPRES05', cellEditor: 'cellPrueba', width: 90, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },

    {
      headerName: 'Junio', field: 'CEN_DETPRES06', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Julio', field: 'CEN_DETPRES07', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Agosto', field: 'CEN_DETPRES08', cellEditor: 'cellPrueba', width: 90, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Septiembre', field: 'CEN_DETPRES09', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Octubre', field: 'CEN_DETPRES10', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Noviembre', field: 'CEN_DETPRES11', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Diciembre', field: 'CEN_DETPRES12', cellEditor: 'cellPrueba', width: 100, cellStyle: { textAlign: 'right' }, editable: true,
      cellEditorParams:
      {
        values: {
          editable: true,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: true,
        }
      }
    },
    {
      headerName: 'Acumulado', field: 'CEN_DETPRESACM', cellEditor: 'cellPrueba', width: 120, cellStyle: { textAlign: 'right' }, editable: false,
      cellEditorParams:
      {
        values: {
          editable: false,
          mayusculas: false,
          numeros: true,
          alphabetic: false,
          int: true,
          saltoslinea: 1,
          newrow: false,
          numerodecimales: 2,
          obligatorio: true,
          fecha: false,
          tamanocelda: 10,
          tienecalculos: false,
        }
      }
    },
  ];

  constructor(
    public presuCostService: PresucentrocostosService,
    private messageService: MessageService,
    private init: NuevoComponentService,
    private confirmationService: ConfirmationService,
    public usuario: Usuario,//CDPJ
    private auditoriagral: AuditoriagralService,
    private invbusquedas: InvbusquedaService
  ) {
    this.frameworkComponents = {
      cellPrueba: CellEditorPrueba
    };
    this.rowStyle = (params) => {
      if (params.node.rowIndex % 2 !== 0) {
        return { background: '#D6EAF8' };
      }
    };
  }


  handlerSelectionDialog(opcion: any) {
    console.log("event", event, opcion)
    if (this.opcion === 'orden') {
      // this.encentradaSeleccionada.ENCORDPRO_NUMERO = opcion.ENCORDPRO_NUMERO
      // this.displayDialogBusqueda = false;
      // this.displayDialogBusquedaFast = false;
      //GSRF
      // this.obtenerDatos();
    } else {
      // this.botonPrimero = false;
      // this.botonAnterior = false;
      // this.botonSiguiente = false;
      // this.botonUltimo = false;
      // this.encentradaSeleccionada = this.encEntradas[this.indice];
      // this.obtenerDatos();
    }
    this.txtYear = opcion.CEN_ENCPRESANIO;
    this.modalOpen = false;
    this.displayDialogBusquedaFast = false;
    this.buscarCC(this.txtYear)
    // this.displayDialogBusqueda = false;
    // this.displayDialogBusquedaFast = false;
  }

  async buscar() {
    // if (!this.existeAsiento()) {
    //   return;
    // }
    this.opcion = 'BUSQUEDA_ANIO';
    this.types = [
      { label: 'Año', value: 'CEN_ENCPRESANIO' },
      { label: 'Descripcción', value: 'CEN_ENCDESCRIPCION' },
    ];
    this.busquedacampos = ['', ''];
    this.tabla = 'CON_ENCPRESCEN';

    const res = await this.invbusquedas.busquedaPresCenCos({ anio: this.txtYear, des: this.txtDescription })
    console.log("res tables", res)
    this.arregloCons = res;
    this.displayDialogBusquedaFast = true
    this.modalOpen = true
    // this.presuCostService.presuCXC = res
    // this.busquedaSer.busquedaInvEncKardex1NEW(this.modulo).subscribe((res: any[]) => {
    //   this.arregloCons = res;
    //   this.arregloCons.map((registro) => {
    //     registro.KAR_FECHA = this.datePipe.transform(registro.KAR_FECHA, 'dd/MM/yyyy');
    //   });
    //   this.inputsBoolean = false;//GSRF 
    //   this.displayDialogBusquedaFast = true;
    // });
  }




  async buscarCC(txtYear: string) {
    if (this.txtYear != null || this.txtYear) {
      const isValid = /^\d{4}$/.test(txtYear)
      console.log(txtYear)
      if (txtYear.length === 4 && isValid) {
        await this.getData(txtYear);
      } else {
        this.mensajeAlerta("info", "Información", "Por favor, selecciona un año especifico_.")
      }
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") this.buscarCC(this.txtYear)
  }

  async cambio(event: any) {
    // console.log("props event", event)
    if (event) {
      console.log
      const isValid = /^\d{4}$/.test(this.txtYear)
      console.log(this.txtYear)
      if (isValid) {
        debugger;
        // ARTIFICE: due to the state is reversing when saving the aggrid this is the reason to use this isRecentlySave 
        if (this.isRecentlySave) {
          this.isRecentlySave = false;
          await this.handleDeactivateButtons()
        } else {
          this.handleActivateButtons()
        }
      }
    }
  }

  async handleDeactivateButtons() {
    this.btnNuevo = false;
    this.btnGuardar = true;
    this.btnGridBorrar = true
  }
  handleActivateButtons() {
    this.btnNuevo = true;
    this.btnGuardar = false;
    this.btnGridBorrar = false
  }

  // *ITEMS*
  // ITEM: DELETE
  async deleteItem(item: PresucentrocostosDET_APIRes) {
    const isInserted = item.NUEVO === 1 ? true : false
    if (isInserted) {
      // const res = await this.presuCostService.eliminadetprescencostos(this.txtYear, "01", item.CEN_CODIGO)
      await this.presuCostService.eliminadetprescencostos(item.CEN_ENCPRESANIO, "01", item.CEN_CODIGO)
      return true
    }
    return false
  }
  // ITEM: UPDATE
  async updateItems(item: PresucentrocostosDET_APIRes) {
    try {
      const res = await this.presuCostService.actualizadetprescencostos(this.txtYear, item)
      console.log("actualizar", res)
      return true
    } catch (error) {
      const msg = error instanceof Error ? error.message : "causa desconocida"
      this.mensajeAlerta(
        'error',
        'Error al Actualizar',
        "Problema debido a " + msg);
      return false
    }
  }
  // ITEM: INSERT
  async insertItemS(item: PresucentrocostosDET_APIRes) {
    try {
      await this.presuCostService.insertdetprescencostos(this.txtYear, "01", item)
      return true
    } catch (error) {
      const msg = error instanceof Error ? error.message : "causa desconocida"
      this.mensajeAlerta(
        'error',
        'Error al Actualizar',
        "Problema debido a " + msg);
      return false
    }
  }

  // *HEADER*
  // HEADER: INSERT
  async insertHeader(item: PresucentrocostosENC) {
    try {
      const { CEN_ENCDESCRIPCION, COM_CODIGO, CEN_ENCPRESANIO } = item
      console.log("Ivalues", item)
      const obj = {
        desc: CEN_ENCDESCRIPCION, anio: CEN_ENCPRESANIO, code: COM_CODIGO
      }
      await this.presuCostService.insertencprescencostos(obj)
      return true
    } catch (error) {
      const msg = error instanceof Error ? error.message : "causa desconocida"
      this.mensajeAlerta(
        'error',
        'Error al Actualizar',
        "Problema debido a " + msg);
      return false
    }
  }
  // HEADER: UPDATE
  async updateHeader(item: PresucentrocostosENC) {
    try {
      const { CEN_ENCDESCRIPCION, COM_CODIGO, CEN_ENCPRESANIO } = item
      const obj = {
        desc: CEN_ENCDESCRIPCION, anio: CEN_ENCPRESANIO, code: COM_CODIGO
      }
      await this.presuCostService.actualizaencprescencostos(obj)
      return true
    } catch (error) {
      const msg = error instanceof Error ? error.message : "causa desconocida"
      this.mensajeAlerta(
        'error',
        'Error al Actualizar',
        "Problema debido a " + msg);
      return false
    }
  }
  // HEADER: DELETE
  async deleteHeader(item: PresucentrocostosENC) {
    try {
      const { CEN_ENCDESCRIPCION, COM_CODIGO, CEN_ENCPRESANIO } = item
      console.log("delete values", item)
      const obj = {
        desc: CEN_ENCDESCRIPCION, anio: CEN_ENCPRESANIO, code: COM_CODIGO
      }
      await this.presuCostService.eliminaencprescencostos(obj)
      this.mensajeAlerta('success', 'Información', 'Encabezado eliminado!');
      return true
    } catch (error) {
      const msg = error instanceof Error ? error.message : "causa desconocida"
      this.mensajeAlerta(
        'error',
        'Error al Actualizar',
        "Problema debido a " + msg);
      return false
    }
  }

  mappingData(item: PresucentrocostosDET_APIRes[]): any {
    return item.map(v => ({
      ...v,
      CEN_DETPRES01: v.CEN_DETPRES01.toFixed(2),
      CEN_DETPRES02: v.CEN_DETPRES02.toFixed(2),
      CEN_DETPRES03: v.CEN_DETPRES03.toFixed(2),
      CEN_DETPRES04: v.CEN_DETPRES04.toFixed(2),
      CEN_DETPRES05: v.CEN_DETPRES05.toFixed(2),
      CEN_DETPRES06: v.CEN_DETPRES06.toFixed(2),
      CEN_DETPRES07: v.CEN_DETPRES07.toFixed(2),
      CEN_DETPRES08: v.CEN_DETPRES08.toFixed(2),
      CEN_DETPRES09: v.CEN_DETPRES09.toFixed(2),
      CEN_DETPRES10: v.CEN_DETPRES10.toFixed(2),
      CEN_DETPRES11: v.CEN_DETPRES11.toFixed(2),
      CEN_DETPRES12: v.CEN_DETPRES12.toFixed(2),
      CEN_DETPRESACM: v.CEN_DETPRESACM.toFixed(2),
    }))
  }

  // *GETDATA*
  async getData(year: string, com_codigo: string | undefined = "01") {
    this.spin = true;
    try {
      let isValid = false
      const headerRes = await this.presuCostService.consulencprescencostos(year, com_codigo)
      if (headerRes) {
        console.log("header getdata", headerRes)
        const currentHeader = headerRes.filter(v => v.CEN_ENCPRESANIO === year)

        if (currentHeader.length) {

          // this.headerItemCXC = headerRes[0]
          const header = currentHeader[0]
          this.txtDescription = header.CEN_ENCDESCRIPCION ?? ""
          console.log("this.txtDescription", this.txtDescription)
          this.headerItemCXC = {
            CEN_ENCDESCRIPCION: this.txtDescription,
            CEN_ENCPRESANIO: this.txtYear,
            COM_CODIGO: header.COM_CODIGO,
            CONTROL: header.CONTROL
          }
          console.log("curheader", this.headerItemCXC)

          // GET ITEMS
          const res = await this.presuCostService.consuldetprescencostos(year, com_codigo)
          if (res) {
            this.spin = false;
            isValid = true
            this.presuCostService._presuCXC = this.mappingData(res)
            console.log("presuXC", this.presuCostService._presuCXC)
            this.handlerBtnAvailables()

            ///this.aggrid.refreshColumnDefs();
            this.aggrid.refreshaggrid(this.presuCostService._presuCXC, this.defaultColDefCentrocostos.objeto);
          } else {
            this.presuCostService._presuCXC = []
            console.log("fail to query", res)
            this.mensajeAlerta("error", "Error", "Busqueda no encontrada!")
            this.spin = false;
          }

        } else this.mensajeAlerta("info", "Información", "Busqueda no encontrada")

      }

    } catch (error) {
      this.mensajeAlerta("error", "Error", "Ha ocurrido un error inesperado!")
      this.spin = false
    }
  }

  async saveAuditoria(option: "I" | "A" | "E", description: string, table: string) {
    this.auditoriagral.registrarAuditoria(table, description, option,
      '', '01', '', '', '', '').subscribe(() => { });
  }

  reset() {
    this.presuCostService._presuCXC = []

    this.btnNuevo = false
    this.btnBorrar = true
    this.btnGuardar = true;
    this.btnGridBorrar = true
    this.BtnRegresar = true;
    this.txtYearAvailable = true
    this.txtDescriptionAvailable = true
    this.txtYear = ""
    this.txtDescription = ""
  }

  // ==============MAEBARRA==============
  handleNew() {
    this.btnNuevo = true
    this.txtYearAvailable = false
    this.txtDescriptionAvailable = true
    this.presuCostService._presuCXC = []

    this.txtYear = ""
    this.txtDescription = ""
  }
  handlerBtnAvailables() {
    this.btnNuevo = true;
    this.txtYearAvailable = true
    this.txtDescriptionAvailable = false
    this.btnBorrar = false
    this.btnGuardar = false
    this.btnGridBorrar = false
    this.BtnRegresar = false
  }
  async handleSave() {
    const inserts = this.presuCostService._presuCXC.filter(item => item.NUEVO === 0)
    const updates = this.presuCostService._presuCXC.filter(item => item.NUEVO === 1)
    this.spin = true

    console.log("inserts", inserts.length)
    console.log("updates", updates.length)

    const header = this.headerItemCXC
    header.CEN_ENCDESCRIPCION = this.txtDescription
    console.log("header save", header)
    const isNewHeader = header.CONTROL === 0 ? true : false
    let okHeader = false
    if (isNewHeader) {
      okHeader = await this.insertHeader(header)
    } else {
      okHeader = await this.updateHeader(header)
    }

    if (okHeader) {
      // HEADER AUDITORIA
      const optionAuditoria = this.headerItemCXC.CONTROL === 1 ? "A" : "I"
      const table = 'CON_ENCPRESCEN'
      const description = this.txtYear
      this.saveAuditoria(optionAuditoria, description, table)


      let numberOfErrors = 0
      let completed = 0

      if (inserts.length > 0) {
        await Promise.all(inserts.map(async (item, index: number) => {
          try {
            const resItem = await this.insertItemS(item)
            if (resItem) {
              const description = this.txtYear + "/" + item.CEN_CODIGO + "/" + item.CEN_DETPRESACM
              const table = "CON_DETPRESCEN"
              this.saveAuditoria("I", description, table)

              if (updates.length === 0 && inserts.length - 1 === index) {
                this.spin = false
                completed = 1
              }

            } else {
              numberOfErrors++;
              this.mensajeAlerta("error", "Error", "Error no se pudo ingresar el " + item.CEN_NOMBRE)
              this.spin = false
            }

          } catch (e) {
            numberOfErrors++;
            console.log("Err", e)
            this.spin = false
          }
        }))

      }
      if (updates.length > 0) {
        await Promise.all(updates.map(async (item, index: number) => {
          try {
            // if (index === 1) {
            //   const resHeader = await this.updateHeader(this.headerItemCXC)
            // }
            const resItem = await this.updateItems(item)
            if (resItem) {
              const description = this.txtYear + "/" + item.CEN_CODIGO + "/" + item.CEN_DETPRESACM
              const table = "CON_DETPRESCEN"
              this.saveAuditoria("A", description, table)
              if (updates.length - 1 === index) {
                this.spin = false
                completed = 1
              }
            } else {
              numberOfErrors++;
              this.mensajeAlerta("error", "Error", "No ha sido posible guardar el detalle" + item.CEN_NOMBRE)
              this.spin = false

            }
          } catch (e) {
            console.log("Err", e)
            numberOfErrors++;
            this.mensajeAlerta("error", "Error", "No ha sido posible actualizar el detalle" + item.CEN_NOMBRE)
            this.spin = false
          }
        }))
      }

      if (completed === 1) {
        this.spin = false
        if (numberOfErrors) {
          this.mensajeAlerta("error", "Error", "Ha ocurrido " + numberOfErrors + " errores en el ingresado de los documentos.")
          this.spin = false
          // this.handleDeactivateButtons()
        } else {
          console.log("final year", header.CEN_ENCPRESANIO)

          this.mensajeAlerta("success", "Guardado", "Se ha ingresado exitosamente todos los documentos")
          this.spin = false
          this.isRecentlySave = true;
          await this.handleDeactivateButtons()
          await this.buscarCC(header.CEN_ENCPRESANIO)
          await this.handleDeactivateButtons()
        }
      }

    } else {
      this.mensajeAlerta("error", "Error", "No ha sido posible guardar los datos y el encabezado")
    }
  }

  async handleDelete() {
    this.confirmationService.confirm({
      message: 'Está seguro de eliminar todos los registros del año ' + this.txtYear,
      header: 'Pregunta',
      icon: 'pi pi-exclamation-triangle',
      key: 'presuCentroDialog',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.confirmationService.close();
        this.deleteEverything()
      }, reject: () => {
        this.confirmationService.close();
      }
    });
  }

  async deleteEverything() {

    const hasToDelete = this.headerItemCXC.CONTROL === 1 ? true : false

    if (hasToDelete) {
      this.spin = true
      const resHeader = await this.deleteHeader(this.headerItemCXC)
      if (resHeader) {
        const table = "CON_DETPRESCEN"
        const description = this.txtYear
        await this.saveAuditoria("E", description, table)

        this.reset()
        this.mensajeAlerta("success", "Información", "Se ha eliminado exitosamente!")
        this.spin = false
      }
    } else {
      this.reset()
      this.mensajeAlerta("info", "Información", "Se ha eliminado exitosamente!")
    }

  }


  handleCancel() {
    this.reset()
  }


  async handlerBarMae(valor: string) {
    console.log("mae bar", valor)
    switch (valor) {
      case 'Nuevo':
        this.handleNew()
        break;
      case 'Guardar':
        await this.handleSave()
        break;
      case 'Borrar':
        await this.handleDelete()
        break;
      case 'Cancelar':
        this.handleCancel()
        break;
      case 'Buscar':
        this.buscar()
        break;
      case 'Salir': -
        this.init.tabs.splice(this.init.tabindex, 1);
        this.presuCostService._presuCXC = []
        this.btnNuevo = false
        this.btnBorrar = true
        this.btnGuardar = true;
        this.btnGridBorrar = true
        this.BtnRegresar = true;
        this.itemSelected = {
          CEN_CODIGO: "",
          CEN_ENCPRESANIO: "",
          CEN_NOMBRE: "",
          NUEVO: 0,
          CEN_DETPRES01: 0,
          CEN_DETPRES02: 0,
          CEN_DETPRES03: 0,
          CEN_DETPRES04: 0,
          CEN_DETPRES05: 0,
          CEN_DETPRES06: 0,
          CEN_DETPRES07: 0,
          CEN_DETPRES08: 0,
          CEN_DETPRES09: 0,
          CEN_DETPRES10: 0,
          CEN_DETPRES11: 0,
          CEN_DETPRES12: 0,
          CEN_DETPRESACM: 0,
        }
        break;
    }
  }
  // ==============END-MAEBARRA==============


  async agGridSelectedItem(params: string | { nameObject: string, object: PresucentrocostosDET_APIRes }) {
    if (typeof params === "string") {
      switch (params) {
        case "eliminar":
          const itemSelected = this.itemSelected
          itemSelected.CEN_ENCPRESANIO = this.txtYear
          this.spin = true
          if (itemSelected == null && itemSelected.CEN_ENCPRESANIO === "") {
            this.mensajeAlerta("info", "Información", "Por favor, Selecciona un detalle para eliminarlo")
          } else {

            const res = await this.deleteItem(itemSelected)
            if (res) {
              // const isNew = itemSelected.NUEVO === 0 ? true : false;
              const description = this.txtYear + "/" + itemSelected.CEN_CODIGO + "/" + itemSelected.CEN_DETPRESACM
              // if (isNew) {
              const table = "CON_DETPRESCEN"
              await this.saveAuditoria("E", description, table);
              this.spin = false
              // }
            }
            this.spin = false
            const newPresuCC = this.presuCostService._presuCXC.filter(item => item.CEN_CODIGO !== this.itemSelected.CEN_CODIGO)
            this.presuCostService._presuCXC = newPresuCC

            this.itemSelected = {
              CEN_CODIGO: "",
              CEN_ENCPRESANIO: "",
              CEN_NOMBRE: "",
              NUEVO: 0,
              CEN_DETPRES01: 0,
              CEN_DETPRES02: 0,
              CEN_DETPRES03: 0,
              CEN_DETPRES04: 0,
              CEN_DETPRES05: 0,
              CEN_DETPRES06: 0,
              CEN_DETPRES07: 0,
              CEN_DETPRES08: 0,
              CEN_DETPRES09: 0,
              CEN_DETPRES10: 0,
              CEN_DETPRES11: 0,
              CEN_DETPRES12: 0,
              CEN_DETPRESACM: 0,
            }
            this.mensajeAlerta("info", "Información", "El " + itemSelected.CEN_NOMBRE + " ha sido eliminado!")
          }
          break;
      }
    } else if (typeof params === "object") {
      const selectedItem = params.object
      this.itemSelected = selectedItem
    }
    console.log("this params", params)
  }

  mensajeAlerta(tipo: "error" | "info" | "success", titulo: string, mensaje: string) {
    this.messageService.add({
      key: 'presuCentroToast',
      severity: tipo,
      summary: titulo,
      detail: mensaje
    });
  }
}