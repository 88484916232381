<p-toast [style]="{marginTop: '41vh'}" position="top-center" key="maeentifin"></p-toast>
<p-confirmDialog [transitionOptions]="'0ms'" key="MaeEntifin" title="Confirmar Acción" appendTo="body">
</p-confirmDialog>

<div class="p-grid" style="width: 98%">
    <div class="p-col-12">
        <app-invmaebarra (signal)="manejarSenales($event)" [botonNuevo]="btnNuevo" [botonGuardar]="btnGuardar"
            [botonBorrar]="btnBorrar" [botonRegresar]="false" [barraBotonesDesplaz]="true" [barraBotones3]="true"
            [barraBotonesAsiCon]="true" [barraBotones5]="true" [botonBuscarPermanente]=""></app-invmaebarra>
    </div>
</div>
<div class="divg" style="height: 450px">
    <app-aggridsaci [width]="" [height]="-1500" [enableSorting]="false" [enableFilter]="true"
        [rowData]="banmaeentifinService.banmaeentifin" [frameworkComponents]="frameworkComponents" [animateRows]="true"
        [rowSelection]="rowSelection" [columnDefs]="columnDefsBanEntifin" [defaultColDef]="defaultColDefBanentinfin"
        [rowStyle]="rowStyle" [singleClickEdit]="false" [botones]="false" (selected)="selectedBanCaja($event)"
        (cambios)="cambio($event)"></app-aggridsaci>
</div>