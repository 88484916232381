import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from 'src/app/usuarioEsquema';
import { Usuario } from 'src/app/usuario';
import {Comencreembolso  } from "../cominterfaces/comencreembolso";
import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
@Injectable({
  providedIn: 'root'
})
export class ComencreembolsoService {
public encabezadoreembolso:Comencreembolso[] = [];
public way: string;

  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private confIniciales: ConfInicialesService,
  ) { 
    this.way = sessionStorage.getItem('way');
  }


  getencreem(strlote): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/20eebaecdb9d6', {
      elementos: {
        lote: strlote
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getnomcaj(caja): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/20eedf1662a6a', {
      elementos: {
        p_caj: caja
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getdetreem(lote): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/20eeb5dddb9d6', {
      elementos: {
        p_lote: lote
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  erCodSriDescripcion1(strFechaEmision, strParametro) {
    return this.http.post(this.way + '/compras/factproveedores/3b6cc379b935c0', {
      elementos: {
        FechaEmision: strFechaEmision,
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  getencnomcaj(caja) {
    return this.http.post<any[]>(this.way + '/compras/encreem/20eedf1662a6a', {
      elementos: {
        p_caj: caja
      },
    }, this.confIniciales.httpOptions());
  }
  erabrirArea(caja) {
    return this.http.post<any[]>(this.way + '/compras/encreem/a5ee5b56dca', {
      elementos: {
        p_caj: caja
      },
    }, this.confIniciales.httpOptions());
  }
  eridcreditrib(caja) {
    return this.http.post<any[]>(this.way + '/compras/encreem/edc9acdb9b2ef000', {
      elementos: {
        p_caj: caja
      },
    }, this.confIniciales.httpOptions());
  }
  ersercom(caja) {
    return this.http.post<any[]>(this.way + '/compras/encreem/76f8edb316', {
      elementos: {
        p_sercom: caja
      },
    }, this.confIniciales.httpOptions());
  }
  guardarencreem(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/43cadb55b75d9c00000', {
      elementos: {
        p_encree_lote: item.ENCREE_LOTE,
        p_com_codigo: '01',
        p_encree_fechaemision: item.ENCREE_FECHAEMISION,
        p_usu_identificacion: this.usuario.identificacion,
        p_encree_caja:item.ENCREE_CAJA,
        p_encree_saldo_caja: item.ENCREE_SALDO_CAJA,
        p_encree_localizacion: '',
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  guardardetreem(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/21e56daadb5dde0000', {
      elementos: {
        p_encree_lote  :item.ENCREE_LOTE,
        p_com_codigo :'01',
        p_detree_linea :item.DETREE_LINEA,
        p_encfacpro_numero :item.ENCFACPRO_NUMERO,
        p_detree_idepro :item.DETREE_IDEPRO,
        p_detree_serieest :item.DETREE_SERIEEST,
        p_detree_serieptoemi :item.DETREE_SERIEPTOEMI,
        p_detree_seccompvta :item.DETREE_SECCOMPVTA,
        p_detree_tipcomp :item.DETREE_TIPCOMP,
        p_detree_tipoidepro :item.DETREE_TIPOIDEPRO,
        p_detree_fechaemision :item.DETREE_FECHAEMISION,
        p_detree_autorizacion :item.DETREE_AUTORIZACION,
        p_detree_basecero :item.DETREE_BASECERO,
        p_detree_baseiva :item.DETREE_BASEIVA,
        p_detree_basenoiva :item.DETREE_BASENOIVA,
        p_detree_porcentajeiva :item.DETREE_PORCENTAJEIVA,
        p_detree_montoice :item.DETREE_MONTOICE,
        p_detree_montoiva  :item.DETREE_MONTOIVA,
        p_detree_baseimpexereemb :item.DETREE_BASEIMPEXEREEMB,
        p_detree_paispago :item.DETREE_PAISPAGO,
        p_detree_protipo :item.DETREE_PROTIPO,
        p_detree_empleado :item.DETREE_EMPLEADO,
        p_detree_tipgas :item.DETREE_TIPGAS,
        p_detree_descripcion :item.DETREE_DESCRIPCION,
        p_detree_area :item.DETREE_AREA,
        p_detree_centroc :item.DETREE_CENTROC,
        p_detree_estado :item.DETREE_ESTADO,
        p_sercom_codigo :item.SERCOM_CODIGO,
        p_detree_idcretri :item.DETREE_IDCRETRI,
        p_detree_usuaprueba :item.DETREE_USUAPRUEBA,
        p_detree_fechaautoriza :item.DETREE_FECHAAUTORIZA,
        p_detree_usuvalida :item.DETREE_USUVALIDA,
        p_tarriva_codigo :item.TARIIVA_CODIGO,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarecnreem(lote): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/3ab2b4aeadbaec00000', {
      elementos: {
        p_encree_lote :lote,
        p_com_codigo :'01'
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminardetreem(item): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/3ab2b4aeadb5de00000', {
      elementos: {
        p_encree_lote :item.ENCREE_LOTE ,
        p_com_codigo :'01',
        p_detree_linea:item.DETREE_LINEA,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarEstado(strEstado1,EstadoAnt1,fecha,lote,linea): Promise<any[]> {
    return this.http.post<any[]>(this.way + '/compras/encreem/14cea9dcea9b8e0000', {
      elementos: {
        strEstado:strEstado1,
        EstadoAnt:EstadoAnt1,
        usuarioConectado:this.usuario.identificacion,
        strFechaAutoriza:fecha,
        txtLote:lote,
        txtlinea:linea
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
}
