<p-toast [style]="{marginTop: '41vh'}"
		 position="top-center"
		 key="presuCentroToast"></p-toast>

<p-confirmDialog [transitionOptions]="'200ms'"
				 key="presuCentroDialog"
				 title="Pregunta"
				 appendTo="body">
</p-confirmDialog>

<p-dialog header="Búsqueda de Datos"
		  [(visible)]="displayDialogBusquedaFast"
		  [transitionOptions]="'0ms'"
		  [modal]="modalOpen"
		  appendTo="body"
		  [style]="{width: '70vw', height: '70vh'} ">
	<app-busquedadlg [arrayOptions]="types"
					 [arregloConsulta]="arregloCons"
					 [tabla]="tabla"
					 [where]="where"
					 [busquedaParams]="busquedacampos"
					 (opcionSeleccionada)="handlerSelectionDialog($event)"></app-busquedadlg>
</p-dialog>

<div class="p-grid"
	 style="width: 98%">
	<div class="p-col-12">
		<app-invmaebarra (signal)="handlerBarMae($event)"
						 [botonNuevo]="btnNuevo"
						 [botonGuardar]="btnGuardar"
						 [botonBorrar]="btnBorrar"
						 [botonRegresar]="BtnRegresar"
						 [barraBotonesDesplaz]="true"
						 [barraBotones3]="true"
						 [barraBotonesAsiCon]="true"
						 [barraBotones5]="true"
						 [botonBuscarPermanente]="true"></app-invmaebarra>
	</div>
</div>

<div class="divgrups"
	 style="width: 98%">
	<div class="p-grid">
		<div class="p-col-1">
			<span>Año:</span>
		</div>
		<div class="p-col-2"
			 style="text-align: left">
			<input id="Year_presucentroCostos"
				   type="text"
				   pInputText
				   autocomplete="off"
				   maxlength="10"
				   [(ngModel)]="txtYear"
				   [disabled]="txtYearAvailable"
				   (keydown)="onKeyDown($event)"
				   (input)="cambio($event)">
		</div>
		<div class="p-col-1">
			<button type="button"
					style="background-image: url(../../../assets/images/iconos/buscar.png);"
					class="littlebuttons"
					(click)="buscarCC(txtYear)"
					[disabled]="false"></button>
		</div>
	</div>
	<div class="p-grid">
		<div class="p-col-1">
			<span>Descripción:</span>
		</div>
		<div class="p-col-11"
			 style="text-align: left">
			<input id="presuCCDescription"
				   type="text"
				   pInputText
				   maxlength="99"
				   autocomplete="off"
				   [(ngModel)]="txtDescription"
				   [disabled]="txtDescriptionAvailable"
				   (input)="cambio($event)">
		</div>
	</div>
</div>

<!-- <p-scrollPanel [style]="{'width':'100%', 'height':'calc(100vh - 400px)'}"> -->

<app-aggridsaci [width]=""
				[height]="-1000"
				[enableSorting]="true"
				[enableFilter]="true"
				[rowData]="presuCostService.presuCXC"
				[frameworkComponents]="frameworkComponents"
				[animateRows]="true"
				[rowSelection]="rowSelection"
				[columnDefs]="columnDef"
				[defaultColDef]="defaultColDefCentrocostos"
				[rowStyle]="rowStyle"
				[singleClickEdit]="false"
				[botones]="true"
				(cambios)="cambio($event)"
				[botonNuevo]="true"
				[botonGuardar]="false"
				[botonBorrar]="btnGridBorrar"
				[botonRegresar]="true"
				[mostrarGuardar]="false"
				[singleClickEdit]="false"
				(selected)="agGridSelectedItem($event)"></app-aggridsaci>
<!-- </p-scrollPanel> -->

<div style="position: fixed;
				z-index: 999;
				height: 2em;
				width: 2em;
				overflow: visible;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				">
	<p-progressSpinner *ngIf="spin"
					   [style]="{width: '100px', height: '100px'}"
					   animationDuration=".3s">
	</p-progressSpinner>
</div>