import { Injectable } from '@angular/core';
import { bantrninv } from "../baninterfaces/bantrninv";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioEsquema } from '../../usuarioEsquema';
import { Usuario } from '../../usuario';
import { MessageService } from 'primeng/api';
import { ErroresBaseDatosService } from '../../servicios/errores-base-datos.service';
import { SaciTrnretencion } from '../../interfaces/sacitrnretencion';

import { ConfInicialesService } from '../../servicios/conf-iniciales.service';
import { bantrninversiones } from "../baninterfaces/bantrninversiones";
import { NuevoComponentService } from '../../servicios/nuevo-component.service';
@Injectable({
  providedIn: 'root'
})
export class BantrninversionesService {
  public detbantrninversion: bantrninv[][] = [];
  dettrnretencion: SaciTrnretencion = {};
  private way: string;
  constructor(
    private http: HttpClient,
    public usuarioEsquema: UsuarioEsquema,
    public usuario: Usuario,
    private messageService: MessageService,
    private errorService: ErroresBaseDatosService,
    private confIniciales: ConfInicialesService,
    private init: NuevoComponentService
  ) {
    this.way = sessionStorage.getItem('way');
  }
  encontrarConNombre(strParametro) {
    return this.http.post(this.way + '/inventario/articulo/3b6cc5ef8b2f6e', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  encontrarbanentifin(strParametro) {
    return this.http.post(this.way + '/ban/bantrninversiones/3aecc5faadbafe00000', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions());
  }
  erGenerarAsiento(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/83aeedab9275f80', {

      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_baninv_certificadoaper: item.BANINV_CERTIFICADOAPER,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'APIN'
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarAsientos(item, item2): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/41dd5724ebf71c', {

      elementos: {
        certificado: item,
        aper: item2
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertarbaninv(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/12bf1dbeab6b55000', {
      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_baninv_certificadoaper: item.BANINV_CERTIFICADOAPER,
        p_ban_codigo: item.BAN_CODIGO,
        p_com_codigo: '01',
        p_baninv_fechaemision: item.BANINV_FECHAEMISION,
        p_baninv_fechavencimiento: item.BANINV_FECHAVENCIMIENTO,
        p_baninv_valornominal: item.BANINV_VALORNOMINAL,
        p_baninv_diasplazo: item.BANINV_DIASPLAZO,
        p_baninv_interesanual: item.BANINV_INTERESANUAL,
        p_baninv_rentabilidadvenc: item.BANINV_RENTABILIDADVENC,
        p_baninv_interesdiario: item.BANINV_INTERESDIARIO,
        p_baninv_origenfondos: item.BANINV_ORIGENFONDOS,
        p_baninv_destinofondos: item.BANINV_DESTINOFONDOS,
        p_baninv_ctacertificado: item.BANINV_CTACERTIFICADO,
        p_baninv_ctainteres: item.BANINV_CTAINTERES,
        p_baninv_ctaotrosint: item.BANINV_CTAOTROSINT,
        p_baninv_estado: item.BANINV_ESTADO,
        p_baninv_ctaaltbanco: item.BANINV_CTAALTBANCO,
        p_baninv_fpaper: item.BANINV_FPAPER,
        p_baninv_fpcierre: item.BANINV_FPCIERRE,
        p_baninv_isd: item.BANINV_ISD,
        p_baninv_tipofinplazo: item.BANINV_TIPOFINPLAZO,
        p_baninv_fechaprecancela: item.BANINV_FECHAPRECANCELA,
        p_baninv_tasacastigo: item.BANINV_TASACASTIGO,
        p_baninv_intprecancela: item.BANINV_INTPRECANCELA,
        p_baninv_ctaisd: item.ASI_CTAISD,
        p_entfin_codigo: item.ENTFIN_CODIGO,
        p_baninv_archivo: '',
        p_baninv_diferencia: item.BANINV_DIFERENCIA
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  insertadepre(ban_codigo, trnban_tipo, trnban_numero, fecha, trnban_importe, trnban_concepto, trnban_beneficiario, asi_nro, trnban_origen, trnban_docnro, concodigo): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/4afc76fad76770', {
      elementos: {
        p_ban_codigo: ban_codigo,
        p_trnban_tipo: trnban_tipo,
        p_trnban_numero: trnban_numero,
        p_trnban_fecha: fecha,
        p_trnban_importe: trnban_importe,
        p_trnban_concepto: trnban_concepto,
        p_trnban_beneficiario: trnban_beneficiario,
        p_com_codigo: '01',
        p_asi_nro: asi_nro,
        p_trnban_origen: trnban_origen,
        p_trnban_docnro: trnban_docnro,
        p_con_codigo: concodigo,
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consulbancodigoRE(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/2d57661b28636e', {
      elementos: {
        p_con_codigo: item
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consulbancodigoDP(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/2d57661b2861b8', {
      elementos: {
        p_con_codigo: item
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  getbainvdato(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/41dd5aa576abb8', {
      elementos: {
        parametro: item
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generaineteres(item, fecha): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/20ebbb6adcafd80000', {
      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_baninv_certificadoaper: item.BANINV_CERTIFICADOAPER,
        p_fecha: fecha,
        p_com_codigo: '01',
        p_usu_identificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  generarasientointeres(certificado): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/41d776d5c93afc0000000000', {
      elementos: {
        p_baninv_certificado: certificado,
        p_com_codigo: '01',
        p_usu_identificacion: this.usuario.identificacion
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarAsientosInteres(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/662fcf57aadab80000000000000000', {

      elementos: {
        certificado: item,
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  mostrarinteres(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/2d8e776adcafd80000', {

      elementos: {
        certificado: item,
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  calcularet() {
    this.dettrnretencion.TRNRETENCION_TOTALRETENIDO = Number(Number(this.dettrnretencion.TRNRETENCION_PORCENTAJE / 100) * Number(this.dettrnretencion.TRNRETENCION_BASE)).toFixed(2)
  }
  guardartretencion(item, CERTIFICADO): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/8795b6ab7b7760000000', {
      elementos: {
        p_trnretencion_nro: item.TRNRETENCION_NRO,
        p_retencion_codigo: item.RETENCION_CODIGO,
        p_com_codigo: '01',
        p_trnretencion_desc: item.RETENCION_NOMBRE,
        p_trnretencion_base: item.TRNRETENCION_BASE,
        p_trnretencion_porc: item.TRNRETENCION_PORCENTAJE,
        p_trnretencion_totr: item.TRNRETENCION_TOTALRETENIDO,
        p_trnretencion_nrodoc: CERTIFICADO,
        p_trnretencion_origen: 'BINV',
        p_pro_codigo: '',
        p_retencion_nroant: '',
        p_CON_codigo: item.CON_CODIGO,
        p_cli_codigo: '',
        p_cen_codigo: '',
        p_trnretencion_fechaemision: item.TRNRETENCION_FECHAEMISION
      }
    }, this.confIniciales.httpOptions()).toPromise();
  }

  actdatosadic(isdv, cntaisdv, certificadov): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/299df2ab2', {

      elementos: {
        isd: isdv,
        cntaisd: cntaisdv === null ?'':cntaisdv,
        certificado: certificadov
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  procesar(item, tipo): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/cef0c7715b6c98000000', {

      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_baninv_certificadoaper: item.BANINV_CERTIFICADOAPER,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CIIN',
        
      },p_tipo: tipo

    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizatipofinplazo(tipo): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/14cebfb2ce1f2c0000', {

      elementos: {
        p_tipo: tipo
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  consultarAsientosCierre(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/83baae49d7ee18000000', {
      elementos: {
        certificado: item,
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  actualizarestado(estado, item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/14cebddcea9b8', {
      elementos: {
        certificado: item,
        p_estado: estado
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  reversar(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/dbbeedf15bdba0000000', {

      elementos: {
        p_baninv_certificado: item,
        p_com_codigo: '01'
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  reversarprecan(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/1b77ddbe2b3b730000000000000', {

      elementos: {
        p_baninv_certificado: item,
        p_com_codigo: '01'
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  actdatoprecan(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/14ceb55dc733b70000', {

      elementos: {
        p_fecha: item.BANINV_FECHAPRECANCELA,
        p_tasacas: item.BANINV_TASACASTIGO,
        p_precancela: item.BANINV_INTPRECANCELA,
        p_finplazo: 'PR',
        certificado: item.BANINV_CERTIFICADO,
        p_com_codigo: '01'
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  procesarprecan(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/33bc31dc56f3b80000', {

      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_baninv_certificadoaper: item.BANINV_CERTIFICADOAPER,
        p_com_codigo: '01',
        p_usuario: this.usuario.identificacion,
        p_tipdia_codigo: 'CIIN',
      },

    }, this.confIniciales.httpOptions()).toPromise();
  }
  getretenciones(strParametro): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/41dddbbaebb2580000000000', {
      elementos: {
        parametro: strParametro
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminardetinv(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/1d595a5756daef0000', {
      elementos: {
        p_baninv_certificado: item.BANINV_CERTIFICADO,
        p_com_codigo: '01',
        p_trnintinv_linea: item.TRNINTINV_LINEA
      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarreg(certificado): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/7565695d5bdba0000000', {
      elementos: {
        p_baninv_certificado: certificado,
        p_com_codigo: '01',

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  eliminarret(item): Promise<any> {
    return this.http.post<any>(this.way + '/ban/bantrninversiones/eacad2bab7b7780000000', {
      elementos: {
        p_trnretencion_nro :item.TRNRETENCION_NRO,
        p_retencion_codigo :item.RETENCION_CODIGO,
        p_com_codigo :'01',
        p_trnretencion_origen :item.TRNRETENCION_ORIGEN,
        p_trnretencion_referencia :item.TRNRETENCION_REFERENCIA,
        p_trncobro_nrocomprobante :item.TRNCOBRO_NROCOMPROBANTE

      },
    }, this.confIniciales.httpOptions()).toPromise();
  }
  calculaTotalInteres(){
    let suma=0
    console.log(this.detbantrninversion[this.init.tabs[this.init.tabindex].indice].length);
    for (let fila=1;fila <= this.detbantrninversion[this.init.tabs[this.init.tabindex].indice].length;fila++) {
      let iterator=this.detbantrninversion[this.init.tabs[this.init.tabindex].indice][fila - 1];
      console.log(iterator.TRNINTINV_INTERES);

      suma=Number(suma)+Number(iterator.TRNINTINV_INTERES)
    }
    return suma;
  }
}
